<template>
  <div class="mission">
    <div class="mission-passed" v-if="passed">
      <p class="mission-passed__text purple-text">Миссия пройдена</p>
      <img src="@/assets/imgs/mission-passed.svg" alt=" " class="mission-passed__img" />
    </div>
    <div class="mission-defoult" :class="{ 'mission-activate': access }" v-else @click="goToMission">
      <div class="mission-caption">
        <img :src="require(`@/assets/imgs/missions/icons/mission-${index}.svg`)" alt="" class="mission-caption__img" />
        <p class="mission-caption__text purple-text">{{ index }} Миссия</p>
        <p class="mission-caption__status" v-if="false">
          Старт {{ localeDateOptions(startDate) }}
        </p>
      </div>
      <p class="mission__text">
        {{
          index === 1 ? 'Сегодня, как и во все века, человечеством движет мечта. Здесь мы вместе выберем те мечты, которые станут движущей силой именно для тебя.' :
              index === 2 ? 'Любить себя — это значит понимать свою уникальность и принимать себя как есть, со всеми недостатками. Здесь мы узнаем о твоих суперспособностях, а это именно то, что нужно, чтобы чувствовать себя счастливым и добиться больших профессиональных успехов.' :
                  index === 3 ? 'Хочешь знать, кто ты? Не спрашивай. Действуй! Действие будет описывать и определять тебя. Здесь мы с тобой организуем мероприятие, и это позволит мне понять, что тебя больше всего мотивирует на свершения.' :
                  index === 4 ? 'Силы прибавляет то, что твое дело кому-то нужно, кто-то в этом нуждается, кому-то это необходимо как воздух. Это главное — быть нужным. Здесь я познакомлю тебя с сутью работы компаний, которые тебе подходят, чтобы ты выбрал, какую пользу в будущем ты хочешь принести обществу.' :
                    'В людях главное не статус. В людях главное суть. То же самое относится и к профессиям. В этой миссии я уберу весь информационный шум и познакомлю тебя с сутью профессий, чтобы узнать, какие из них подходят именно тебе.'

        }}
      </p>
    </div>
  </div>
</template>

<script>
import { localeDateOptions } from "@/lib/time";
import { useRouter } from "vue-router";

export default {
  name: "SeparateMission",
  props: ["index", "passed", "startDate", "access"],
  setup(props) {
    const router = useRouter();

    return {
      localeDateOptions,
      goToMission: () =>
        router.push({
          name: "missionPass",
          params: { missionId: props.index },
        }),
    };
  },
};
</script>

<style scoped>
.mission {
  max-width: 100%;
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: 1s all ease-in-out;
}

.mission:hover {
  opacity: 0.5;
}

.mission-passed {
  background: rgba(175, 175, 175, 0.17);
  padding: 1.5rem;
  border-radius: 1rem;
  max-width: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.mission-defoult {
  background: rgba(175, 175, 175, 0.17);
  padding: 1.5rem;
  border-radius: 1rem;
  height: 100%;

}

.mission-passed__text {
  font-size: 1.1875rem;
  font-weight: 700;
}

.mission-passed__img {
  margin-top: 0.95rem;
  width: 2rem;
}

.mission-caption {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
}

.mission-caption__img {
  width: 2rem;
  height: 2rem;
  display: flex;
  flex-shrink: 0;
}

.mission-caption__text {
  margin: 0 0.38rem;
  color: #d9d9d9;
  font-size: 2rem;
  font-weight: 700;
}

.mission-caption__status {
  margin-left: auto;
  color: rgba(255, 255, 255, 0.6);
  padding: 0.75rem 1.04rem 0.94rem 1.04rem;
  background: rgba(254, 254, 254, 0.15);
  font-size: 1.25rem;
  border-radius: 0.37675rem;
  font-weight: 700;
}

.mission__text {
  margin-top: 0.82rem;
  color: rgba(254, 254, 254, 0.8);
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.875rem;
  letter-spacing: -0.025rem;
}

.mission-activate {
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  height: 100%;
}

.mission-activate .mission-caption__text {
  color: #fff;
  background-color: #fff;
  background-image: unset;
}

@media (max-width: 850px) {
  .mission-passed {
    border-radius: 0.625rem;
  }

  .mission-defoult {
    padding: 1rem;
    border-radius: 0.625rem;
  }

  .mission-passed__text {
    font-size: 1.375rem;
    font-weight: 700;
  }

  .mission-passed__img {
    margin-top: 0.31rem;
    width: 1.65519rem;
    height: 1.60513rem;
  }

  .mission-caption {
    padding-right: 0;
  }

  .mission-caption__img {
    width: 1.3125rem;
    height: 1.3125rem;
  }

  .mission-caption__text {
    margin: 0 0.36rem;
    font-size: 1.375rem;
  }

  .mission-caption__status {
    font-size: 0.875rem;
    padding: 0.5rem;
  }

  .mission__text {
    margin-top: 0.5rem;
    font-size: 0.8125rem;
    line-height: 1.25rem;
  }

  .mission-activate {
    background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
    height: 100%;
  }

  .mission-activate .mission-caption__text {
    background-color: #fff;
    background-image: #fff;
  }
}
</style>
