<template>
  <div class="chat-form-wrapper">
    <div class="chat-form container">
      <label class="form-label ">
        <button class="eye-wrapper" v-if="step === 10" @click="viewPassword = !viewPassword"
                :class="{ 'eye-open': viewPassword }">
        </button>
        <input v-if="step !== 9" v-model="body" @keydown.enter="enterMessage"
               :type="step === 8 ? 'date' : (step === 10 && !viewPassword) ? 'password' : 'text'" class="chat__input "
               :max="step === 8 ? '2013-12-31' : null" :min="step === 8 ? '1970-01-01' : null"
               placeholder="Ответить Цип"/>

        <input   v-if="step === 9" @keydown.enter="enterMessage" ref="el" class="chat__input" placeholder="Ответить Цип" type="text">
        <!--        <i-mask-component v-if="step === 9" class="chat__input" type="tel" :mask="mask" v-model="maskedPhone"-->
        <!--                          @keydown.enter="enterMessage" v-model:unmasked="body"-->
        <!--                          placeholder="Ответить Цип"></i-mask-component>-->
      </label>


      <!--      <i-mask-component v-if="step === 8" class="chat__input" :mask="Date" :max="new Date(2008, 0, 1)" v-model="body"-->
      <!--                        @keydown.enter="enterMessage" placeholder="Дата рождения"></i-mask-component>-->


      <button @click="enterMessage" class="chat__send"></button>
    </div>
  </div>
</template>

<script>
import {inject, ref} from "vue";
import {useIMask} from "vue-imask";
import dayjs from "dayjs";
import {errorHandler} from "@/lib/errorHandler";

export default {
  name: "ChatForm",
  props: ["step"],

  setup(props, {emit}) {
    const store = inject("store");

    let body = ref("");
    const viewPassword = ref(false);

    const {el, masked, unmasked} = useIMask({
      mask: '+{7}(000)000-00-00',
      radix: '.',
    });

    return {
      el,
      body,
      viewPassword,
      enterMessage: () => {
        switch (props.step) {
          case 0:
            if (!body.value) return;
            emit('changeLoader');
            store.commit("setRegistrationData", {
              field: "firstName",
              val: body.value,
            });
            setTimeout(() => {
              emit('changeLoader');
            }, 1500)

            break;
          case 1:
            if (!body.value) return;
            store.commit("setRegistrationData", {
              field: "secondName",
              val: body.value,
            });
            break;
          case 2:
            store.commit("setRegistrationData", {
              field: "thirdName",
              val: body.value,
            });
            emit("setName");
            break;
          case 7:
            if (!body.value) return;
            store.commit("setRegistrationData", {
              field: "educationName",
              val: body.value,
            });
            break;
          case 8:
            if (body?.value?.split("-")?.length < 3 || dayjs(body.value).toDate() < new Date(1970, 0, 1)) {
              errorHandler({name: 'InvalidField', field: 'birth'})
              return;
            }
            if (dayjs(body.value).toDate() > new Date(2013, 11, 31)) {
              errorHandler({name: 'InvalidField', field: 'birth_young'})
              return;
            }

            body.value = dayjs(body.value).toDate();

            store.commit("setRegistrationData", {
              field: "birthDate",
              val: body.value,
            });
            body.value = body.value?.toLocaleString("ru-RU", {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
            break;
          case 9:
            if (unmasked.value?.length < 11) return;
            store.commit("setRegistrationData", {
              field: "phone",
              val: unmasked.value,
            });
            body.value = masked.value;
            break;
          case 10:
            if (!body.value) return;
            store.commit("setRegistrationData", {
              field: "password",
              val: body.value,
            });
            body.value = "";
            break;
          case 11:
            if (/[А-я]/g.test(body?.value)) {
              errorHandler({name: 'InvalidField', field: 'cyrillic'});
              return;
            }

            if (!/^[\w-\\.]+@[\w-]+\.[a-z]{2,4}$/i.test(body?.value)) {
              errorHandler({name: 'InvalidField', field: 'email'});
              return;
            }


            store.dispatch('checkUserEmail', body.value).then((unique) => {
              if (unique) {
                store.commit("setRegistrationData", {
                  field: "email",
                  val: body.value,
                });
                // emit('emailError', true);
                emit("sendMessage", body.value);
                emit("clearMessages");
                body.value = "";

              } else {
                emit('existEmail');
              }
            });

            return;




            // case 12:
            //   store.commit("setRegistrationData", {
            //     field: "promocode",
            //     val: body.value,
            //   });
            //   break;

          case "emailError":
            if (!/^[\w-\\.]+@[\w-]+\.[a-z]{2,4}$/i.test(body?.value)) return;
            store.commit("setRegistrationData", {
              field: "email",
              val: body.value,
            });
            break;
        }

        emit("sendMessage", body.value);
        if (props.step > 2) emit("clearMessages");


        body.value = "";
      },
    };
  },
};
</script>

<style scoped>
.chat-form-wrapper {
  position: fixed;
  bottom: 0px;
  max-width: 100%;
  width: 100%;
  background: #1e2229;
  left: 0;
  z-index: 3;
}

.form-label {
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.chat-form {
  padding-top: 1.875rem;
  padding-bottom: 1.5rem;
  display: flex;
}

.chat__input {
  background: #2b2d30;

  border: 1px solid rgba(112, 116, 123, 0.3);
  border-radius: 0.625rem;
  padding: 0.75rem 1.563rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: #fff;
}

.chat__input::placeholder {
  color: rgba(255, 255, 255, 0.35);
}


.chat__send {
  display: flex;
  flex-shrink: 0;
  margin-left: 1.875rem;
  width: 2.813rem;
  height: 2.813rem;
  background: url("@/assets/imgs/chat-send.svg");
  background-size: contain;
  background-repeat: no-repeat;
}


.eye-wrapper {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  flex-shrink: 0;
  background: url('@/assets/imgs/eye.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  position: absolute;
  right: 0.5rem;
  z-index: 2;
}

.eye-open {
  background: url('@/assets/imgs/eye-close.svg');
  background-size: contain;
  background-position: center;
}

.eye-wrapper ~ .chat__input {
  padding-right: 2rem;
}

.eye {
  display: flex;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  position: relative;
  z-index: 2
}

.chat__input--wrong {

  border: 1px solid rgba(255, 0, 0, 0.40);
  background: rgba(255, 0, 0, 0.10);
}

@media (max-width: 700px) {
  .chat-form {
    padding-top: 0.625rem;
    padding-bottom: 1rem;
    display: flex;
  }

  .chat__input {
    padding: 0.625rem 1rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1;
    color: #fff;
  }

  .chat__input::placeholder {
    color: rgba(255, 255, 255, 0.35);
  }

  input[type="date"] {
    -webkit-appearance: none !important;
    height: 100%;
    max-width: 100%;
  }

  .chat__send {
    margin-left: 0.625rem;
    width: 2.563rem;
    height: 2.563rem;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 2;
  }
}
</style>
