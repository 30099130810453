<template>
  <div class="filling" :class="{ 'step': step === 0 }">
    <img src="@/assets/imgs/video/bg-1-1.webp" alt="" class="bg" />
    <div class="content">
      <img src="@/assets/imgs/video/robot.webp" alt="" class="robot" :class="{ 'scale': step === 0 }" />
      <p class="text">ЦИФРОВОЙ ПОМОЩНИК</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoElement1",
  props: ["step"],
};
</script>

<style scoped>
.filling {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.scale {
  animation: rotate 4s ease-in-out infinite;
  will-change: scale;
}

@keyframes rotate {
  0% {
    scale: 1;
  }

  50% {
    scale: 0.8;
  }

  100% {
    scale: 1;
  }
}

.bg {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.content {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  max-width: 100%;
  width: 100%;
}

.robot {
  width: 10.83vw;
}

.text {
  margin-left: 2.45vw;
  font-size: 2.5vw;
  font-weight: 700;
  line-height: normal;
}
</style>
