<template>
  <div class="assistant">
    <div class="assistant-info">
      <img src="@/assets/imgs/missions/robot-big.png" alt="" class="robot"/>
      <h1 class="title">Помощник</h1>
      <p class="text">Это начало вашей истории прямых сообщений с Цип.</p>
    </div>
    <div class="date-wrapper" v-if="false">
      <div class="date-line"></div>
      <p class="date">Ноябрь 18, 2023</p>
      <div class="date-line"></div>
    </div>
    <div class="filling">
      <div class="caption desktop">
        <img src="@/assets/imgs/missions/robot-big.png" alt="" class="robot robot__small"/>
        <p class="caption-name">
          Цип
          <span class="caption-name-description">BOT</span>
        </p>
        <p class="caption-date" v-if="false">11/18/2023</p>
        <p class="caption-date caption-date__time" v-if="false">13:03</p>
      </div>
      <div class="messages">
        <div class="message-wrapper" :id="`msg${index}`" v-for="(item, index) in messages" :key="index">
          <pre class="message" :class="{
            'message-my': item.from === 'user',
            'message-robot': item.from === 'bot',
          }">{{ item.body }}</pre>
          <div class="radio-text-wrapper" v-if="item.type === 'multi' && botMessages[step]?.type === 'multi'">
            <label class="radio-text" v-for="company in companies" :key="company.id">
              <input :type="manyCompanies ? 'radio' : 'checkbox'" :value="company.email" v-model="userAnswers.companies"
                     name="text"
                     class="radio-text__checkbox"/>

              <div class="radio-text__content">
                <div class="radio-text__round"></div>
                <p class="radio-text__content-text">{{ company.name }}</p>
              </div>
            </label>
          </div>
          <div class="radio-text-wrapper radio-text-yes-no" v-if="item.type === 'single' && botMessages[step]?.type === 'single'
            ">
            <label class="radio-text">
              <input type="radio" name="text-yes-no" class="radio-text__checkbox" :value="true"
                     v-model="manyCompanies"/>
              <div class="radio-text__content">
                <div class="radio-text__round"></div>
                <p class="radio-text__content-text">да, уверен</p>
              </div>
            </label>
            <label class="radio-text">
              <input type="radio" name="text-yes-no" :value="false" class="radio-text__checkbox"
                     v-model="manyCompanies"/>
              <div class="radio-text__content">
                <div class="radio-text__round"></div>
                <p class="radio-text__content-text">
                  нет, хочу выбрать несколько вариантов
                </p>
              </div>
            </label>
          </div>
          <button class="robot-message__btn button-purple" @click="nextStep" v-if="(item.type === 'multi' && botMessages[step]?.type === 'multi') ||
            (item.type === 'single' && botMessages[step]?.type === 'single')">
            подтвердить
          </button>
        </div>
      </div>
      <form @submit.prevent v-if="botMessages[step]?.type === 'phone' ||
        botMessages[step]?.type === 'comment'
        " class="form">
        <button v-if="false" class="add" type="button"></button>
        <input @keydown.enter="nextStep" v-if="botMessages[step]?.type === 'comment'" v-model="userAnswers.comment"
               type="text" class="field" placeholder="Напишите @Цип"/>
        <i-mask-component v-if="botMessages[step]?.type === 'phone'" @keydown.enter="nextStep" type="tel" class="field"
                          placeholder="Напишите @Цип" :mask="mask" v-model="maskedPhone"
                          v-model:unmasked="userAnswers.phone"/>
        <button @click="nextStep" class="send" type="button"></button>
      </form>
    </div>
  </div>
</template>

<script>
import {computed, inject, ref, watch} from "vue";
import {IMaskComponent} from "vue-imask";
// import {appConfig} from "@/lib/appConfig";
// import {useRouter} from "vue-router";
import {errorHandler} from "@/lib/errorHandler";

export default {
  name: "AssistantPage",
  components: {IMaskComponent},
  setup() {

    const store = inject("store");

    // const userData = computed(() => store.getters.userData);


    // if (!appConfig.appState.free && userData.value?.status !== 'account_paid') {
    //
    //   const router = useRouter()
    //
    //
    //   router.push({name: 'statistics'}).then(() => {
    //     store.commit('setPaymentByMoneyModal', true);
    //   })
    //
    // }

    const userAnswers = ref({
      companies: [],
      phone: '',
      comment: '',
    });


    const maskedPhone = ref("");

    store.dispatch("fetchStudentStatistic");

    const companies = computed(() => store.getters.studentStatistic.companies);

    const botMessages = ref([
      {
        body: "Я рад, что ты заинтересовался данной возможностью. Об этом мало кто знает, но работодатели готовы заплатить за твоё обучение, а после того, как ты получишь диплом, готовы гарантированно тебя трудоустроить. Для этого заключается трёхстороннее соглашение между работодателем, тобой и университетом, которое называется целевым договором на обучение. \n" +
            "\n" +
            "Звучит как сказка, но через наш сервис этой возможностью воспользовалось уже более 500 человек.\n" +
            "\n" +
            "Напомню, что твою заявку готовы рассмотреть 3 компании. Ты точно хочешь остановить свой выбор только на одной из них? \n",
        from: "bot",
        type: "single",
      },
      {
        body:
            "Отлично! Для отправки данных мне нужно ещё немного информации. Напиши, пожалуйста, свой номер телефона, чтобы\n" +
            "работодатель мог связаться с тобой напрямую ☎️",
        from: "bot",
        type: "phone",
      },
      {
        body:
            "Расскажи о своих любых достижениях, какие посчитаешь значимыми 😉\n" +
            "Это нужно, чтобы работодатель мог с тобой заочно познакомиться.\n",
        from: "bot",
        type: "comment",
      },
      {
        body: "Я всё зафиксировал и уже отправил заявку. Мы сделали важный шаг к твоему успеху! Думаю, через пару дней придёт ответ от работодателя(-ей). Ну, а теперь можно отдохнуть. Хорошего дня! 😉",
        from: "bot",
        type: "finally",
      },
    ]);

    const messages = ref([]);

    const step = ref(0);

    const manyCompanies = ref(null);

    // const pickedCompany = computed(() => store.getters.pickedCompany);


    const saveAnswers = () => {
      if (typeof userAnswers.value?.companies === 'string') {
        userAnswers.value.companies = [userAnswers.value?.companies]
      }
      store.dispatch("saveAnswers", userAnswers.value);
    };
    const nextStep = () => {
      switch (step.value) {
        case 0:
          if (manyCompanies.value === null) {
            return errorHandler({name: 'customMessage', message: 'Необходимо сделать выбор'});
          }
          break;

        case 1:
          if (!userAnswers.value.companies?.length) {
            return errorHandler({
              name: 'customMessage',
              message: 'Необходимо сделать выбор'
            });
          }
          break;

        case 2:
          if (userAnswers.value.phone?.length < 11) {
            return errorHandler({
              name: 'customMessage',
              message: 'Необходимо ввести номер телефона'
            });
          }
          break;

        case 3:
          if (!userAnswers.value.comment?.trim()) {
            return errorHandler({
              name: 'customMessage',
              message: 'Необходимо рассказать о своих достижениях'
            });
          }
          break;
      }


      step.value++;
    }

    watch(
        () => step.value,
        (val, oldValue) => {
          switch (val) {
            case 1:
              messages.value.push({
                from: "user",
                body: manyCompanies.value
                    ? "Да, уверен"
                    : "Нет, хочу выбрать несколько вариантов",
              });

              if (!manyCompanies.value) {
                botMessages.value.splice(1, 0, {
                  body:
                      "В какие компании ты хочешь отправить заявку?\n" +
                      "Можно выбрать несколько вариантов ответов:",
                  from: "bot",
                  type: "multi",
                });
              } else {

                botMessages.value.splice(1, 0, {
                  body:
                      "В какую компанию ты хочешь отправить заявку?\n" +
                      "Можно выбрать один вариант ответа:",
                  from: "bot",
                  type: "multi",
                });
                // userAnswers.value.companies = pickedCompany.value
                //     ? pickedCompany.value
                //     : companies.value[0].email;
                // const index = companies.value
                //     .map((item) => item.email)
                //     .indexOf(userAnswers.value.companies);
                // messages.value.push({
                //   from: "user",
                //   body: companies.value[index].name,
                // });
              }
              break;

            case 2:
              // if (botMessages.value[oldValue].type === "multi") {
              //   messages.value.push({
              //     from: "user",
              //     body: userAnswers.value.companies
              //         .map((item) => {
              //           const index = companies.value
              //               .map((company) => company.email)
              //               .indexOf(item);
              //           return index !== -1 ? companies.value[index].name : "";
              //         })
              //         .join(","),
              //   });
              // } else {
              //   messages.value.push({
              //     from: "user",
              //     body: maskedPhone.value,
              //   });
              // }
              // break;
              messages.value.push({
                from: "user",
                body: !manyCompanies.value ? userAnswers.value.companies
                    .map((item) => {
                      const index = companies.value
                          .map((company) => company.email)
                          .indexOf(item);
                      return index !== -1 ? companies.value[index].name : "";
                    })
                    .join(",") : companies.value[companies.value?.findIndex(item => item?.email === userAnswers.value.companies)].name
              });

              break;

            case 3:
              if (botMessages.value[oldValue].type === "phone") {
                messages.value.push({
                  from: "user",
                  body: maskedPhone.value,
                });
              }

              // if (oldValue && botMessages.value[oldValue].type === "comment") {
              //   messages.value.push({
              //     from: "user",
              //     body: userAnswers.value.comment,
              //   });
              //   saveAnswers(userAnswers.value);
              // }

              break;
            case 4:
              if (oldValue && botMessages.value[oldValue].type === "comment") {
                messages.value.push({
                  from: "user",
                  body: userAnswers.value.comment,
                });
              }
              saveAnswers(userAnswers.value);
              break;
          }
          messages.value.push(botMessages.value[val]);

          setTimeout(() => {
            document
                .getElementById(`msg${messages.value.length - 1}`)
                ?.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
          }, 100);
        }
    );


    store.dispatch("fetchUserAnswers").then((data) => {
      if (data) {
        step.value = botMessages.value.length - 1;
      } else {
        messages.value.push(botMessages.value[step.value]);
      }
    });

    return {
      messages,
      step,
      manyCompanies,
      userAnswers,
      maskedPhone,
      mask: "+{7}(000)000-00-00",
      companies,
      botMessages,

      nextStep

      // sendMessage
    };
  },
};
</script>

<style scoped>
.assistant {
  max-width: 70rem;
  width: 100%;
  border-radius: 0.625rem;
  background: linear-gradient(180deg, #101215 0%, #21262e 100%);
  padding: 1rem;
}

.assistant-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.robot {
  width: 4.9375rem;
}

.title {
  color: #f2f3f5;
  font-size: 1.99794rem;
  font-weight: 700;
  line-height: normal;
}

.text {
  color: #959ba3;
  font-size: 0.99894rem;
  line-height: normal;
}

.date-wrapper {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 0.38rem;
  max-width: 100%;
  width: 100%;
}

.date-line {
  max-width: 100%;
  width: 100%;
  height: 0.06244rem;
  background: #3f4147;
}

.date {
  color: #a5aab1;
  font-size: 0.75rem;
  line-height: normal;
  display: flex;
  flex-shrink: 0;
}

.filling {
  margin-top: 1rem;
}

.caption {
  display: flex;
  align-items: center;
}

.robot__small {
  width: 2.4375rem;
}

.caption-name {
  margin-left: 1rem;
  display: inline-flex;
  align-items: flex-start;
  font-size: 0.99894rem;
  font-weight: 500;
  line-height: normal;
}

.caption-name-description {
  margin-left: 0.19rem;
  padding: 0.03rem 0.18731rem;
  border-radius: 0.12488rem;
  background: #5a65ea;
  font-size: 0.62438rem;
  font-weight: 700;
  line-height: normal;
}

.caption-date {
  margin-left: 0.37rem;
  color: #aaaeb6;
  font-size: 0.74925rem;
  line-height: normal;
}

.caption-date__time {
  margin-left: 0.25rem;
}

.messages {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 34rem;
  overflow: auto;
  scrollbar-width: thin;
  align-items: flex-start;
}

.message-wrapper {
  max-width: 100%;
  width: 100%;
}

.message {
  max-width: 60%;
  padding: 1.25rem 1.88rem;
  border-radius: 0.625rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: #4c4c4c;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: normal;
}

.message-my {
  margin-left: auto;
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  border: 1px solid linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
}

.form {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  align-items: center;
  padding: 0.625rem 1rem;
  border-radius: 0.25rem;
  background: #2b2d30;
}

.add {
  width: 1.49844rem;
  height: 1.49844rem;
  background: url("@/assets/imgs/add.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-shrink: 0;
}

.field {
  color: #70747b;
  font-size: 0.875rem;
  padding: 0;
  background: none;
  max-width: 100%;
  width: 100%;
}

.send {
  width: 2rem;
  height: 2rem;
  background: url("@/assets/imgs/send.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-shrink: 0;
}

.robot-message__btn {
  margin-top: 2.813rem;
  font-size: 1.25rem;
}

@media (max-width: 850px) {
  .robot-message__btn {
    margin-top: 1rem;
  }

  .robot-message__btn {
    margin-top: 1rem;

    text-align: center;
  }
}

@media (max-width: 850px) {
  .assistant {
    max-width: 100%;
    padding: 0;
    background: none;
  }

  .title {
    font-size: 1.75rem;
  }

  .text {
    font-size: 0.875rem;
  }

  .date-wrapper {
    margin-top: 1.31rem;
  }

  .filling {
    margin-top: 1.56rem;
  }

  .messages {
    margin-top: 0;
    gap: 0.7rem;
    height: 28rem;
  }

  .message {
    max-width: 80%;
    padding: 0.75rem 1rem;
    font-size: 0.75rem;
  }

  .message-my {
    margin-left: auto;
    background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
    border: 1px solid linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  }

  .form {
    padding: 0.75rem 1rem;
    border-radius: 0.625rem;
    gap: 0.69rem;
  }

  .add {
    width: 1.49844rem;
    height: 1.49844rem;
    background: url("@/assets/imgs/add.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .field {
    color: #70747b;
    font-size: 0.875rem;
    padding: 0;
    background: none;
    max-width: 100%;
    width: 100%;
  }

  .send {
    width: 2rem;
    height: 2rem;
    background: url("@/assets/imgs/send.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .radio-text-wrapper {
    flex-direction: column;
  }
}
</style>
