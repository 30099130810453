<template>
  <div class="landing">
    <header class="header">
      <div class="header-filling container">
        <a href="#" class="logo">
          <img src="@/assets/imgs/landing/logo.png" alt="Лого" class="logo-img"/>
          <p class="logo-text desktop">ЦИФРОВОЙ ПОМОЩНИК</p>
        </a>
        <div class="links desktop">
          <a href="#hero-block" id="hero-link" class="links-element"
             :class="{ 'link-show': currentViewBlock === 'hero-block' }">Начало</a>
          <a href="#tutorial-block" id="tutorial-link" class="links-element"
             :class="{ 'link-show': currentViewBlock === 'tutorial-block' }">Как пользоваться</a>
          <a href="#chance-block" id="chance-link" class="links-element"
             :class="{ 'link-show': currentViewBlock === 'chance-block' }">Шанс</a>
          <a href="#advantagesа-block" id="advantagesа-link" class="links-element"
             :class="{ 'link-show': currentViewBlock === 'advantagesа-block' }">Преимущества</a>
          <a href="#reviews-block" id="reviews-link" class="links-element"
             :class="{ 'link-show': currentViewBlock === 'reviews-block' }">Отзывы</a>
        </div>
        <div class="authorization">
          <router-link v-if="false" :to="{ name: 'registration' }" href="" class="authorization-registration">Вперёд! В
            будущее
          </router-link>
          <router-link :to="{ name: 'authorization' }" href="" class="authorization-enter desktop">Войти
          </router-link>
        </div>
        <button class="burger mobile" @click="menuOpen = !menuOpen"></button>
        <div class="menu mobile" :class="{ 'menu-open': menuOpen }">
          <div class="menu-caption" @click="menuOpen = !menuOpen"></div>
          <div class="menu-filling">
            <div class="links">
              <a @click="menuOpen = !menuOpen" href="#hero-block" id="hero-link" class="links-element"
                 :class="{ 'link-show': currentViewBlock === 'hero-block' }">Начало</a>
              <a @click="menuOpen = !menuOpen" href="#tutorial-block" id="tutorial-link" class="links-element"
                 :class="{ 'link-show': currentViewBlock === 'tutorial-block' }">Как пользоваться</a>
              <a @click="menuOpen = !menuOpen" href="#chance-block" id="chance-link" class="links-element"
                 :class="{ 'link-show': currentViewBlock === 'chance-block' }">Шанс</a>
              <a @click="menuOpen = !menuOpen" href="#advantagesа-block" id="advantagesа-link" class="links-element"
                 :class="{
                  'link-show': currentViewBlock === 'advantagesа-block',
                }">Преимущества</a>
              <a @click="menuOpen = !menuOpen" href="#reviews-block" id="reviews-link" class="links-element"
                 :class="{ 'link-show': currentViewBlock === 'reviews-block' }">Отзывы</a>
            </div>
            <div class="authorization-enter-wrapper">
              <router-link :to="{ name: 'authorization' }" class="authorization-enter">Войти
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </header>

    <main class="main">
      <section class="section hero container" id="hero-block">
        <div class="hero-info">
          <div class="hero-left">
            <h1 class="hero-title">
              ЦИФРОВОЙ ПОМОЩНИК <br/>
              ДЛЯ ВЫБОРА <span class="purple-text">ПРОФЕССИИ</span>
            </h1>
            <p class="hero-text" v-if="!appConfig?.appState?.free">Пройдите 5 миссий и определите, какое будущее <br>
              будет для вас наилучшим</p>
            <div class="hero-statistic">
              <div class="hero-statistic-element">
                <p class="hero-statistic-title">768</p>
                <p class="hero-statistic-text">компаний</p>
              </div>
              <div class="hero-statistic-element">
                <p class="hero-statistic-title">298</p>
                <p class="hero-statistic-text">профессий</p>
              </div>
              <div class="hero-statistic-element">
                <p class="hero-statistic-title">35</p>
                <p class="hero-statistic-text">минут</p>
              </div>
            </div>
            <button @click="checkPayment" class="hero-registration desktop">
              Вперёд! В будущее
            </button>
          </div>
          <div class="hero-right">
            <div class="hero-demonstration">
              <div class="bubble">
                <p class="bubble__text">
                  Смотри <br> видео обо мне!
                </p>
                <div class="bubble__filling">
                  <img src="@/assets/imgs/landing/buble-robot.png" alt="" class="bubble__robot" v-if="false">
                  <button @click="openVideo = !openVideo" class="bubble__btn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                      <path
                          d="M20 40C8.9543 40 0 31.0456 0 20C0 8.9543 8.9543 0 20 0C31.0456 0 40 8.9543 40 20C40 31.0456 31.0456 40 20 40ZM17.2438 12.8292C17.1124 12.7416 16.958 12.6948 16.8 12.6948C16.3582 12.6948 16 13.053 16 13.4948V26.5052C16 26.6632 16.0468 26.8176 16.1344 26.949C16.3794 27.3166 16.8762 27.416 17.2438 27.1708L27.0016 20.6656C27.0894 20.607 27.1648 20.5316 27.2234 20.4438C27.4686 20.0762 27.3692 19.5794 27.0016 19.3344L17.2438 12.8292Z"
                          fill="url(#paint0_linear_217_12864)"/>
                      <defs>
                        <linearGradient id="paint0_linear_217_12864" x1="1.81132" y1="-2.19811e-05" x2="29.6937"
                                        y2="0.779238" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#AD4FEA"/>
                          <stop offset="1" stop-color="#4A58DD"/>
                        </linearGradient>
                      </defs>
                    </svg>
                  </button>
                </div>
                <img src="@/assets/imgs/landing/buble-triangle.svg" alt="" class="bubble__triangle">
              </div>

              <img src="@/assets/imgs/landing/hero-robot.png" alt="" class="hero-demonstration-img"/>
            </div>
            <p class="hero-right-title">
              Умный бот-помощник
            </p>
          </div>
          <router-link :to="{ name: 'registration' }" class="hero-registration mobile">Вперёд! В будущее
          </router-link>
        </div>
        <div class="hero-description">
          <div class="hero-description-element hero-description__who">
            КОМУ НУЖЕН <br/>
            ЦИФРОВОЙ ПОМОЩНИК:
          </div>
          <div class="hero-description-element hero-description__school">
            <img src="@/assets/imgs/landing/school.svg" alt="" class="hero-description-img"/>
            <p class="hero-description-text">
              <span>Школьникам</span> <br/>
              7-11 классов
            </p>
          </div>
          <div class="hero-description-element hero-description__school">
            <img src="@/assets/imgs/landing/suz.svg" alt="" class="hero-description-img"/>
            <p class="hero-description-text">
              <span>Студентам ссузов</span>
            </p>
          </div>
          <div class="hero-description-element hero-description__student">
            <img src="@/assets/imgs/landing/student.svg" alt="" class="hero-description-img"/>

            <p class="hero-description-text">
              <span>Студентам вузов</span><br/>
              1-2 курсов

            </p>
          </div>
        </div>
      </section>

      <div class="video" v-if="openVideo">
        <div class="video-filling">
          <button class="close" @click="openVideo = !openVideo">
            <img src="@/assets/imgs/close.svg" alt="" class="close__img"/>
          </button>
          <iframe class="video-filling" src="https://rutube.ru/play/embed/ba33baa9a87a134f6d9977ff1cd702f9"
                  frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen
                  allowFullScreen></iframe>

        </div>
      </div>

      <section class="section tutorial container" id="tutorial-block">
        <div class="tutorial-filling">
          <div class="tutorial-caption">
            <h2 class="tutorial-caption-element tutorial-caption__title">
              КАК ПОЛЬЗОВАТЬСЯ ЦИФРОВЫМ ПОМОЩНИКОМ
            </h2>
            <div class="tutorial-caption-element tutorial-caption__robot">
              <p class="tutorial-caption-info">
                Сейчас всё <br/>
                расскажу!
              </p>
              <img src="@/assets/imgs/landing/tutorial-robot.png" alt="" class="tutorial-caption-img"/>
            </div>
          </div>
          <div class="tutorial-steps">
            <div class="tutorial-step">
              <div class="tutorial-step-filling">
                <p class="tutorial-step-title">1 ЭТАП</p>
                <div class="tutorial-step-info">
                  <p class="tutorial-step-subtitle">Регистрация </p>
                  <p class="tutorial-step-text">
                    🚫 Это проще, чем кажется! Ты регистрируешься, вносишь свои данные – и вуаля! 🔓 Получаешь доступ к
                    миру возможностей💼
                  </p>
                </div>
              </div>
            </div>
            <div class="tutorial-step">

              <div class="tutorial-step-filling">
                <p class="tutorial-step-title">2 ЭТАП</p>
                <div class="tutorial-step-info">
                  <p class="tutorial-step-subtitle">Общение </p>
                  <p class="tutorial-step-text">
                    🚀 Забудь о скучных тестах выбора профессии. Тебя ждут 5 увлекательных миссий. А Цифровой Помощник
                    станет твоим гидом в мире профессиональных возможностей! 🚀
                  </p>
                </div>
              </div>
            </div>
            <div class="tutorial-step">
              <div class="tutorial-step-filling">
                <p class="tutorial-step-title">3 ЭТАП</p>
                <div class="tutorial-step-info">
                  <p class="tutorial-step-subtitle">Результаты </p>
                  <p class="tutorial-step-text">
                    Дерзай! Не упусти шанс заглянуть в будущее и отправляйся в свое профессиональное приключение! 🌐
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section chance container" id="chance-block">
        <h3 class="chance-title desktop">ЦИФРОВОЙ ПОМОЩНИК – ЭТО ШАНС</h3>
        <h3 class="chance-title mobile">
          ЦИФРОВОЙ <br/>
          ПОМОЩНИК – ЭТО <br/>
          ШАНС
        </h3>
        <div class="chance-filling">
          <p class="chance-element">
            <span>Узнать</span> свои <span>сильные стороны</span> и понять, как их развивать
          </p>
          <p class="chance-element">
            <span>Заглянуть</span> в <span>будущее</span> рынка труда
          </p>
          <p class="chance-element">
            <span>Определить</span> подходящие <span>профессии</span>
          </p>
          <p class="chance-element">
            <span>Понять,</span> нужно ли становиться <span>предпринимателем</span>
          </p>
          <p class="chance-element">
            <span>Получить целевой договор</span> на обучение
          </p>
          <p class="chance-element">
            <span>Найти</span> интересного <span>работодателя</span>
          </p>
        </div>
      </section>

      <section class="section advantages container" id="advantagesа-block">
        <div class="advantages-filling">
          <div class="advantages-list">
            <div class="advantages-list-element">
              <p class="advantages-list-title advantages-list-title__first">
                ПРЕИМУЩЕСТВА <br/>
                ЦИФРОВОГО <br/>
                ПОМОЩНИКА
              </p>
            </div>
            <div class="advantages-list-element">
              <p class="advantages-list-title">Доступность</p>
              <p class="advantages-list-text">
                Можно участвовать из любой точки планеты
              </p>
            </div>
            <div class="advantages-list-element">
              <p class="advantages-list-title">Игровой формат</p>
              <p class="advantages-list-text">
                5 миссий и погружение в разные ситуации
              </p>
            </div>
            <div class="advantages-list-element">
              <p class="advantages-list-title">Современная подача</p>
              <p class="advantages-list-text">
                Взаимодействие с цифровым помощником как с другом
              </p>
            </div>
            <div class="advantages-list-element">
              <p class="advantages-list-title">
                Искусственный <br/>
                интеллект
              </p>
              <p class="advantages-list-text">
                На основе ответов быстро и четко формируется индивидуальный
                портрет способностей и перспектив пользователя
              </p>
            </div>
            <div class="advantages-list-element">
              <p class="advantages-list-title">
                531 работодатель
              </p>
              <p class="advantages-list-text">
                Выступающие партнёрами проекта, заинтересованы в талантливых потенциальных сотрудниках
              </p>
            </div>
          </div>
          <div class="advantages-robot">
            <p class="advantages-robot-text">
              И еще я очень <br/>
              дружелюбный!
            </p>
            <img src="@/assets/imgs/landing/advantages-robot.png" alt="" class="advantages-robot-img desktop"/>
            <img src="@/assets/imgs/landing/advantages-robot-mobile.png" alt="" class="advantages-robot-img mobile"/>
          </div>
        </div>
      </section>

      <section class="section reviews" id="reviews-block">
        <h4 class="reviews-title container">ОТЗЫВЫ О ЦИФРОВОМ ПОМОЩНИКЕ</h4>
        <div class="reviews-filling">
          <div class="reviews-list">
            <div class="reviews-element " v-for="review in reviews" :key="review.id">
              <div class="reviews-caption">
                <p class="reviews-name">{{ review.name }}</p>
                <img src="@/assets/imgs/landing/stars.png" alt="" class="reviews-star"/>
              </div>
              <p class="reviews-place">
                {{ review.info }}
              </p>
              <div class="reviews-content">
                <p class="reviews-text " :class="{ 'reviews-text--open': review.open === true }">
                  {{ review.text }}
                </p>
                <div v-if="false" class="reviews-help">
                  <p class="reviews-help__text">Вам помог этот отзыв?</p>
                  <div class="reviews-grade">
                    <button class="reviews-grade__button like">
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path
                            d="M28.95 12.1199C28.5285 11.6139 28.0011 11.2066 27.405 10.9267C26.8089 10.6467 26.1586 10.5011 25.5 10.4999H18.66L19.5 8.35495C19.8494 7.41577 19.9658 6.40585 19.8391 5.41183C19.7124 4.4178 19.3465 3.46933 18.7728 2.64779C18.199 1.82624 17.4345 1.15614 16.5449 0.69496C15.6553 0.23378 14.6671 -0.00471121 13.665 -5.51835e-05C13.3765 0.000546859 13.0942 0.0843577 12.8521 0.241329C12.61 0.3983 12.4183 0.62177 12.3 0.884945L8.025 10.4999H4.5C3.30653 10.4999 2.16193 10.9741 1.31802 11.818C0.474106 12.6619 0 13.8065 0 14.9999V25.4999C0 26.6934 0.474106 27.838 1.31802 28.6819C2.16193 29.5258 3.30653 29.9999 4.5 29.9999H23.595C24.6477 29.9996 25.667 29.6302 26.4755 28.956C27.284 28.2818 27.8305 27.3455 28.02 26.3099L29.925 15.8099C30.0428 15.161 30.0166 14.4941 29.8481 13.8565C29.6796 13.2188 29.373 12.626 28.95 12.1199ZM7.5 26.9999H4.5C4.10218 26.9999 3.72064 26.8419 3.43934 26.5606C3.15804 26.2793 3 25.8978 3 25.4999V14.9999C3 14.6021 3.15804 14.2206 3.43934 13.9393C3.72064 13.658 4.10218 13.4999 4.5 13.4999H7.5V26.9999ZM27 15.2699L25.095 25.7699C25.0311 26.1194 24.8453 26.4348 24.5706 26.66C24.2959 26.8853 23.9502 27.0057 23.595 26.9999H10.5V12.3149L14.58 3.13494C15 3.25738 15.39 3.46555 15.7255 3.74632C16.061 4.02708 16.3346 4.37435 16.5291 4.76618C16.7236 5.15802 16.8348 5.58593 16.8556 6.0229C16.8764 6.45986 16.8064 6.8964 16.65 7.30494L15.855 9.44995C15.6856 9.90333 15.6284 10.391 15.6883 10.8712C15.7482 11.3515 15.9234 11.8102 16.199 12.2081C16.4746 12.6059 16.8423 12.9312 17.2709 13.1562C17.6994 13.3812 18.176 13.4991 18.66 13.4999H25.5C25.7204 13.4996 25.9381 13.5478 26.1377 13.6411C26.3374 13.7345 26.514 13.8706 26.655 14.0399C26.7995 14.2069 26.9053 14.4038 26.965 14.6164C27.0246 14.829 27.0366 15.0522 27 15.2699Z"
                            fill="#6840DF"/>
                      </svg>
                      1
                    </button>
                    <button class="reviews-grade__button like">
                      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                        <path
                            d="M28.5005 3H9.40545C8.35274 3.00035 7.33346 3.36976 6.52497 4.04396C5.71648 4.71817 5.16994 5.65448 4.98045 6.69L3.07545 17.19C2.95676 17.8387 2.98207 18.5055 3.14961 19.1433C3.31714 19.7811 3.6228 20.3742 4.04496 20.8808C4.46711 21.3874 4.99545 21.795 5.59258 22.0748C6.1897 22.3546 6.84102 22.4998 7.50045 22.5H14.3405L13.5005 24.645C13.1511 25.5842 13.0347 26.5941 13.1614 27.5881C13.288 28.5821 13.6539 29.5306 14.2277 30.3522C14.8014 31.1737 15.5659 31.8438 16.4555 32.305C17.3452 32.7662 18.3334 33.0047 19.3355 33C19.624 32.9994 19.9062 32.9156 20.1483 32.7586C20.3905 32.6016 20.5822 32.3782 20.7005 32.115L24.9755 22.5H28.5005C29.6939 22.5 30.8385 22.0259 31.6824 21.182C32.5263 20.3381 33.0005 19.1935 33.0005 18V7.5C33.0005 6.30653 32.5263 5.16193 31.6824 4.31802C30.8385 3.47411 29.6939 3 28.5005 3ZM22.5005 20.685L18.4205 29.865C18.003 29.7387 17.6158 29.5282 17.2828 29.2466C16.9497 28.965 16.6779 28.6182 16.4839 28.2275C16.29 27.8369 16.1781 27.4106 16.1552 26.9751C16.1322 26.5395 16.1987 26.1039 16.3505 25.695L17.1455 23.55C17.3149 23.0966 17.372 22.609 17.3121 22.1287C17.2522 21.6484 17.077 21.1898 16.8015 20.7919C16.5259 20.394 16.1581 20.0687 15.7296 19.8437C15.3011 19.6188 14.8244 19.5008 14.3405 19.5H7.50045C7.28009 19.5004 7.06235 19.4522 6.86272 19.3588C6.6631 19.2655 6.48649 19.1293 6.34545 18.96C6.20095 18.793 6.09511 18.5962 6.03547 18.3836C5.97583 18.1709 5.96388 17.9478 6.00045 17.73L7.90545 7.23C7.96935 6.88056 8.15518 6.56514 8.42987 6.3399C8.70456 6.11465 9.05027 5.99421 9.40545 6H22.5005V20.685ZM30.0005 18C30.0005 18.3978 29.8424 18.7794 29.5611 19.0607C29.2798 19.342 28.8983 19.5 28.5005 19.5H25.5005V6H28.5005C28.8983 6 29.2798 6.15804 29.5611 6.43934C29.8424 6.72064 30.0005 7.10218 30.0005 7.5V18Z"
                            fill="white"/>
                      </svg>
                      0
                    </button>
                  </div>
                </div>
              </div>
              <button class="reviews__btn" @click="review.open = !review.open">
                {{ review.open ? 'Скрыть' : 'Читать полностью' }}
              </button>
            </div>
          </div>
        </div>
      </section>

      <section class="section action container" v-if="false">
        <div class="action-filling">
          <h4 class="action-title">
            ЗАРЕГИСТРИРУЙТЕСЬ, ЧТОБЫ УЧАСТВОВАТЬ В АКЦИИ
          </h4>
          <div class="action-list">
            <input type="text" placeholder="ФИО" class="action-fild"/>
            <input type="text" placeholder="Должность" class="action-fild"/>
            <input type="text" placeholder="Название организации" class="action-fild"/>
            <input type="text" placeholder="Регион" class="action-fild"/>
            <input type="text" placeholder="Должность" class="action-fild"/>
            <input type="email" placeholder="E-mail" class="action-fild"/>
            <input type="text" placeholder="Название организации" class="action-fild"/>
            <input type="tel" placeholder="Телефон" class="action-fild"/>
          </div>
          <button class="action-send">Отправить заявку</button>
          <p class="action-text">
            «Нажимая на кнопку, вы даете согласие на обработку персональных
            данных и соглашаетесь c политикой конфиденциальности»
          </p>
        </div>
      </section>

      <section class="section questions container">
        <div class="questions-filling">
          <div class="questions-element questions-element__robot">
            <p class="questions-text">Остались вопросы?</p>
            <img src="@/assets/imgs/landing/questions-robot.png" alt="" class="questions-img desktop"/>
            <img src="@/assets/imgs/landing/questions-robot-mobile.png" alt="" class="questions-img mobile"/>
          </div>
          <div class="questions-element questions-element__tg">
            <a href="mailto:tsip.rf@mail.ru" class="questions-link">Отправить вопрос на почту</a>
          </div>
        </div>
      </section>
    </main>

    <footer class="footer">
      <div class="footer-filling container">
        <div class="footer-caption">
          <a href="https://profstories.ru/" class="footer__link" target="_blank">
            <img src="@/assets/imgs/companies/profstor.png" alt="" class="footer-img ps"/>
          </a>
          <img v-if="false" src="@/assets/imgs/landing/dis.png" alt="" class="footer-img dis"/>
          <a href="https://засобой.рф/" class="footer__link" target="_blank">
            <img src="@/assets/imgs/companies/za.png" alt="" class="footer-img za"/>
          </a>
          <img v-if="false" src="@/assets/imgs/landing/work.png" alt="" class="footer-img work"/>
        </div>
        <div class="footer-info">
          <div class="footer-info__block footer-info__left">
            <p class="footer-info-element ">Общество с ограниченной ответственностью «Код 93»
              <br>
              ИНН: 7430036788, ОГРН: 1227400006635
            </p>
            <p class="footer-info-element fw-6">
              Для работодателей
            </p>
            <div class="footer__links">
              <p class="footer-info-element">Если у вас есть вопросы по сотрудничеству, то можете написать нам
                на почту <a href="mailto:tsip.rf@mail.ru"> tsip.rf@mail.ru</a></p>
            </div>
          </div>
          <div class="footer-info__block">
            <p class="footer-info-element">
              Адрес организации: 456671, Челябинская область, Красноармейский р-н, п. Петровский, Учительская ул., д.
              14, ком. 1 <br>
              Контактное лицо: Пронь Ольга, +79170284454, эл. почта – tsip.rf@mail.ru
            </p>
            <div class="footer__links">
              <a href="/docs/ofer.pdf" target="_blank" class="footer-info-element">ДОГОВОР ОФЕРТА
              </a>
              <a :href="`${appConfig.domain}/files/data/politika_obrabotki_personalnih_dannih.pdf`" target="_blank"
                 class="footer-info-element">ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</a>
              <a href="/docs/polzovatelskoe_soglashenie.pdf" class="footer-info-element"
                 target="_blank">ПОЛЬЗОВАТЕЛЬСКОЕ
                СОГЛАШЕНИЕ
              </a>
              <a href="/docs/soglashenie_na_obrabotky_personalnih_dannih.pdf" class="footer-info-element"
                 target="_blank">СОГЛАСИЕ
                НА ОБРАБОТКУ
                ПЕРСОНАЛЬНЫХ ДАННЫХ
              </a>
              <a href="/docs/soglashenie_na_obrabotky_personalnih_dannih_nesovershennoletnih.pdf"
                 class="footer-info-element" target="_blank">СОГЛАСИЕ НА ОБРАБОТКУ
                ПЕРСОНАЛЬНЫХ ДАННЫХ НЕСОВЕРШЕННОЛЕТНИХ
              </a>
            </div>

          </div>
          <div class="footer-info__block">

          </div>
        </div>
      </div>
    </footer>
    <PaymentMoneyElement v-if="viewPaymentModal" @close="viewPaymentModal = false"></PaymentMoneyElement>
  </div>
</template>

<script>
import {onMounted, ref, shallowRef} from "vue";
import {appConfig} from "@/lib/appConfig";
import PaymentMoneyElement from "@/components/profile/PaymentMoneyElement.vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";

export default {
  name: "PageWrapper",
  components: {PaymentMoneyElement},
  setup() {

    const router = useRouter();
    const store = useStore();

    const menuOpen = shallowRef(false);
    const openVideo = shallowRef(false);
    const reviews = ref([
      {
        id: 1,
        name: "Яна",
        info: "ученица 8 класса из Республики Кабардино-Балкария ",
        text: "Мне очень всё понравилось, очень много вариантов ответов. Больше всего понравилась миссия, где я узнала, хочу ли я быть предпринимателем или нет",
        open: false,
      },
      {
        id: 2,
        name: "Дарья",
        info: "студентка 1 курса Шатурского энергетического техникума",
        text: "Спасибо за предоставленную возможность пройти такой увлекательный и познавательный тест. В пятой миссии очень понравилось видео, которое помогло ещё лучше понять себя и оценить свои возможности. Также хочу отметить, что все остальные вопросы оставили только положительные эмоции!",
        open: false,
      },
      {
        id: 3,
        name: "Екатерина",
        info: "студентка 1 курса Владимирского авиамеханического колледжа",
        text: "Спасибо за интересные миссии, они помогают задуматься о настоящем и будущем. Благодаря им каждый человек, который не определился или определился, но не до конца, сможет найти себя в различных профессиях. Сейчас не каждый может сразу определиться с профессией, и эти миссии могут направить человека к подходящей ему отрасли и профессии",
        open: false,
      },
      {
        id: 4,
        name: "Анастасия",
        info: "Башкортостан",
        text: "Раньше никогда не проходила онлайн тестирования по профессии. Очень хорошие впечатления. Помощник очень располагает на ответы, нет нудности, как в тестах на IQ и прочей психологии. Сейчас я уже учусь в колледже, о поступлении в вуз не задумывалась, целевое не запрашивала, хотелось просто проверить себя",
        open: false,
      },
      {
        id: 5,
        name: "Карина",
        info: "активист в школе из Новосибирской области",
        text: "Хороший сервис, мне всеё понравилось. Дополнительно посмотрела профессии на вашем сайте, есть интересные мне. Для меня целевое направление очень интересно, я думала, что его “выдают”, а про “запросить” не знала. В будущем году или даже заранее займусь этим вопросом. Спасибо Вам, что предоставляете такую возможность в принципе",
        open: false,
      },
      {
        id: 6,
        name: "Егор",
        info: "участник олимпиады в сфере IT из Ставропольского края",
        text: "Платформой легко было пользоваться, все мультяшное, но очень в меру. Хороший дизайн. Для себя понял подбор профессии и получение целевого договора",
        open: false,
      },
      {
        id: 7,
        name: "Алина",
        info: "Смоленская область",
        text: "Рассматривала раньше заключение целевого договора, но родители отговорили, так как советуют поступать после колледжа самой. Сейчас я учусь на экономиста в колледже. Цифровой помощник довольно точно показал, что я гуманитарий :) Дизайн супер, поднял настроение тогда",
        open: false,
      },
      {
        id: 8,
        name: "Арина",
        info: "член Российского движения школьников из Калмыкии",
        text: "Очень удобно, впервые встретилась с такой возможностью, скинула своим друзьям из других школ",
        open: false,
      },
      {
        id: 9,
        name: "Добрыня",
        info: "активист из ХМАО",
        text: "В помощнике мне предложили вакансии из другого края. Думаю, можно будет попробовать связаться в будущем с другими работодателями и переехать, целевой договор может быть полезен, после выпуска из школы планирую поступать и жить уже сам, а не с помощью родителей",
        open: false,
      },
      {
        id: 10,
        name: "Ольга",
        info: "финалистка научно-технической олимпиады",
        text: "Цифровой помощник помог подобрать профессии, связанные с фармацевтикой. Проходила вместе с другом",
        open: false,
      },
    ]);

    const currentViewBlock = shallowRef("hero-block");
    const viewPaymentModal = shallowRef(false);

    function checkPayment() {
      if (appConfig.appState.free || store.getters.token) {
        return router.push({name: 'authorization'});
      }

      viewPaymentModal.value = true;

    }

    onMounted(() => {
      const textObserverBlock = new IntersectionObserver(
          function (entry) {
            entry.forEach((change) => {
              if (change.isIntersecting) {
                currentViewBlock.value = change.target.id;
              }
            });
          },
          {
            threshold: [0],
          }
      );
      textObserverBlock.observe(document.getElementById("hero-block"));
      textObserverBlock.observe(document.getElementById("tutorial-block"));
      textObserverBlock.observe(document.getElementById("chance-block"));
      textObserverBlock.observe(document.getElementById("advantagesа-block"));
      textObserverBlock.observe(document.getElementById("reviews-block"));
    });

    onMounted(() => {
      if (sessionStorage.getItem('openPaymentModal') === 'true') {
        viewPaymentModal.value = true;
        sessionStorage.removeItem('openPaymentModal');
      }
    })

    return {
      currentViewBlock,
      menuOpen,
      openVideo,
      reviews,
      appConfig,
      checkPayment,
      viewPaymentModal


    };
  }
};
</script>

<style scoped>
html {
  background: #020410;
}

main {
  background: #020410;
}

* {
  scroll-margin-top: 4vw;
  font-family: "PtRootUi", sans-serif !important;
}

.container {
  max-width: 83.33vw;
  padding: 0;
  width: 100%;
  margin: 0 auto;
}

.section {
  padding: 6.25vw 0;
  border-bottom: 1px solid #7c808b;
}

.header {
  max-width: 100%;
  width: 100%;
  border-bottom: 1px solid #7c808b;
  position: sticky;
  top: 0;
  background: #000000;
  z-index: 6;
}

.header-filling {
  padding: 1.25vw 0;
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  gap: 0.83vw;
  align-items: center;
  margin-right: 1.04vw;
}

.logo-img {
  width: 3.7vw;
}

.logo-text {
  padding: 0.4vw 0;
  font-size: 1.04vw;
  font-weight: 700;
  background: linear-gradient(151deg, #e5bdff 0%, #b5bcff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "PtRootUi", sans-serif
}

.links {
  margin-left: auto;
  display: flex;
  gap: 1.67vw;
}

.links-element {
  color: #fff;
  text-align: center;
  font-size: 0.83vw;
  font-weight: 700;
  line-height: normal;
  opacity: 0.6;
}

.link-show {
  opacity: 1;
  text-decoration-line: underline;
}

.authorization {
  margin-left: 1.67vw;
  display: flex;
  gap: 0.83vw;
  align-items: center;
}

.authorization-registration {
  padding: 0.83vw 1.67vw;
  border-radius: 1.04vw;
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  color: #fff;
  font-size: 0.83vw;
  font-weight: 700;
  line-height: normal;
}

.authorization-enter {
  padding: 0.83vw 1.67vw;
  border-radius: 1.04vw;
  background: rgba(223, 230, 244, 0.2);
  color: #fff;
  font-size: 0.83vw;
  font-weight: 700;
  line-height: normal;
}

.hero {
  padding-top: 5.1vw;
}

.hero-info {
  display: flex;
  gap: 1.09vw;
}

.hero-left {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hero-title {
  font-size: 4.28vw;
  font-weight: 700;
  line-height: 100%;
}

.hero-text {
  font-size: 2vw;
  font-weight: 700;
  line-height: 100%;
}

.hero-statistic {
  display: flex;
  flex-wrap: wrap;
  gap: 0.89vw;
}

.hero-statistic-element {
  max-width: 13.02vw;
  width: 100%;
}

.hero-statistic-title {
  font-size: 4.48vw;
  font-weight: 700;
  line-height: 100%;
}

.hero-statistic-text {
  margin-top: 0.21vw;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 100%;
  opacity: 0.800000011920929;
}

.hero-registration {
  padding: 2.08vw;
  width: 100%;
  max-width: 100%;
  background: linear-gradient(180deg, #D88FFF 0%, #AE4BE3 11.33%, #5C4ADA 93.23%, #442FDB 100%);
  color: #fff;
  font-size: 2.08vw;
  font-weight: 700;
  line-height: 2.50vw;
  /* 100% */
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 1.25vw;
  border: 0.16vw solid #FFF;
}

.hero-right {
  max-width: 26.88vw;
  width: 100%;
  border-radius: 1.04vw;
  background: radial-gradient(149.55% 139.67% at 1.15% 1.32%,
  rgba(173, 79, 234, 0.6) 0%,
  rgba(74, 88, 221, 0.6) 100%);
}

.hero-demonstration {
  position: relative;
}

.hero-demonstration-text {
  position: absolute;
  bottom: 9.01vw;
  border-radius: 1.04vw;
  background: #fff;
  padding: 1.35vw 1.88vw 1.61vw 1.46vw;
  color: #000;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  left: -13.65vw;
}

.hero-demonstration-text button {
  color: #000;
  text-decoration: underline;
}

.hero-demonstration-text::after {
  position: absolute;
  content: "";
  display: block;
  background: url("@/assets/imgs/landing/triangle-right.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 1.93vw;
  height: 2.4vw;
  right: -1.7vw;
  bottom: 0.47vw;
}

.hero-demonstration-img {
  position: relative;
  width: 30vw;
  height: 32.97vw;
  right: -3.39vw;
}

.bubble {
  position: absolute;
  border-radius: 1.25vw;
  background: #2E313E;
  padding: 1.04vw 1.15vw 0 1.04vw;
  right: 23vw;
  bottom: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 14vw;
}

.bubble__text {
  background: linear-gradient(92deg, #FFF 4.41%, #7582FF 72.27%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.25vw;
  font-weight: 700;
  line-height: 110%;
  text-transform: uppercase;
}

.bubble__filling {
  margin-top: 0.42vw;
  display: flex;
  align-items: flex-end;
  gap: 2.92vw;
}

.bubble__robot {
  width: 7.03vw;
}

.bubble__btn {
  width: 2.08vw;
  height: 2.08vw;
  margin-bottom: 1.15vw;
  position: relative;
  z-index: 4;
}

.bubble__btn svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bubble__triangle {
  position: absolute;
  right: -2.66vw;
  width: 2.66vw;
}

.hero-right-title {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.67vw;
  font-weight: 700;
  line-height: 120%;
  padding: 0 1.67vw 1.67vw 1.67vw;
}

.hero-right-title span {
  color: #fff;
  font-size: 1.67vw;
  font-weight: 700;
  line-height: 120%;
}

.hero-free {
  margin-top: 1.04vw;
  padding: 1.25vw;
  font-size: 1.25vw;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  border-radius: 1.04vw;
  background: rgba(223, 230, 244, 0.2);
}

.hero-description {
  margin-top: 1.04vw;
  display: flex;
  gap: 1.15vw;
  max-width: 100%;
  width: 100%;
}

.hero-description-element {
  max-width: 100%;
  width: 100%;
  display: flex;
  gap: 1.67vw;
  border-radius: 1.04vw;
  background: rgba(223, 230, 244, 0.2);
  padding: 1.67vw 2.19vw;
  align-items: center;
}

.hero-description__who {
  color: #020410;
  font-size: 1.25vw;
  font-weight: 700;
  line-height: 120%;
  background: #e9edfc;
}

.hero-description-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.25vw;
  font-weight: 500;
  line-height: normal;
}

.hero-description-text span {
  color: #fff;
  font-weight: 700;
}

.hero-description-img {
  width: 2.71vw;
  object-fit: contain;
}

.tutorial-filling {
  border-radius: 1.04vw;
  background: rgba(143, 150, 164, 0.55);
  padding: 1.04vw;
}

.tutorial-caption {
  display: flex;
  gap: 1.04vw;
}

.tutorial-caption-element {
  border-radius: 1.04vw;
  background: #393c49;
  max-width: 100%;
  width: 100%;
  height: 14.38vw;
  position: relative;
  justify-content: center;
  display: flex;
}

.tutorial-caption__title {
  color: #fff;
  text-align: center;
  font-size: 3.33vw;
  font-weight: 700;
  line-height: 100%;
  display: flex;
  align-items: center;
}

.tutorial-caption-info {
  padding: 1.56vw 1.25vw 1.41vw 1.25vw;
  position: absolute;
  color: #000;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  border-radius: 1.04vw;
  background: #fff;
  z-index: 2;
  left: 2.5vw;
  top: -4.01vw;
}

.tutorial-caption-info::after {
  position: absolute;
  content: "";
  display: block;
  background: url("@/assets/imgs/landing/triangle-right.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 1.93vw;
  height: 2.4vw;
  transform: rotate(90deg);
  left: 0.47vw;
  bottom: -1.77vw;
}

.tutorial-caption-img {
  width: 25.05vw;
  position: absolute;
  bottom: 0;
}

.tutorial-steps {
  margin-top: 1.04vw;
  display: flex;
  gap: 1.04vw;
}

.tutorial-step {
  max-width: 100%;
  width: 100%;
  padding: 1.67vw;
  border-radius: 1.04vw;
  background: #fff;
}

.tutorial-step-date {
  color: #595959;
  font-size: 0.94vw;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
}

.tutorial-step-filling {
  display: flex;
  flex-direction: column;
}

.tutorial-step-title {
  padding: 0.52vw 1.09vw;
  color: #fff;
  font-size: 1.67vw;
  font-weight: 700;
  line-height: 120%;
  display: flex;
  flex-shrink: 0;
  border-radius: 0.52vw;
  background: linear-gradient(92deg, #AD4FEA 4.41%, #4A58DD 72.27%);
}

.tutorial-step-subtitle {
  margin-top: 1.25vw;
  color: #020410;
  font-size: 1.67vw;
  font-weight: 700;
  line-height: 120%;
}

.tutorial-step-text {
  margin-top: 0.73vw;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.94vw;
  font-weight: 500;
  line-height: normal;
}

.chance-title {
  color: #fff;
  font-size: 2.19vw;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
}

.chance-filling {
  margin-top: 2.19vw;
  display: flex;
  flex-wrap: wrap;
  gap: 1.04vw;
  justify-content: center;
}

.chance-element {
  padding: 1.25vw 1.67vw;
  border-radius: 1.04vw;
  background: #505461;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.25vw;
  font-weight: 500;
  line-height: normal;
}

.chance-element span {
  color: #fff;
  font-size: 1.67vw;
  font-weight: 700;
  line-height: normal;
}

.advantages-filling {
  display: flex;
}

.advantages-list {
  display: grid;
  max-width: 100%;
  width: 100%;
  row-gap: 5vw;
  column-gap: 1.04vw;
  grid-template-columns: repeat(2, 1fr);
}

.advantages-list-element {
  max-width: 100%;
  width: 100%;
}

.advantages-list-title {
  color: #fff;
  font-size: 2.19vw;
  font-weight: 700;
  line-height: 2.5vw;
}

.advantages-list-text {
  margin-top: 0.42vw;
  max-width: 20.05vw;
  width: 100%;
  color: #fff;
  font-size: 1.25vw;
  font-weight: 700;
  line-height: normal;
  opacity: 0.6000000238418579;
}

.advantages-robot {
  margin-top: 1.61vw;
  max-width: 20.99vw;
  width: 100%;
  border-radius: 1.04vw;
  background: rgba(223, 230, 244, 0.25);
  position: relative;
}

.advantages-robot-text {
  padding: 1.04vw;
  position: absolute;
  color: #000;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  border-radius: 1.04vw;
  background: #fff;
  z-index: 2;
  left: 2vw;
  top: -1.01vw;
}

.advantages-robot-text::after {
  position: absolute;
  content: "";
  display: block;
  background: url("@/assets/imgs/landing/triangle-right.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 1.93vw;
  height: 2.4vw;
  transform: rotate(90deg);
  left: 0.47vw;
  bottom: -1.81vw;
}

.advantages-robot-img {
  width: 25.16vw;
  transform: rotate(7.78deg);
  position: absolute;
  bottom: 1.72vw;
  right: 0.26vw;
}

.reviews {
  padding: 6.25vw 0;
  border: none;
}

.reviews-title {
  color: #fff;
  font-size: 2.24vw;
  font-weight: 700;
  line-height: 120%;
}

.reviews-filling {
  margin-top: 2.92vw;
  max-width: 100%;
  width: 100%;
  position: relative;

}


.reviews-list {
  padding: 0 1.04vw;
  display: flex;
  align-items: flex-start;
  overflow: auto;
  gap: 1.04vw;
  scrollbar-width: thin;
  z-index: 2;
  top: 0;
  width: 100%;
}

.reviews-element:nth-child(1) {
  margin-left: 7.1vw;
}

.reviews-element {
  width: 34.11vw;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 0.83vw;
  background: #1F222F;
  padding: 1.67vw;
  transition: all 0.5s ease;
  overflow: hidden;
}

.reviews-content {
  margin-top: 2.19vw;
}

.reviews__btn {
  padding-top: 1.04vw;
  color: #616161;
  font-size: 1.88vw;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.reviews-caption {
  display: flex;
  align-items: center;
  gap: 0.83vw;
}

.reviews-name {
  background: linear-gradient(151deg, #dca6ff 0%, #8691ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: normal;
}

.reviews-star {
  width: 7.4vw;
}

.reviews-place {
  margin-top: 0.68vw;
  color: #fff;
  font-size: 1.04vw;
  font-weight: 500;
  line-height: 1.67vw;
  opacity: 0.800000011920929;
}

.reviews-text {
  color: #fff;
  font-size: 1.88vw;
  font-weight: 500;
  line-height: normal;
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: anywhere;
  -webkit-line-clamp: 4;
  word-break: break-word;
  transition: all 0.5s ease;
}

.reviews-text--open {
  display: inline;
}

.action-filling {
  max-width: 100%;
  width: 100%;
  border-radius: 1.04vw;
  background: linear-gradient(151deg, #a446e0 0%, #4350d2 100%);
  padding: 4.17vw 7.03vw;
}

.action {
  border-top: 1px solid #7c808b;
  border-bottom: none;
  padding-bottom: 0;
}

.action-title {
  color: #fff;
  text-align: center;
  font-size: 2.19vw;
  font-weight: 700;
  line-height: 120%;
}

.action-list {
  margin-top: 4.17vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.15vw;
  row-gap: 2.5vw;
}

.action-fild {
  padding: 0 0 1.46vw 0;
  background: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 1.67vw;
}

.action-send {
  margin-top: 2.92vw;
  padding: 1.98vw;
  color: #4a58dd;
  text-align: center;
  font-size: 1.67vw;
  font-weight: 700;
  line-height: normal;
  border-radius: 1.04vw;
  background: #fff;
  max-width: 100%;
  width: 100%;
}

.action-text {
  margin-top: 1.25vw;
  color: #fff;
  text-align: center;
  font-size: 0.78vw;
  font-weight: 400;
  line-height: 100%;
  text-align: center;
  opacity: 0.800000011920929;
}

.questions {
  border-bottom: none;
}

.questions-filling {
  display: flex;
  gap: 1.04vw;
  border-radius: 1.04vw;
  background: rgba(143, 150, 164, 0.55);
  padding: 1.04vw;
}

.questions-element {
  max-width: 100%;
  width: 100%;
}

.questions-element__robot {
  max-width: 26.46vw;
  position: relative;
}

.questions-img {
  max-width: 100%;
  width: 100%;
}

.questions-text {
  padding: 0.94vw 1.46vw 1.2vw 1.56vw;
  position: absolute;

  top: 0.83vw;
  border-radius: 1.04vw;
  background: #fff;
  color: #7a7c84;
  font-size: 1.67vw;
  font-weight: 500;
  line-height: 100%;
  left: 24.64vw;
  display: inline-flex;
  flex-shrink: 0;
  width: 18.07vw;
}

.questions-text::after {
  position: absolute;
  content: "";
  display: block;
  background: url("@/assets/imgs/landing/triangle-left.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 2.55vw;
  height: 1.67vw;
  left: -1.4vw;
  bottom: 0;
}

.questions-element__tg {
  border-radius: 1.04vw;
  background: #1f2129;
  padding: 1.04vw;
  display: flex;
  flex-direction: column;
}

.questions-link {
  padding: 0.83vw 1.04vw 1.04vw 1.04vw;
  margin-top: auto;
  border-radius: 1.04vw;
  background: #59a3d6;
  max-width: 100%;
  width: 100%;
  color: #fff;
  font-size: 1.67vw;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.footer {
  background: #020410;
  padding-bottom: 6.25vw;
}

.footer-caption {
  display: flex;
  justify-content: space-between;
  gap: 1.04vw;
  flex-wrap: wrap;
}

.footer-img {
  object-fit: contain;
}

.footer__link {
  display: flex;
  align-items: center;
}

.ps {
  width: 5.68vw;
}

.dis {
  width: 6.2vw;
}

.za {
  width: 12.76vw;
}


.work {
  width: 9.64vw;
}

.footer-info {
  border-top: 1px solid #7c808b;
  margin-top: 1.67vw;
  padding-top: 1.67vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  gap: 2rem;
  row-gap: 1rem;
}

.footer-info__block {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
}

.footer__links {
  display: flex;
  width: 100%;
  max-width: 100%;
  gap: 0.5vw;
  flex-wrap: wrap;
}

.footer__links a {
  text-decoration: underline;
}

.footer-info-element {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.83vw;
  font-weight: 500;
  line-height: normal;
}

.fw-6 {
  font-weight: 600;
}

/* video */
.video {
  position: fixed;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 1rem;
}

.video-filling {
  margin: auto;
  max-width: 70vw;
  width: 100%;
  position: relative;
  display: flex;
}

iframe {
  max-width: 100%;
  width: 100%;
  height: 80vh;
  height: 80dvh;
}

.close {
  position: absolute;
  right: -3rem;
  top: 0;
  padding: 0 0.62rem 0.62rem 0.62rem;
  cursor: pointer;
}

.close__img {
  width: 1.75rem;
  height: 1.75rem;
}

@media (max-width: 1600px) {
  .container {
    max-width: 86.11vw;
  }

  .section {
    padding: 6.46vw 0;
  }

  .header-filling {
    padding: 1.67vw 0;
    display: flex;
    align-items: center;
  }

  .logo {
    gap: 1.11vw;
  }

  .logo-img {
    width: 4.93vw;
  }

  .logo-text {
    font-size: 1.39vw;
  }

  .links {
    gap: 2.29vw;
  }

  .links-element {
    font-size: 1.11vw;
  }

  .link-show {
    opacity: 1;
    text-decoration-line: underline;
  }

  .authorization {
    margin-left: 2.22vw;
    gap: 1.11vw;
  }

  .authorization-registration {
    padding: 1.11vw 2.22vw;
    border-radius: 1.39vw;
    font-size: 0.97vw;
  }

  .authorization-enter {
    padding: 1.11vw 2.22vw;
    border-radius: 1.39vw;
    font-size: 0.97vw;
  }

  .hero {
    padding-top: 6.94vw;
  }

  .hero-info {
    display: flex;
    gap: 1.11vw;
  }

  .hero-title {
    font-size: 4.24vw;
  }


  .hero-statistic {
    display: flex;
    flex-wrap: wrap;
    gap: 0.89vw;
  }

  .hero-statistic-element {
    max-width: 13.68vw;
    width: 100%;
    margin-right: 0.89vw;
  }

  .hero-statistic-title {
    font-size: 4.24vw;
  }

  .hero-statistic-text {
    margin-top: 0.21vw;
    font-size: 0.97vw;
    line-height: 1.74vw;
  }

  .hero-registration {
    padding: 2.22vw;
    font-size: 1.67vw;
    line-height: 100%;
  }

  .hero-right {
    max-width: 27.85vw;
    width: 100%;
  }

  .hero-demonstration-text {
    bottom: 9.38vw;
    padding: 1.46vw 1.39vw;
    font-size: 1.53vw;
    font-weight: 700;
    line-height: 100%;
    left: -16.18vw;
  }

  .hero-demonstration-img {
    width: 30.97vw;
    height: 34.1vw;
  }

  .hero-right-title {
    margin-top: 0.42vw;
    font-size: 1.39vw;
    padding: 0 1.67vw 1.67vw 1.74vw;
    line-height: 100%;
  }

  .hero-free {
    font-size: 1.39vw;
  }

  .hero-description__who {
    font-size: 1.19vw;
  }

  .hero-description-element {
    padding: 1vw 1.19vw;
  }

  .hero-description-text {
    font-size: 1.27vw;
  }

  .hero-description-img {
    width: 2.78vw;
  }

  .tutorial-filling {
    padding: 1.11vw;
  }

  .tutorial-caption {
    display: flex;
    gap: 1.04vw;
  }

  .tutorial-caption-element {
    height: 14.86vw;
  }

  .tutorial-caption__title {
    font-size: 3.33vw;
  }

  .tutorial-caption-info {
    padding: 1.39vw 1.39vw 1.46vw 1.46vw;
    font-size: 1.53vw;
    line-height: 100%;
    top: -4.24vw;
    left: 2.36vw;
  }

  .tutorial-caption-img {
    width: 25.9vw;
  }

  .tutorial-step-date {
    font-size: 1.25vw;
  }


  .tutorial-step-subtitle {
    font-size: 1.67vw;
  }


  .chance-title {
    font-size: 2.78vw;
    line-height: 100%;
  }

  .chance-filling {
    margin-top: 2.29vw;
    gap: 1.11vw;
  }

  .chance-element {
    padding: 1.32vw 1.74vw;
    font-size: 1.39vw;
    line-height: 115%;
  }

  .chance-element span {
    font-size: 1.67vw;
    line-height: 100%;
  }

  .advantages-list {
    row-gap: 5.14vw;
    column-gap: 0;
  }

  .advantages-list-element {
    max-width: 22.22vw;
  }

  .advantages-list-title {
    font-size: 2.78vw;
    line-height: 100%;
  }

  .advantages-list-text {
    margin-top: 0.42vw;
    font-size: 1.53vw;
    line-height: 100%;
  }

  .advantages-robot {
    margin-top: 2.15vw;
    max-width: 27.99vw;
    width: 100%;
  }

  .advantages-robot-text {
    padding: 1.39vw;
    font-size: 1.53vw;
    line-height: 100%;
    left: 3.19vw;
    top: -1.94vw;
  }

  .advantages-robot-text::after {
    position: absolute;
    content: "";
    display: block;
    background: url("@/assets/imgs/landing/triangle-right.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1.93vw;
    height: 2.4vw;
    transform: rotate(90deg);
    left: 0.47vw;
    bottom: -1.81vw;
  }

  .advantages-robot-img {
    width: 33.54vw;
    bottom: 2.29vw;
    right: -0.28vw;
  }

  .reviews-title {
    font-size: 2.78vw;
    line-height: 100%;
  }

  .reviews-filling {
    margin-top: 2.99vw;
  }

  .reviews-list {
    gap: 1.11vw;
  }

  .reviews-element:nth-child(1) {
    margin-left: 5.97vw;
  }

  .reviews-caption {
    gap: 0.83vw;
  }

  .reviews-name {
    font-size: 1.39vw;
    line-height: 1;
  }

  .reviews-place {
    font-size: 0.97vw;
    line-height: 1.74vw;
  }

  .reviews-text {
    font-size: 2.22vw;
    line-height: 100%;
  }

  .action {
    padding-bottom: 0;
  }

  .action-title {
    font-size: 2.78vw;
    line-height: 1;
  }

  .action-list {
    margin-top: 4.17vw;
    row-gap: 2.22vw;
  }

  .action-fild {
    font-size: 1.53vw;
    line-height: 1;
    padding-bottom: 1.46vw;
  }

  .action-send {
    padding: 2.22vw;
    font-size: 1.67vw;
    line-height: 1;
  }

  .action-text {
    font-size: 0.83vw;
    line-height: 100%;
  }

  .questions-filling {
    gap: 1.11vw;
    padding: 1.11vw;
  }

  .questions-element__robot {
    max-width: 26.94vw;
    position: relative;
  }

  .questions-img {
    max-width: 100%;
    width: 100%;
  }

  .questions-text {
    padding: 1.39vw 1.39vw 1.53vw 1.39vw;
    font-size: 1.53vw;
    line-height: 1;
    width: 16.74vw;
    left: 25.21vw;
    top: 0.69vw;
  }

  .questions-element__tg {
    font-size: 2.22vw;
  }

  .ps {
    width: 5.9vw;
  }

  .dis {
    width: 6.46vw;
  }

  .za {
    width: 10.78vw;
  }


  .work {
    width: 9.93vw;
  }

  .footer-info-element {
    font-size: 0.83vw;
  }

}

@media (max-width: 850px) {

  html,
  body {
    max-width: 100%;
    width: 100%;
  }

  * {
    scroll-margin-top: 20vw;
  }

  .container {
    max-width: 100%;
    padding: 0 5.63vw;
    overflow-x: hidden;
  }

  .main {
    padding: 0;
  }

  .section {
    padding: 9.38vw 5.63vw 12.5vw 5.63vw;
  }

  .header-filling {
    padding: 5.63vw;
  }

  .logo-img {
    width: 15.31vw;
    height: 10.63vw;
  }

  .menu {
    position: fixed;
    right: 0;
    transform: translateX(100%);
    transition: all 1s ease-in-out;
    background: rgba(0, 0, 0, 0.57);
    height: 100vh;
    top: 0;
    max-width: 100%;
    width: 100%;
    display: flex;
    z-index: 5;
    flex-direction: column;
    overflow: auto;
  }

  .menu-filling {
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    height: calc(100% - 22.19vw);
    background: #000;
    padding: 13.75vw 0 18vw 0;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 6;
  }

  .menu-caption {
    height: 22.19vw;
    max-width: 100%;
    width: 100%;
  }

  .menu-open {
    transform: translateX(0);
  }

  .burger {
    margin-left: 5.63vw;
    width: 5vw;
    height: 4.38vw;
    background: url("@/assets/imgs/landing/burger.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .authorization {
    margin-left: auto;
  }

  .authorization-registration {
    padding: 3.13vw 3.44vw;
    border-radius: 1.56vw;
    font-size: 3.75vw;
  }

  .links {
    padding: 0 9.38vw;
    flex-direction: column;
    gap: 6.25vw;
    margin-left: 0;
  }

  .links-element {
    text-align: left;
    font-size: 5vw;
  }

  .authorization-enter-wrapper {
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    margin-top: auto;
    padding: 5.63vw 9.38vw;
    max-width: 100%;
    width: 100%;
  }

  .authorization-enter {
    max-width: 100%;
    width: 100%;
    padding: 5vw;
    border-radius: 3.13vw;
    background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
    color: #fff;
    font-size: 5.63vw;
    font-weight: 700;
    line-height: 100%;
    text-align: center;
  }

  .hero {
    padding-top: 6.25vw;
  }

  .hero-info {
    flex-direction: column;
  }

  .hero-title {
    font-size: 12.5vw;
    line-height: 115%;
  }

  .hero-text {
    margin-top: 5vw;
    font-size: 8.23vw;
  }

  .hero-text br {
    display: none;
  }

  .hero-statistic-element:last-child {
    margin-right: 0;
  }

  .hero-statistic-element {
    max-width: unset;
    width: unset;
    margin-right: 8.13vw;
  }

  .hero-statistic {
    margin-top: 12.5vw;
    display: flex;
  }

  .hero-statistic-title {
    font-size: 12.5vw;
    line-height: 115%;
  }

  .hero-statistic-text {
    margin-top: 2.5vw;
    font-size: 4.38vw;
    font-style: normal;
    font-weight: 700;
    line-height: 126.5%;
  }

  .hero-right {
    margin-top: 17.81vw;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 3.44vw 0 3.13vw 3.13vw;
    border-radius: 3.13vw;
  }

  .hero-demonstration-img {
    margin-top: 3.44vw;
    width: 43.75vw;
    height: 47.5vw;
    margin-left: auto;
    right: unset;
    margin-right: -7.5vw;
  }

  .hero-demonstration {
    display: flex;
  }

  .hero-right-title {
    margin-top: auto;
    padding: 0;
    font-size: 4.38vw;

    line-height: 112%;
  }

  .hero-right-title span {
    font-size: 4.38vw;
    font-weight: 500;
    line-height: 112%;
  }

  .hero-demonstration-text {
    padding: 5vw;
    border-radius: 2.5vw;
    font-size: 5vw;
    width: 48.75vw;
    left: -49.38vw;
    bottom: 37.81vw;
  }

  .hero-demonstration-text::after {
    width: 8.13vw;
    height: 5.63vw;
    background: url("@/assets/imgs/landing/triangle-right-mobile.svg");
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -5.63vw;
    right: 0.63vw;
  }

  .hero-registration {
    margin-top: 7.5vw;
    padding: 5vw;
    font-size: 5.63vw;
    border-radius: 3.13vw;
    line-height: 100%;
  }

  .hero-description {
    margin-top: 12.5vw;
    flex-direction: column;
  }

  .hero-description-element {
    padding: 6.25vw;
    border-radius: 3.13vw;
    font-size: 5.63vw;
    line-height: 115%;
    gap: 6.88vw;
  }

  .hero-description-text {
    font-size: 5.63vw;
    font-weight: 500;
    line-height: 115%;
  }

  .hero-description__school {
    margin-top: 5vw;
  }

  .hero-description__student {
    margin-top: 3.13vw;
  }

  .hero-description-img {
    width: 12.5vw;
  }

  .bubble {
    padding: 3vw 3vw 0 3vw;
    right: 38vw;
    bottom: 12vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 47vw;
    border-radius: 3vw;
  }

  .bubble__text {
    font-size: 6vw;
  }

  .bubble__filling {
    margin-top: 2.42vw;
    display: flex;
    align-items: flex-end;
    gap: 10.92vw;
  }

  .bubble__robot {
    width: 20vw;
  }

  .bubble__btn {
    width: 8.08vw;
    height: 8.08vw;
    margin-bottom: 3.15vw;
    position: relative;
    z-index: 4;
  }

  .bubble__btn svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .bubble__triangle {
    right: -6.66vw;
    width: 6.66vw;
    bottom: 5vw;
  }

  .tutorial-filling {
    padding: 3.13vw;
    border-radius: 3.13vw;
  }

  .tutorial-caption {
    flex-direction: column-reverse;
    gap: 3.13vw;
  }

  .tutorial-caption-element {
    align-items: flex-end;
    padding-right: 0.94vw;
    justify-content: flex-end;
    height: unset;
    min-height: 33.75vw;
    border-radius: 3.13vw;
  }

  .tutorial-caption-img {
    width: 43.13vw;
  }

  .tutorial-caption-info {
    left: 3.13vw;
    top: -6.25vw;
    padding: 5vw 5.31vw 5.31vw 5.31vw;
    font-size: 5vw;
    border-radius: 2.5vw;
  }

  .tutorial-caption-info::after {
    width: 8.13vw;
    height: 5.63vw;
    background: url("@/assets/imgs/landing/triangle-right-mobile.svg");
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -5.31vw;
    right: 0;
    transform: rotate(0);
    left: unset;
  }

  .tutorial-caption__title {
    padding: 7.81vw 2.81vw;
    font-size: 9.38vw;
  }

  .tutorial-steps {
    margin-top: 5vw;
    gap: 3.13vw;
    flex-direction: column;
  }

  .tutorial-step {
    border-radius: 3.13vw;
    padding: 6.25vw;
  }

  .tutorial-step-date {
    font-size: 4.38vw;
    line-height: 100%;
  }


  .tutorial-step-title {
    padding: 2vw 3vw;
    font-size: 6.25vw;
    line-height: 100%;
    border-radius: 2vw;
  }

  .tutorial-step-subtitle {
    margin-top: 5vw;
    font-size: 6.25vw;
    line-height: 100%;
  }

  .tutorial-step-text {
    margin-top: 3.75vw;
    font-size: 4.38vw;

    line-height: 7.81vw;
  }

  .chance-title {
    font-size: 7.5vw;
    line-height: 110%;
  }

  .chance-filling {
    gap: 3.13vw;
  }

  .chance-element {
    max-width: 100%;
    width: 100%;
    border-radius: 3.13vw;
    padding: 5.63vw 6.88vw;
    font-size: 5.63vw;
    line-height: 115%;
  }

  .chance-element span {
    font-size: 6.25vw;

    line-height: 100%;
  }

  .advantages-filling {
    flex-direction: column;
  }

  .advantages-list {
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: center;
    text-align: center;
  }

  .advantages-list-element:last-child {
    margin-bottom: 0;
  }

  .advantages-list-element {
    max-width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 9.38vw;
  }

  .advantages-list-title {
    font-size: 6.25vw;
    line-height: 100%;
  }

  .advantages-list-title__first {
    font-size: 7.5vw;

    line-height: 110%;
    padding-bottom: 1.56vw;
  }

  .advantages-list-text {
    margin-top: 3.13vw;
    max-width: 60vw;
    font-size: 4.38vw;
    line-height: 126.5%;
  }

  .advantages-robot {
    margin-top: 30.63vw;
    height: 33.75vw;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 3.13vw;
  }

  .advantages-robot-img {
    right: unset;
    bottom: unset;
    transform: rotate(0);
    width: 43.44vw;
    height: 33.75vw;
  }

  .advantages-robot-text {
    left: 3.13vw;
    top: -16.88vw;
    padding: 5vw;
    font-size: 5vw;
    border-radius: 2.5vw;
  }

  .advantages-robot-text::after {
    width: 8.13vw;
    height: 5.63vw;
    background: url("@/assets/imgs/landing/triangle-right-mobile.svg");
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -5.31vw;
    right: unset;
    transform: rotate(0);
    left: 1.56vw;
  }

  .reviews {
    padding: 9.38vw 0 12.5vw 0;
  }

  .reviews-title {
    max-width: 100%;
    padding: 0;
    font-size: 7.5vw;
    text-align: center;
    line-height: 110%;
  }

  .reviews-list {
    padding: 0 5.63vw;
    margin-top: 7.5vw;
    gap: 3.13vw;
    overflow-x: scroll;
    scrollbar-width: thin;
    height: unset;
    position: relative;
  }


  .reviews-element:nth-child(1) {
    margin-left: 0;
  }

  .reviews-element {
    width: 88.13vw;
    padding: 6.25vw;
    border-radius: 3.13vw;
    max-height: none;
  }

  .reviews__btn {
    margin-top: 2vw;
    font-size: 4vw;
  }

  .reviews-caption {
    justify-content: space-between;
    align-items: flex-start;
  }

  .reviews-name {
    max-width: 30vw;
    font-size: 5.63vw;

    line-height: 115%;
  }

  .reviews-star {
    width: 34.38vw;
  }

  .reviews-place {
    max-width: 100%;
    margin-top: 3.13vw;
    font-size: 4.38vw;
    line-height: 126.5%;
    min-height: 11vw;
  }

  .reviews-text {
    font-size: 5vw;
    line-height: 100%;
  }

  .action {
    padding-bottom: 0;
  }

  .action-filling {
    border-radius: 3.13vw;
    padding: 6.25vw 6.25vw 11.56vw 6.25vw;
  }

  .action-title {
    font-size: 5vw;
    line-height: 110%;
  }

  .action-list {
    margin-top: 10.31vw;
    display: flex;
    flex-direction: column;
    gap: 11.56vw;
  }

  .action-fild {
    padding-bottom: 3.13vw;
    font-size: 5vw;
    line-height: 126.5%;
  }

  .action-send {
    margin-top: 13.44vw;
    padding: 5vw;
    font-size: 5.63vw;
    line-height: 100%;
    border-radius: 3.13vw;
  }

  .action-text {
    margin-top: 5.94vw;
    font-size: 2.5vw;
    line-height: 100%;
  }

  .questions {
    padding-top: 17.57vw;
  }

  .questions-filling {
    border: none;
    background: none;
    gap: 3.75vw;
    flex-direction: column;
    padding: 0;
  }

  .questions-element__robot {
    max-width: 100%;
    border-radius: 3.13vw;
    background: #393c49;

    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 35vw;
  }

  .questions-img {
    width: 43.13vw;
  }

  .questions-text {
    left: 3.13vw;
    top: -13.13vw;
    padding: 5vw;
    font-size: 5vw;
    border-radius: 2.5vw;
    width: unset;
    color: #000;
  }

  .questions-text::after {
    width: 8.13vw;
    height: 5.63vw;
    background: url("@/assets/imgs/landing/triangle-right-mobile.svg");
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -5.31vw;
    right: unset;
    transform: rotate(0);
    left: 1.56vw;
  }

  .questions-element__tg {
    padding: 0;
    background: none;
    border-radius: unset;
  }

  .questions-link {
    padding: 5vw 2.5vw;
    font-size: 5.63vw;
    border-radius: 3.13vw;
    line-height: 115%;
  }

  .footer {
    padding-bottom: 20vw;
  }

  .footer-filling {
    flex-direction: column-reverse;
    display: flex;
  }

  .footer-info {
    border-top: none;
    margin: 0;
    padding-top: 0;
  }

  .footer-caption {
    border-top: 1px solid #7c808b;
    margin-top: 7.81vw;
    padding-top: 4.06vw;
    gap: 3.75vw;
  }

  .ps {
    width: 24.38vw;
    order: 3;
    height: 24.69vw;
  }

  .dis {
    width: 24.38vw;
    order: 5;
  }

  .za {
    width: 42.81vw;
    order: 1;
  }


  .work {
    width: 33.44vw;
    order: 4;
  }

  .footer-info {
    flex-direction: column;
    gap: 2.19vw;
    display: flex;
  }

  .footer-info-element {
    font-size: 3.13vw;
  }

  /* video */
  .video-filling {
    max-width: 100%;
  }

  .close {
    right: -0.62rem;
    top: -2.6rem;
  }

  iframe {
    height: 30vh;
    height: 30dvh;
  }

  .footer__links {
    margin-top: 1vw;
    gap: 1vw;
  }
}
</style>
