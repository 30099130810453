<template>
  <div class="course">
    <div class="caption">
      <p class="caption__title purple-text">Осторожно, карьера!</p>
      <img src="@/assets/imgs/robot-big.png" alt="" class="robot">
      <button class="close" @click="$emit('close')"></button>
    </div>
    <div class="filling">
      <p class="filling__title">Какой бы ни была ваша цель, вы сможете её достигнуть, если только захотите
        потрудиться</p>
      <p class="text">Лишь пятая часть россиян оценила уходящий 2023 год положительно в карьерном плане. Хочешь узнать,
        что же помешало остальным добиться успеха в карьере?
        <br> Авторский курс <span class="purple">Антона Тимкина</span> по построению карьеры <span
          class="purple">«Осторожно, карьера!»</span>
        поможет тебе в этом!
      </p>
      <div class="list">
        <p>— Как развить в себе навык целеполагания и как правильно ставить перед собой цели? </p>
        <p>— Для чего нужен список эмоций?</p>
        <p>— Как работает навык эффективных переговоров?</p>
        <p>— А в чём уникальность навыка проектного мышления?</p>
        <div class="list-bg"></div>
      </div>
      <div class="list-after">
        <p class="text">
          Всё это, и даже больше, ты узнаешь от Антона, одного из самых эффективных топ-менеджеров столицы.
        </p>
        <p class="text">
          <span>Смотри онлайн-курс – начни строить успешную карьеру уже сейчас!</span>
        </p>
      </div>
      <p class="time">Антон Тимкин «Осторожно, карьера!» </p>
    </div>
    <form class="form" @submit.prevent>
      <p class="text">
        <span>Заполни форму ниже, чтобы получить на свою электронную почту индивидуальную ссылку на курс</span>
      </p>
      <div class="fields">
        <input type="text" class="input" placeholder="Ваше имя *" v-model="name">
        <i-mask-component type="tel" :mask="mask" placeholder="Телефон *" class="input" v-model="maskedPhone"
          v-model:unmasked="phone"></i-mask-component>
        <input type="email" v-model.trim="email" class="input" placeholder="E-mail *">
      </div>
      <div class="check-wrapper data">
        <label class="check">
          <input type="checkbox" v-model="userAgreementPrivacy" class="check__input">
          <div class="check__box"></div>
        </label>
        <p class="check-text">Я принимаю условия <a href="/docs/polzovatelskoe_soglashenie.pdf" target="_blank">
            пользовательского соглашения</a> и даю своё <a href="/docs/soglashenie_na_obrabotky_personalnih_dannih.pdf"
            target="_blank" class="footer__text footer__links-element">согласие на обработку
            персональных данных</a> на условиях, определённых <a href="/docs/politica_obrabotki_personalnih_dannih.pdf"
            target="_blank" class="footer__text footer__links-element">Политикой обработки персональных данных.</a>
        </p>
      </div>
      <button type="button" class="btn" @click="signUpForTheCourses">Получить</button>
    </form>
  </div>
</template>

<script>
import { IMaskComponent } from "vue-imask";
import { ref } from "vue";
import { errorHandler } from "@/lib/errorHandler";
import { appConfig } from "@/lib/appConfig";

export default {
  name: "CourseModal",
  computed: {
    appConfig() {
      return appConfig
    }
  },
  components: { IMaskComponent },

  setup(_, { emit }) {
    const maskedPhone = ref('');

    const name = ref('');
    const email = ref('');
    const phone = ref('');

    const userAgreementPrivacy = ref(false);

    const signUpForTheCourses = () => {

      if (!name.value?.trim()) {
        return errorHandler({ name: 'InvalidField', field: 'name' });
      }

      if (phone.value?.length < 11) {
        return errorHandler({ name: 'InvalidField', field: 'phone' });
      }

      if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/?.test(email.value)) {
        return errorHandler({ name: 'InvalidField', field: 'email' });
      }

      if (!userAgreementPrivacy.value) {
        return errorHandler({ name: 'userAgreementPrivacy' });
      }

      emit('save', { target: 'courses', name: name.value, email: email.value, phone: phone.value });
    }


    return {
      mask: "+{7}(000)000-00-00",
      maskedPhone,
      signUpForTheCourses,
      name,
      phone,
      email,
      userAgreementPrivacy
    }
  }
}
</script>

<style scoped>
.course {
  padding: 2.5rem 2rem 2.5rem 2rem;
  border-radius: 2rem;
  background: #FFF;
  max-width: 50rem;
  width: 100%;
  color: #131313;
  font-family: "PTRootUI", sans-serif;
  margin: auto;

}

.caption {
  display: flex;
  align-items: flex-start;
}

.caption__title {
  margin: auto 0 11px 0;
  font-size: 4.25rem;
  line-height: 1.3;
}

.purple-text {
  max-width: 26.25rem;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

.robot {
  width: 13.1875rem;
  margin-left: 2rem;
}

.close {
  width: 4rem;
  height: 4rem;
  background-image: url("@/assets/imgs/close-white.svg");
  background-color: #F4F4F4;
  background-size: contain;
  border-radius: 100%;
  display: flex;
  flex-shrink: 0;
  background-repeat: no-repeat;
}

.filling {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.filling__title {
  color: #000;
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 120%;
}

.text {
  color: #131313;
  font-size: 1.5rem;
  line-height: 130%;
}

.text span {
  font-weight: 700;
}

.text .purple {
  font-weight: 700;
  background-color: linear-gradient(151deg, #AD4FEA 0%, #4A58DD 100%);
  background-image: linear-gradient(151deg, #AD4FEA 0%, #4A58DD 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.list {
  padding: 26px 42px 34px 42px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 24px;
  position: relative;
}

.list-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(150.98deg, #ad4fea 0%, #4a58dd 119.7%);
  opacity: 0.1;
  border-radius: 24px;
}

.list p {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
  background-color: linear-gradient(151deg, #AD4FEA 0%, #4A58DD 100%);
  background-image: linear-gradient(151deg, #AD4FEA 0%, #4A58DD 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 1;
}

.list-after {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.time {
  margin-top: 0.5rem;
  padding: 1.19rem;
  max-width: 100%;
  width: 100%;
  color: #FFF;
  font-size: 2rem;
  font-weight: 700;
  line-height: 130%;
  border-radius: 1rem;
  background: #6d7afb;
  text-align: center;
}

.form {
  margin-top: 1.5rem;
}

.fields {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input {
  border-radius: 1rem;
  border: 2px solid #AD4FEA;
  background: #FFF;
  padding: 1.5rem;
  color: rgba(0, 0, 0, 0.50);
  font-size: 1.5rem;
  line-height: normal;
  color: #000;
}

.input::placeholder {
  color: rgba(0, 0, 0, 0.50);
}

.btn {
  max-width: 100%;
  width: 100%;
  text-align: center;
  padding: 2.1rem;
  margin-top: 1.5rem;
  border-radius: 1rem;
  background: linear-gradient(92deg, #AD4FEA 4.41%, #4A58DD 72.27%);
  color: #FFF;
  font-size: 2rem;
  line-height: 100%;
}

.check-wrapper {
  margin-top: 3rem;
  display: flex;
  gap: 1rem;
  max-width: 69.25rem;
  color: rgba(19, 19, 19, 0.8);
  font-weight: 400;
  font-size: 24px;
  align-items: flex-start;
}


.check {
  display: flex;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.check:hover {
  opacity: 0.8;
}

.check__input {
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
}

.check__box {
  width: 100%;
  height: 100%;
  background: center / cover no-repeat url("@/assets/imgs/check__box-white.svg");
}

.check__input:checked~.check__box {
  background: center / cover no-repeat url("@/assets/imgs/check__box-white-on.svg");
}

.check-text {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}

.check-text a {
  color: #003099;
  text-decoration: underline;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: none !important;
  transition: background-color 5000s ease-in-out 0s !important;
  background-color: none !important;
  font-size: 1.5rem;
  color: #000 !important;
}

@media (max-width: 850px) {
  .course {
    padding: 1.5rem;
    border-radius: 1rem;
    max-width: 27.3rem;
    position: relative;
  }


  .caption__title {
    margin-top: 1rem;
    font-size: 2rem;
    max-width: 14rem;
    margin-right: 0.5rem;
  }

  .robot {
    max-width: 8rem;
    width: 100%;
    margin-left: auto;
  }

  .close {
    top: 0.75rem;
    right: 0.75rem;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
  }

  .filling {
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .filling__title {
    font-size: 1.5rem;
  }

  .text {
    font-size: 0.875rem;
  }

  .time {
    margin-top: 1rem;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 0.5rem;
  }

  .form {
    margin-top: 1rem;
  }

  .fields {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .input {
    border-radius: 0.5rem;
    border: 1px solid #AD4FEA;
    padding: 0.75rem;
    font-size: 0.875rem;
  }

  .btn {
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 0.5rem;
  }

  .list {
    padding: 1rem;
    border-radius: 0.75rem;
  }

  .list-bg {
    border-radius: 0.75rem;
  }

  .list p {
    font-size: 0.875rem;
  }

  .check-wrapper {
    margin-top: 1rem;
    gap: 0.5rem;
  }

  .check {
    width: 1.5rem;
    height: 1.5rem;
  }

  .check-text {
    font-size: 1rem;
    line-height: normal;
  }
}

@media (max-width: 380px) {
  .caption__title {
    margin-top: 0.5rem;
    font-size: 1.5rem;
  }

  .robot {
    max-width: 5rem;
  }

}
</style>