<template>
  <div class="chat">
    <div class="chat-el" v-if="currentMission">
      <img
        src="@/assets/imgs/robot-face.png"
        alt=""
        class="chat__logo"
        v-if="!missionStart && !missionFinish"
      />
      <mission-message
        @finishMission="missionFinish = true"
        v-if="!missionStart && !missionFinish"
        :questions="currentMission?.questions"
        :mission-passing="missionPassing"
      ></mission-message>
      <mission-greatings
        :mission-id="Number(missionId)"
        @startMission="missionStart = false"
        v-if="missionStart"
      ></mission-greatings>
      <mission-finish
        :mission-id="Number(missionId)"
        v-if="missionFinish"
      ></mission-finish>
    </div>
  </div>
</template>

<script>
import MissionMessage from "@/components/missions/MissionMessage.vue";
import MissionFinish from "@/components/missions/MissionFinish.vue";
import MissionGreatings from "@/components/missions/MissionGreatings.vue";
import { computed, inject, ref } from "vue";

export default {
  name: "MissionPassagePage",
  components: {
    MissionMessage,
    MissionGreatings,
    MissionFinish,
  },
  props: {
    missionId: String,
  },
  setup(props) {
    const store = inject("store");

    const missionStart = ref(true);
    const missionFinish = ref(false);

    store.commit("initMissionPassing", {
      id: Number(props.missionId),
      missions: store.getters.userData.missions,
    });

    if (props.missionId === "4" || props.missionId === "5") {
      if (props.missionId === "4") {
        missionStart.value = false;
      }
      store.dispatch("fetchMissionsAnswers", Number(props.missionId));
    }

    const currentMission = computed(() => {
      const index = store.getters.missions
        .slice()
        .map((item) => item.missionId)
        .indexOf(Number(props.missionId));
      return index !== -1 ? store.getters.missions[index] : null;
    });

    const missionPassing = computed(() => store.getters.missionPassing);
    return {
      currentMission,
      missionPassing,
      missionStart,
      missionFinish,
    };
  },
};
</script>

<style scoped>
.chat__logo {
  margin: 1.69rem 0 1.69rem 0;
  width: 4.09725rem;
  height: 2.8125rem;
}

.chat {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-el {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  height: 100%;
  align-items: center;
  min-height: calc(100vh - 100px);
}

@media (max-width: 1600px) {
  .chat-el {
    min-height: calc(100vh - 75px);
  }
}
@media (max-width: 850px) {
  .chat__logo {
    width: 5.75rem;
    height: 3.9375rem;
    object-fit: contain;
    margin: 0 0 0.5rem 0;
  }
  .chat-el {
    min-height: unset;
    align-items: flex-start;
  }
}
</style>
