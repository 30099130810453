<template>
  <div class="profle-data">
    <div class="profle-caption">
      <button class="back" @click="closeWindow"></button>
      <h2 class="profle__title">Личные данные</h2>
    </div>
    <div class="profile-info">
      <!--      <button class="porofile-image" @click="fileInput.click()">-->
      <!--        <input-->
      <!--          type="file"-->
      <!--          hidden-->
      <!--          ref="fileInput"-->
      <!--          accept="image/*"-->
      <!--          @change="uploadFile"-->
      <!--        />-->
      <!--        <img-->
      <!--          src="@/assets/imgs/photo_camera.svg"-->
      <!--          alt="Выбор фотографии"-->
      <!--          class="porofile-image__mask"-->
      <!--        />-->
      <!--        <img-->
      <!--          :src="userData.avatarUrl"-->
      <!--          alt="Фотография"-->
      <!--          class="porofile-image__img"-->
      <!--        />-->
      <!--      </button>-->
      <div class="porofile-info-filling">
        <p class="porofile__name">
          {{ userData?.firstName }} {{ userData?.secondName }}
        </p>
        <p class="porofile__login purple-text" v-if="false">@Vrt12345</p>
      </div>
    </div>
    <div class="data-list" v-if="userData">
      <div class="profile-field">
        <p class="profile-field__text">Имя</p>
        <input type="text" v-model.trim="userData.firstName" placeholder="Имя" class="profile-field__input" />
      </div>
      <div class="profile-field">
        <p class="profile-field__text">Фамилия</p>
        <input type="text" v-model.trim="userData.secondName" placeholder="Фамилия" class="profile-field__input" />
      </div>
      <!--      <div class="profile-field">-->
      <!--        <p class="profile-field__text">Пол</p>-->
      <!--        <div class="pop-up profile-pop-up" v-if="viewSex">-->
      <!--          <button class="select-btn profile__select-btn">-->
      <!--            Мужской-->
      <!--            <div class="arrow arrow-active"></div>-->
      <!--          </button>-->
      <!--          <div class="select-list profile__select-list" v-if="false">-->
      <!--            <button-->
      <!--                class="select-element profile__select-element select-element__active"-->
      <!--            >-->
      <!--              Мужской-->
      <!--            </button>-->
      <!--            <button class="select-element profile__select-element">-->
      <!--              Женский-->
      <!--            </button>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="profile-field">
        <p class="profile-field__text">Дата рождения</p>
        <input type="date" placeholder="12.05.1994" class="profile-field__input" v-model="userData.birthDate" />
      </div>
    </div>
    <button class="save button-purple" @click="saveUserData">Сохранить</button>
  </div>
</template>

<script>
import { computed, inject, ref, watch } from "vue";
import dayjs from "dayjs";

export default {
  name: "ProfileData",
  setup(props, { emit }) {
    const store = inject("store");
    const user = computed(() => store.getters.userData);
    const userData = ref(JSON.parse(JSON.stringify(user.value)));
    watch(
      () => user.value,
      (val) => {
        userData.value = JSON.parse(JSON.stringify(val));
      }
    );

    const fileInput = ref(null);
    let file = null;

    const uploadFile = (event) => {
      file = event.target.files[event.target.files.length - 1];
      userData.value.avatarUrl = URL.createObjectURL(file);
    };

    const saveUserData = async () => {
      if (
        userData.value.firstName === user.value.firstName &&
        userData.value.secondName === user.value.secondName &&
        (userData.value.avatarUrl === user.value.avatarUrl) &
        (userData.value.birthDate === user.value.birthDate)
      )
        return;
      if (file) {
        await store.dispatch("uploadFile", file).then((url) => {
          userData.value.avatarUrl = url;
        });
      }
      userData.value.birthDate = userData.value.birthDate
        ? new Date(dayjs(userData.value.birthDate).format())
        : null;

      await store.dispatch("saveUserData", { data: userData.value });
    };
    return {
      fileInput,
      userData,
      closeWindow: () => emit("closeWindow"),
      uploadFile,
      saveUserData,
    };
  },
};
</script>

<style scoped>
.profle-caption {
  display: flex;
  gap: 0.88rem;
}

.back {
  width: 1.75rem;
  height: 1.75rem;
  background: url("@/assets/imgs/back.svg");
  background-size: cover;
}

.profle__title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.profile-info {
  margin-top: 1.75rem;
  display: flex;
  gap: 0.87rem;
  align-items: center;
}

.porofile-image {
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.188rem solid #fff;
  border-radius: 100%;
}

.porofile-image__mask {
  position: absolute;
}

.porofile-image__img {
  border-radius: 100%;
  max-width: 100%;
  width: 100%;
  z-index: 2;
  object-fit: cover;
  object-position: center;
  height: 100%;
}

.porofile__name {
  font-size: 0.875rem;
  line-height: 1.75rem;
  word-break: break-word;
}

.porofile__login {
  margin-top: 0.19rem;
  font-size: 0.75rem;
  line-height: normal;
}

.data-list {
  margin-top: 1.75rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.save {
  margin-top: 3.2rem;
  padding: 1rem 2.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: normal;
  border-radius: 0.625rem;
}

.arrow {
  width: 1.25rem;
  height: 1.25rem;
  background: url("@/assets/imgs/arrow-bottom-grey.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.arrow-active {
  transform: rotate(180deg);
}

@media (max-width: 850px) {
  .data-list {
    display: flex;
    flex-direction: column;
  }

  .save {
    margin-top: 2.3rem;
    padding: 0.75rem;
    max-width: 100%;
    font-size: 1rem;
    width: 100%;
    text-align: center;
  }
}
</style>
