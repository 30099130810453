<template>
  <div class="filling-7">
    <img src="@/assets/imgs/video/bg-2-2.png" alt="" class="bg-7" />
    <div class="content-7">
      <p class="title-7">
        Компании для твоего будущего трудоустройства, <br />
        когда получишь диплом ;)
      </p>
      <div class="description-7">
        <div class="company-7">
          <div class="company-element-7">
            <p class="company-text-7">Lucoil</p>
            <img
              src="@/assets/imgs/video/company-2.svg"
              alt=""
              class="company-img-7 company-img__1-7"
            />
          </div>
          <div class="company-element-7">
            <p class="company-text-7">Lucoil</p>
            <img
              src="@/assets/imgs/video/company-2.svg"
              alt=""
              class="company-img-7 company-img__1-7"
            />
          </div>
          <div class="company-element-7">
            <p class="company-text-7">Lucoil</p>
            <img
              src="@/assets/imgs/video/company-2.svg"
              alt=""
              class="company-img-7 company-img__1-7"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PresentationElement7",
};
</script>

<style scoped>
.filling-7 {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  height: 100vh;
  height: 100dvh;
}

.description-7 {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.bg-7 {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.content-7 {
  max-width: 100%;
  width: 100%;
  padding: 100px;
  position: relative;
  height: 100%;
}

.title-7 {
  text-align: center;
  font-size: 55px;
  font-weight: 700;
  line-height: 143.5%;
  letter-spacing: 3.3px;
  text-transform: uppercase;
  text-align: left;
}

.company-7 {
  position: relative;
  z-index: 2;
  display: flex;
  gap: 100px;
  justify-content: center;
}

.company-img-7 {
  width: 100%;
  height: 186px;
}

.company-element-7 {
  padding: 40px 0 0 0;
  width: 372px;
  height: 531px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  backdrop-filter: blur(50px);
  position: relative;
  overflow: hidden;
}

.company-text-7 {
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 106.99%;
}

.company-img-7 {
  height: auto;
  max-height: 60%;
  width: 100%;
  margin-top: auto;
}
</style>
