<template>
  <div class="account-information " id="menu" :class="{ 'train-selected': educationStep === 8 }">
    <div class="profile">
      <div class="clue">
        <h1 class="page-name ">Мой аккаунт</h1>
        <div class="clue__content" v-if="!routerBlocked">
          <p class="clue__title">Блок Мой аккаунт</p>
          <p class="clue__text">Вперёд – тратить баллы! Смотри, что у тебя на балансе, и трать на что хочешь. А если
            захочешь пообщаться о бесплатном обучении – просто нажми на «Цифровой помощник». И не забудь загрузить свою
            фотку.</p>
        </div>
      </div>
      <div class="profile__img-wrapper clue">
        <img @click="changePhoto" :src="userData?.avatarUrl" alt="Личное фото" class="profile__img" />
        <div class="clue__content desktop" v-if="!viewChangePhoto">
          <p class="clue__title">Нажми чтобы изменить фотографию</p>
        </div>
      </div>
      <div class="change-photo" v-if="viewChangePhoto">
        <button class="close" @click="changePhoto">
          <img src="@/assets/imgs/close.svg" alt="" class="close__img">
        </button>
        <button @click="inputFile?.click()" class="photo-btn">
          <img src="@/assets/imgs/change.svg" alt="" class="photo-img" />
          Обновить фотографию
        </button>
        <input ref="inputFile" accept="image/*" type="file" hidden="hidden" @change="uploadPhoto" />
        <button class="photo-btn photo-btn__second" @click="deletePhoto">
          <img src="@/assets/imgs/delete.svg" alt="" class="photo-img" />
          Удалить
        </button>
      </div>
      <p class="profile__name">
        {{ `${userData?.firstName} ${userData?.secondName}` }}
      </p>
      <div class="profile-description">
        <button :disabled="routerBlocked" @click="setPaymentByScoreModal" class="score">
          <div class="coin-wrapper">
            <p class="coin__number">{{ userData?.sideData?.scoresPaymentTarget ? 0 : userData?.monets }}</p>
          </div>
          <p class="score__text">Баллов </p>
        </button>
        <router-link :to="{ name: 'profile' }" class="read-more">
          <img src="@/assets/imgs/info.svg" alt="информация" class="read-more__img" />
          <p class="read-more__text purple-text">Подробнее</p>
        </router-link>
      </div>
    </div>
    <button class="log-out" @click="logout">Выйти</button>
    <button class="delete" v-if="false">Удалить аккаунт</button>
    <nav class="navigation desktop">
      <div class="clue">
        <router-link :to="{ name: 'statistics' }" class="navigation-link " :class="{
          'navigation-link-active':
            ($route.name === 'statistics' || $route.name === 'missions') && !$route.hash && !viewPaymentByScoreModal,
        }">
          <img src="@/assets/imgs/person.svg" alt="" class="navigation__img" />
          Аккаунт
        </router-link>

      </div>
      <router-link :to="{ name: 'profile' }" class="navigation-link" :class="{
        'navigation-link-active':
          $route.name === 'profile' && !viewPaymentByScoreModal,
      }">
        <img src="@/assets/imgs/profile-icon.svg" alt="" class="navigation__img" />
        Настройки
      </router-link>
      <!--      <router-link to="/statistics#test" class="navigation-link" v-if="$route.name === 'statistics'">-->
      <!--        <img src="@/assets/imgs/calendar.svg" alt="" class="navigation__img">-->
      <!--        Календарь-->
      <!--      </router-link>-->
      <router-link :to="{ name: 'statistics', hash: '#calendar' }" class="navigation-link" :class="{
        blocked: routerBlocked,
        'navigation-link-active':
          $route.name === 'statistics' &&
          $route.hash === '#calendar' &&
          !viewPaymentByScoreModal,
      }">
        <img src="@/assets/imgs/calendar.svg" alt="" class="navigation__img" />
        Календарь
      </router-link>
      <button @click="setPaymentByScoreModal" class="navigation-link" :class="{
        blocked: routerBlocked,
        'navigation-link-active': viewPaymentByScoreModal,
      }">
        <img src="@/assets/imgs/money.svg" alt="" class="navigation__img" />
        Баланс
      </button>
      <router-link :to="{ name: 'assistant' }" class="navigation-link" :class="{
        blocked: routerBlocked,
        'navigation-link-active':
          $route.name === 'assistant' && !viewPaymentByScoreModal,
      }">
        <img src="@/assets/imgs/chat.svg" alt="" class="navigation__img" />
        Чат с помощником
      </router-link>
    </nav>
    <!--при прохождении всех подсказок добавляй класс -->
    <button v-if="!routerBlocked" class="help" :class="{ 'help--end': userData?.sideData?.educationPassed }"
      @click="startEducation">
      <img src="@/assets/imgs/help.svg" alt="" class="help__img">
      <span class="help__text">Навигация</span>
    </button>

  </div>
</template>

<script>
import { computed, inject, ref } from "vue";
import { allMissionPassed } from "@/router/helpers";
import 'vue-advanced-cropper/dist/style.css';
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "PageMenu",
  props: ['educationStep'],

  setup() {
    const store = inject("store") || useStore();
    const route = useRoute();
    const router = useRouter();
    const userData = computed(() => store.getters.userData);
    const inputFile = ref(null);
    const viewChangePhoto = ref(false);


    const deletePhoto = () => {
      const newUserData = JSON.parse(JSON.stringify(userData.value));
      newUserData.avatarUrl = " ";
      store
        .dispatch("saveUserData", { data: newUserData })
        .then(() => store.dispatch("fetchUserData"));
    };

    const uploadPhoto = (event) => {
      const file = event.target.files[event.target.files.length - 1];
      store.commit('setAvatarFile', file);
    };

    return {
      userData,
      logout: () => store.dispatch("logout"),
      setPaymentByScoreModal: () => store.commit("setPaymentByScoreModal", true),
      viewPaymentByScoreModal: computed(() => store.getters.viewPaymentByScoreModal),
      routerBlocked: computed(() => !allMissionPassed()),
      deletePhoto,
      uploadPhoto,
      inputFile,
      viewChangePhoto,
      changePhoto() {
        if (route.name !== 'profile') {
          router.push({ name: 'profile' }).then(() => viewChangePhoto.value = true);
        } else {
          viewChangePhoto.value = !viewChangePhoto.value;
        }
      },

      startEducation: () => {
        if (route.name !== '') {
          router.push({ name: 'statistics' }).then(() => store.commit('setEducationStep', 0))
          return;
        }
        store.commit('setEducationStep', 0);
      }
    };
  },
};
</script>

<style scoped>
.account-information {
  padding: 7.13rem 1.69rem 3.75rem 0;
  display: flex;
  flex-direction: column;

  height: calc(100vh - 99px);
  overflow-y: auto;
  max-width: 23.19rem;
  width: 100%;

  scrollbar-width: thin;
  scrollbar-color: #2e2f32 #222326;
  text-align: center;
  position: sticky;
  top: 99px;
  scroll-margin-top: 150px;
  overflow-x: hidden;
}

.account-information::-webkit-scrollbar-track {
  background-color: #222326;
  border-radius: 50px;
}

.account-information::-webkit-scrollbar-thumb {
  background-color: #2e2f32;
  border-radius: 50px;
}

.close {
  position: absolute;
  right: 0;
  top: -3rem;
  padding: 0;
  cursor: pointer;
}

.profile__img {
  margin-top: 2rem;
  cursor: pointer;
  border-radius: 100%;
  max-width: 14.125rem;
  height: 14.125rem;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.profile__img-wrapper .clue__title {
  text-align: center;
}

.profile__img-wrapper .clue__content::after {
  text-align: center;
  left: calc(50% - 1.3125rem);
}

.profile__name {
  margin-top: 1.25rem;
  font-weight: 500;
  font-size: 2.25rem;
  line-height: 1;
  word-wrap: break-word;
}

.profile-description {
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

.score {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.score__text {
  font-weight: 500;
  font-size: 1.1875rem;
  line-height: 1.875rem;
}

.read-more {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  font-size: 1.1875rem;
  line-height: 1.875rem;
}

.read-more__img {
  width: 1.3125rem;
  height: 1.3125rem;
}

.log-out {
  border-radius: 0.625rem;
  background: rgba(255, 255, 255, 0.1);
  font-size: 1.25rem;
  max-width: 100%;
  width: 100%;
  margin-top: 1.22rem;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  padding: 1rem 0rem;
}

.delete {
  border-radius: 0.625rem;
  color: rgba(255, 114, 114, 0.7);
  padding: 1rem 0rem;
  max-width: 100%;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.navigation {
  margin-top: 2.06rem;
}

.navigation-link {
  display: flex;
  padding: 1rem 0 1rem 1.6875rem;
  align-items: center;
  color: #fff;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: -0.015rem;
  scroll-behavior: smooth;
  max-width: 100%;
  width: 100%;
}

.help {
  margin-top: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.75rem;
  transition: all 0.5s ease;
  background: linear-gradient(128.54deg, #ad4fea 14.88%, #4a58dd 98.81%);
}

.help:hover {
  background: #21262e;
  opacity: 1;
}

.help--end {
  background: #21262e;
}

.help--end:hover {
  background: linear-gradient(128.54deg, #ad4fea 14.88%, #4a58dd 98.81%);
}

.help__text {
  font-weight: 500;
  font-size: 1.375rem;
  color: #ffffff;
}


.navigation-link-active {
  background: linear-gradient(90deg, #ad4fea -90%, rgb(31, 36, 43) 100%),
    rgba(173, 79, 234, 0.58);
}

.navigation__img {
  width: 2.75rem;
  height: 2.75rem;
}

.change-photo {
  position: relative;
  margin-top: 0.5rem;
  max-width: 100%;
  width: 100%;
  box-shadow: -1px 2px 6px 0px rgba(255, 255, 255, 0.16);
  margin-bottom: 0.5rem;
  border-radius: 0.94rem;
}

.photo-btn {
  padding: 1rem 0 1rem 1.69rem;
  background: #4f5156;
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  font-size: 1.375rem;
  line-height: 1.25rem;
  color: #fff;
}

.photo-btn {
  border-radius: 0.94rem 0.94rem 0 0;
}

.photo-btn__second {
  border-top: 1px solid #656565;
  border-radius: 0 0 0.94rem 0.94rem;
}

.photo-img {
  width: 2.75rem;
  height: 2.75rem;
  flex-shrink: 0;
  display: flex;
}

.clue__content {
  left: 0;
}

.coin-wrapper {
  width: 3rem;
  height: 3rem;
}

.coin__number {
  width: 2.5rem;
  height: 2.5rem;
  border: 2px solid #585a5e;
  font-size: 1rem;
}

.clue:hover {
  opacity: 1;
}

.clue:hover .clue__content {
  opacity: 1;
  z-index: 2;
}

@media (max-width: 1600px) {
  .account-information {
    padding-top: 3.13rem;
    top: 74.25px;
    max-width: 20.19rem;
    padding-right: 0;
    height: calc(100vh - 73px);
  }
}

@media (max-width: 850px) {
  .account-information {
    padding: 0;
    height: unset;
    max-width: 18rem;
    width: 100%;
    text-align: center;
    position: relative;
    top: unset;
    margin: 0 auto;
  }

  .page-name {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: normal;
  }

  .change-photo {
    max-width: 220px;
    margin: 0.37rem auto;
    border-radius: 0.625rem;
    box-shadow: -1px 2px 6px 0px rgba(255, 255, 255, 0.16);
  }

  .photo-btn {
    padding: 0.75rem 0 0.75rem 0.94rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 0.625rem 0.625rem 0 0;
  }

  .photo-btn__second {
    border-radius: 0 0 0.625rem 0.625rem;
  }

  .photo-img {
    width: 2rem;
    height: 2rem;
  }

  .profile__img {

    border-radius: 100%;
    max-width: 6rem;
    height: 6rem;
    width: 100%;
  }

  .profile__name {
    margin-top: 0.5rem;
    font-size: 1.5rem;
    line-height: 1.16;
  }

  .profile-description {
    margin-top: 0.5rem;
    min-height: 1.875rem;
  }

  .score__text {
    font-size: 0.875rem;
    line-height: normal;
  }

  .read-more {
    gap: 0.5rem;
    font-size: 0.875rem;
  }

  .read-more__img {
    width: 1rem;
    height: 1rem;
  }

  .log-out {
    margin-top: 1.22rem;
    font-size: 1rem;
  }

  .delete {
    border-radius: 0.625rem;
    color: rgba(255, 114, 114, 0.7);
    padding: 1rem 0rem;
    max-width: 100%;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }

  .navigation {
    margin-top: 2.06rem;
  }

  .navigation-link {
    display: flex;
    padding: 1rem 1.6875rem;
    align-items: center;
    color: #fff;
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: -0.015rem;
  }

  .navigation-link-active {
    background: linear-gradient(90deg, #ad4fea -90%, rgb(31, 36, 43) 100%),
      rgba(173, 79, 234, 0.58);
  }

  .navigation__img {
    width: 2.75rem;
    height: 2.75rem;
  }

  .coin-wrapper {
    width: 2rem;
    height: 2rem;
  }

  .coin__number {
    width: 1.7rem;
    height: 1.7rem;
    font-size: 0.7rem;
  }

  .profile__img-wrapper .clue__content {
    left: calc(50% - 8rem);
  }

  .help {
    margin-top: 1.5rem;
    gap: 0.5rem;
    padding: 0.75rem;
    border-radius: 0.5rem;
  }

  .help__text {
    font-size: 1rem;
  }

  .help__img {
    width: 1.5rem;
  }
}

.blocked {
  opacity: 0.3;
  pointer-events: none;
}
</style>
