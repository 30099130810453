<template>
  <div class="filling" :class="{ 'step': step === 17 }">
    <img src="@/assets/imgs/video/bg-18.webp" alt="" class="bg" />
    <div class="content">
      <div class="image animate-1" :class="{ 'animate-2': viewAnimate }">
        <div class="image-bg"></div>
        <img src="@/assets/imgs/video/decoration-18-2.webp" alt="" class="image-filling" />
      </div>
      <div class="description">
        <p class="text">
          В любую профессию ты всегда <br />
          можешь привнести свою
        </p>
        <p class="text text__big">Индивидуальность</p>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
  name: "VideoElement18",
  props: ["step"],
  setup() {
    const viewAnimate = ref(false);

    onMounted(() => setTimeout(() => (viewAnimate.value = true), 1000));
    return {
      viewAnimate,
    };
  },
};
</script>

<style scoped>
.filling {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.bg {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.content {
  display: flex;
  align-items: center;
  padding-left: 6vw;
}

.image {
  width: 33.59vw;
  height: 43.59vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 1s ease-in-out;
}

.image-bg {
  width: 29.59vw;
  height: 39.59vw;
  position: absolute;
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  transition: all 1s ease-in-out;
}

.image-filling {
  width: 29.59vw;
  height: 39.59vw;
  position: absolute;
  transition: all 1s ease-in-out;
}

.description {
  position: relative;
  margin-left: 7vw;
}

.text {
  font-size: 2.5vw;
  font-weight: 400;
  font-weight: 400;
  line-height: normal;

  text-transform: uppercase;
}

.animate-1 {
  animation: content-open 1s both;
  will-change: transform;
}

@keyframes content-open {
  from {
    transform: translate(-6vw, -7vw);
  }

  to {
    transform: translate(0, 0);
  }
}

.animate-2 .image-bg {
  transform: translate(-2vw, -2vw);
  will-change: transform;
}

.animate-2 .image-filling {
  transform: translate(2vw, 2vw);
  will-change: transform;
}

.text__big {
  margin-top: 0.83vw;
  font-size: 3.33vw;
  font-weight: 700;
}
</style>
