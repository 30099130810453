<template>
  <tr class="table-row">
    <td class="table-row__element"> Татарстан</td>
    <td class="table-row__element"> Lorem ipsum dolor sit amet.</td>
    <td class="table-row__element"> Lorem ipsum dolor sit amet.</td>
  </tr>
</template>

<script>
export default {
  name: "RegionTableElement",
  props: ['region']
}
</script>

<style scoped>
.table-row {
  background: #131313;
}

td {
  padding: 1rem;
}
</style>