<template>
  <div class="robot-message">
    <div class="greetings show-left" v-if="step === 0">
      <img src="@/assets/imgs/robot-big.png" alt="" class="greetings__img">
      <p class="greetings__text"> Привет! 😉Меня зовут Цип. <br> А как твоё имя?<br>
        <!--        <span class="purple-text">Как тебя зовут?</span>-->
        <img src="@/assets/imgs/triangle.svg" alt=" " class="triangle robot-big--desktop"/>
        <img src="@/assets/imgs/triangle-mobile.svg" alt=" " class="triangle robot-big--mobile"/>
      </p>
    </div>

    <p class="robot-message__text" v-if="message?.body && step !== 0 && step < 8">
      {{ message?.body }}
      <br/>

      <span v-if="step === 4 && message.type === 'city'">
        Выбери, пожалуйста, из списка ниже:
      </span>
      <span class="purple-text" :class="{ 'link': message?.link }">{{ message.purpleText }}</span>
      <!--      <button class="purple-text link">Визитка</button>-->
    </p>

    <div class="illustration" v-if="step === 3 && message.type === 'video'">
      <video class="illustration__filling" @play="videoPlaying = true" controls>
        <source :src="`${appConfig.domain}/files/data/video-preview.webm`" type="video/webm">
        <source :src="`${appConfig.domain}/files/data/video-preview-reserve.mp4`" type="video/mp4"/>
      </video>
    </div>

    <!--    <div class="illustration" v-if="step === 3 && message.type === 'video'">-->
    <!--      <iframe class="illustration__filling" src="https://rutube.ru/play/embed/7da4da60790e971fc48ae48e613cdc45"-->
    <!--              frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen-->
    <!--              allowFullScreen></iframe>-->
    <!--    </div>-->

    <div class="loader" v-if="loader">
      <div class="loader__line">
        <div class="loader__line-filling"></div>
      </div>
      <img src="@/assets/imgs/robot-big.png" alt="" class="loader__img">
    </div>

    <div v-if="step === 4 && message.type === 'city'" class="chat-pop-up pop-up">
      <button class="select-btn chat-select-btn" @click="viewCountries = !viewCountries"
              :class="{ 'select-btn-open': viewCountries }">
        {{ selectedCountry ? selectedCountry : "Выбери свою страну" }}
        <svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M13 8.97205L3.62037 1.15566C2.87788 0.53692 1.77439 0.637236 1.15566 1.37972C0.536919 2.12221 0.637237 3.22569 1.37972 3.84443L11.8797 12.5944C12.5287 13.1352 13.4714 13.1352 14.1204 12.5944L24.6204 3.84443C25.3629 3.22569 25.4632 2.12221 24.8444 1.37972C24.2257 0.637236 23.1222 0.53692 22.3797 1.15566L13 8.97205Z"
              fill="url(#paint0_linear_957_7195)"/>
          <defs>
            <linearGradient id="paint0_linear_957_7195" x1="1.85944" y1="0.749993" x2="18.8976" y2="1.70236"
                            gradientUnits="userSpaceOnUse">
              <stop stop-color="#AD4FEA"/>
              <stop offset="1" stop-color="#4A58DD"/>
            </linearGradient>
          </defs>
        </svg>
      </button>
      <div class="select-list chat-select-list" :class="{ 'select-list-open': viewCountries }">
        <label class="find-wrapper">
          <img src="@/assets/imgs/find.svg" alt="" class="find-icon"/>
          <input type="text" class="find" v-model="searchCountry" placeholder="Поиск"/>
        </label>
        <div class="find-list">
          <button @click="setCountry(item)" class="select-element chat-select-element" v-for="(item, index) in Object.keys(countries)?.filter((country) =>
            searchCountry
              ? country?.toLowerCase()?.includes(searchCountry?.toLowerCase())
              : true
          )" :key="index">
            {{ item }}
          </button>
        </div>
      </div>
    </div>


    <div
        v-if="step === 4 && message.type === 'city' && (!selectedCountry || selectedCountry === 'Российская Федерация')"
        class="chat-pop-up pop-up " :class="{ 'disabled-opacity': !selectedCountry }">
      <button class="select-btn chat-select-btn" @click="viewRegions = !viewRegions"
              :class="{ 'select-btn-open': viewRegions }">
        {{ selectedRegion ? selectedRegion : "Выбери свой регион" }}
        <svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M13 8.97205L3.62037 1.15566C2.87788 0.53692 1.77439 0.637236 1.15566 1.37972C0.536919 2.12221 0.637237 3.22569 1.37972 3.84443L11.8797 12.5944C12.5287 13.1352 13.4714 13.1352 14.1204 12.5944L24.6204 3.84443C25.3629 3.22569 25.4632 2.12221 24.8444 1.37972C24.2257 0.637236 23.1222 0.53692 22.3797 1.15566L13 8.97205Z"
              fill="url(#paint0_linear_957_7195)"/>
          <defs>
            <linearGradient id="paint0_linear_957_7195" x1="1.85944" y1="0.749993" x2="18.8976" y2="1.70236"
                            gradientUnits="userSpaceOnUse">
              <stop stop-color="#AD4FEA"/>
              <stop offset="1" stop-color="#4A58DD"/>
            </linearGradient>
          </defs>
        </svg>
      </button>
      <div class="select-list chat-select-list" :class="{ 'select-list-open': viewRegions }">
        <label class="find-wrapper">
          <img src="@/assets/imgs/find.svg" alt="" class="find-icon"/>
          <input type="text" class="find" v-model="searchRegion" placeholder="Поиск"/>
        </label>
        <div class="find-list">
          <button @click="setRegion(item)" class="select-element chat-select-element" v-for="(item, index) in Object.keys(selectedCountry === 'Российская Федерация' ? countries[selectedCountry] : {})?.filter((region) =>
            searchRegion
              ? region?.toLowerCase()?.includes(searchRegion.toLowerCase())
              : true
          )" :key="index">
            {{ item }}
          </button>
        </div>
      </div>
    </div>

    <div
        v-if="step === 4 && message.type === 'city' && (!selectedCountry || selectedCountry === 'Российская Федерация')"
        class="chat-pop-up pop-up" :class="{ 'disabled-opacity': !selectedRegion }">
      <button class="select-btn chat-select-btn" @click="viewCities = !viewCities"
              :class="{ 'select-btn-open': viewCities }">
        {{ selectedCity ? selectedCity : "Напиши свой населённый пункт" }}
        <svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M13 8.97205L3.62037 1.15566C2.87788 0.53692 1.77439 0.637236 1.15566 1.37972C0.536919 2.12221 0.637237 3.22569 1.37972 3.84443L11.8797 12.5944C12.5287 13.1352 13.4714 13.1352 14.1204 12.5944L24.6204 3.84443C25.3629 3.22569 25.4632 2.12221 24.8444 1.37972C24.2257 0.637236 23.1222 0.53692 22.3797 1.15566L13 8.97205Z"
              fill="url(#paint0_linear_957_7195)"/>
          <defs>
            <linearGradient id="paint0_linear_957_7195" x1="1.85944" y1="0.749993" x2="18.8976" y2="1.70236"
                            gradientUnits="userSpaceOnUse">
              <stop stop-color="#AD4FEA"/>
              <stop offset="1" stop-color="#4A58DD"/>
            </linearGradient>
          </defs>
        </svg>
      </button>
      <div class="select-list chat-select-list" :class="{ 'select-list-open': viewCities }">
        <label class="find-wrapper">
          <img src="@/assets/imgs/find.svg" alt="" class="find-icon"/>
          <input type="text" class="find" v-model="searchCity" placeholder="Поиск"/>
        </label>
        <div class="find-list">
          <button @click="setCity(item)" class="select-element chat-select-element" v-for="(item, index) in cities?.filter((city) =>
            searchCity
              ? city?.toLowerCase()?.includes(searchCity?.toLowerCase())
              : true
          )" :key="index">
            {{ item }}
          </button>
        </div>
      </div>
    </div>

    <!--    <video v-if="message.type === 'video'" src="" poster="@/assets/imgs/video-poster.png"-->
    <!--           class="robot-message__video"></video>-->
    <p class="robot-message__text" v-show="videoPlaying" v-if="step === 3 && message.type === 'video'">
      Отлично! Ну вот ты и узнал меня поближе😉! А теперь нажимай на кнопку «Продолжить»!
    </p>

    <div class="robot-big " v-if="step >= 8 && step <= 12">
      <div class="robot-big-filling">
        <img src="@/assets/imgs/robot-big.png" alt="" class="robot-big__img show-left">
        <div class="robot-big__text-wrapeer show-right">
          <p class="robot-big__text " v-html="message?.body">
          </p>
          <img src="@/assets/imgs/triangle.svg" alt=" " class="triangle robot-big--desktop"/>
          <img src="@/assets/imgs/triangle-mobile.svg" alt=" " class="triangle robot-big--mobile"/>
        </div>
      </div>
      <div class="check-wrapper data" v-if="message.type === 'policy' && step === 12">
        <label class="check">
          <input type="checkbox" v-model="policy" name="data" class="check__input">
          <div class="check__box"></div>
        </label>
        <p class="check-text">
          Продолжая регистрацию и осуществляя пользование данным онлайн-сервисом я принимаю условия <a
            class="purple-text" target="_blank" href="/docs/polzovatelskoe_soglashenie.pdf">пользовательского
          соглашения</a> и даю <a class="purple-text"
                                  href="/docs/soglashenie_na_obrabotky_personalnih_dannih.pdf"
                                  target="_blank">свое согласие на обработку персональных данных</a> на условиях,
          определенных <a
            class="purple-text" :href="`${appConfig.domain}/files/data/politika_obrabotki_personalnih_dannih.pdf`"
            target="_blank">Политикой обработки
          персональных данных.</a>
        </p>
      </div>

      <div class="check-wrapper data" v-if="message.type === 'policy' && step === 12">
        <label class="check">
          <input type="checkbox" v-model="agreementOnTheNewsletter" name="data" class="check__input">
          <div class="check__box"></div>
        </label>
        <p class="check-text">
          Выражаю своё согласие на получение от АНО Департамент и его партнеров информации о федеральных проектах
          «Profstories», «Zaсобой», сервисе «Цифровой помощник для выбора профессии», федеральных, региональных и
          местных
          мероприятиях по профориентации в форме сообщений на указанные при регистрации контактные данные (адрес
          электронной почты, телефон, мессенджеры).
        </p>
      </div>
    </div>
    <!--    <div class="robot-pay-wrapper" v-if="step>=12">-->
    <!--      <div class="robot-pay ">-->
    <!--        <p v-if="step===12" class="robot-big__text robot-pay__text robot-pay__text&#45;&#45;1">Мы не обсудили с тобой самое-->
    <!--          главное! Для того,-->
    <!--          чтобы получить полноценный доступ ко всем моим возможностям, тебе необходимо оплатить полный доступ.-->
    <!--          <img src="@/assets/imgs/triangle-right.svg" alt=" " class="triangle robot-pay&#45;&#45;triangle robot-big&#45;&#45;desktop"/>-->
    <!--          <img src="@/assets/imgs/triangle-mobile.svg" alt=" " class="triangle robot-pay&#45;&#45;triangle robot-big&#45;&#45;mobile"/>-->
    <!--        </p>-->
    <!--        <p v-if="step===13" class="robot-big__text robot-pay__text robot-pay__text&#45;&#45;2">А теперь давай посмотрим, что же-->
    <!--          ты получишь в-->
    <!--          результате.-->
    <!--          Вот оно! Это одно из моих главных сокровищ.-->
    <!--          <img src="@/assets/imgs/triangle-right.svg" alt=" " class="triangle robot-pay&#45;&#45;triangle robot-big&#45;&#45;desktop"/>-->
    <!--          <img src="@/assets/imgs/triangle-mobile-right.svg" alt=" "-->
    <!--               class="triangle robot-pay&#45;&#45;triangle robot-big&#45;&#45;mobile"/>-->
    <!--        </p>-->
    <!--        <p v-if="step===14" class="robot-big__text robot-pay__text robot-pay__text&#45;&#45;3">Я буду тебе помогать и всячески-->
    <!--          способствовать,-->
    <!--          чтобы ты его получил! Скорее смотри!-->
    <!--          <img src="@/assets/imgs/triangle-right.svg" alt=" " class="triangle robot-pay&#45;&#45;triangle robot-big&#45;&#45;desktop"/>-->
    <!--          <img src="@/assets/imgs/triangle-mobile.svg" alt=" " class="triangle robot-pay&#45;&#45;triangle robot-big&#45;&#45;mobile"/>-->
    <!--        </p>-->
    <!--        <iframe v-if="step===15" class="robot-pay__video show-left"-->
    <!--                src="https://rutube.ru/play/embed/a638c9f24e3f11185c1a3dba2a9e37b2"-->
    <!--                frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen-->
    <!--                allowFullScreen></iframe>-->
    <!--        <img src="@/assets/imgs/robot-tresure.png" alt="" class="robot-pay__img robot-pay__img&#45;&#45;video  show-right">-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="robot-video" v-if="step===16">-->
    <!--      <iframe class="robot-pay__video show-left" src="https://rutube.ru/play/embed/a638c9f24e3f11185c1a3dba2a9e37b2"-->
    <!--              frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen-->
    <!--              allowFullScreen></iframe>-->
    <!--      <img src="@/assets/imgs/robot-tresure.png" alt="" class="robot-video__img show-right">-->
    <!--    </div>-->

    <div class="radio-number-wrapper" v-if="step === 6 && message.type === 'course'">
      <label class="radio-number" v-for="(item, index) in message.values" :key="index">
        <input v-model="selectedCourse" :value="item" type="radio" name="number" class="radio-number__checkbox"/>
        <div class="radio-number__content">
          <p class="radio-number__content-text">{{ item }}</p>
        </div>
      </label>
    </div>

    <div class="radio-number-wrapper radio-number-wrapper--organization"
         v-if="message.type === 'organization' && step === 5">
      <label class="radio-number" v-for="item in message.values" :key="item">
        <input type="radio" :value="item" v-model="selectedOrganization" name="text" class="radio-number__checkbox"/>
        <div class="radio-number__content">
          <p class="radio-number__content-text">{{ item }}</p>
        </div>
      </label>
    </div>


    <button class="robot-message__btn button-purple" v-if="viewContinue" @click="nextStep">
      Продолжить
    </button>
  </div>
</template>

<script>
import {inject, computed, ref, watch} from "vue";
import countries from "@/lib/data_file.json";
import {appConfig} from "@/lib/appConfig";

export default {
  name: "RobotMessage",
  computed: {
    appConfig() {
      return appConfig
    }
  },
  props: ["message", "step", 'loader'],
  setup(props, {emit}) {
    const store = inject("store");


    const regions = computed(() => store.getters.regions);
    const searchRegion = ref(null);
    const selectedRegion = computed(
        () => store.getters.registrationData?.region
    );
    const viewRegions = ref(false);

    const videoPlaying = ref(false);

    const cities = computed(() => {
      if (selectedCountry.value === 'Российская Федерация' && selectedRegion.value) {
        return countries[selectedCountry.value][selectedRegion.value];
      }

      return [];
    });


    const searchCity = ref(null);
    const selectedCity = computed(() => store.getters.registrationData?.city);
    const viewCities = ref(false);


    const searchCountry = ref(null);
    const selectedCountry = computed(() => store.getters.registrationData?.sideData?.country);
    const viewCountries = ref(false);


    const policy = ref(false);

    const agreementOnTheNewsletter = computed({
      get() {
        return store.getters.registrationData?.sideData?.sendNews;
      },
      set(val) {
        store.commit("setRegistrationData", {
          field: 'sendNews', val, sideData: true
        });

      }
    })

    const selectedOrganization = computed({
      get: () => store.getters.registrationData?.education,
      set: (val) => {
        store.commit("setRegistrationData", {field: "education", val});
        emit("setOrganization", val.includes("школа"));
      },
    });

    watch(
        () => viewCountries.value,
        (val) => {
          if (val) {
            viewCities.value = false;
            viewRegions.value = false;
          }
        }
    );
    watch(
        () => viewRegions.value,
        (val) => {
          if (val) {
            viewCities.value = false;
            viewCountries.value = false;
          }
        }
    );
    watch(
        () => viewCities.value,
        (val) => {
          if (val) {
            viewRegions.value = false;
            viewCountries.value = false;
          }
        }
    );

    const selectedCourse = computed({
      get: () => store.getters.registrationData?.course,
      set: (val) =>
          store.commit("setRegistrationData", {field: "course", val}),
    });

    const viewContinue = computed(() => {
      return (
          (props.message.type === "video" && props.step === 3 && videoPlaying.value) ||
          (props.message.type === "city" &&
              props.step === 4 &&
              selectedCountry.value && (selectedCountry.value === 'Российская Федерация' ? (selectedRegion.value && (cities.value?.length ? selectedCity.value : true)) : true)) ||
          (props.message.type === "organization" &&
              props.step === 5 &&
              selectedOrganization.value) ||
          (props.message.type === "course" &&
              props.step === 6 &&
              selectedCourse.value) ||
          (props.message.type === "policy" &&
              props.step === 12 &&
              policy.value)

      );
    });

    // const findCity = () =>
    //     store
    //         .dispatch("fetchCities", searchCity.value)
    //         .then((val) => (cities.value = val));
    //
    // if (props.step === 4 && props.message.type === 'city') {
    //   findCity();
    // }

    // watch(() => searchCity.value, findCity);

    return {
      nextStep: () => {
        switch (props.step) {
          case 3:
            emit("nextStep");
            break;
          case 4:
            if (selectedCountry.value !== 'Российская Федерация') {
              store.commit("setRegistrationData", {field: "region", val: 'Другой'})
              store.commit("setRegistrationData", {field: "city", val: 'Москва'})
            }
            emit(
                "sendMessage",
                `${selectedRegion.value} , ${selectedCity.value}`
            );
            break;
          case 5:
            emit("sendMessage", selectedOrganization.value);
            break;
          case 6:
            emit("sendMessage", selectedCourse.value);
            break;
          case 12:
            emit("nextStep");
            break;
        }
        emit('clearMessages');
      },
      regions,
      searchRegion,
      viewRegions,
      selectedRegion,
      setRegion: (val) => {
        store.commit("setRegistrationData", {field: "region", val});
        viewRegions.value = false;
        searchRegion.value = null;
      },

      cities,
      searchCity,
      viewCities,
      selectedCity,

      setCity: (val) => {
        store.commit("setRegistrationData", {field: "city", val});
        viewCities.value = false;
        searchCity.value = null;
      },
      setCountry: (val) => {
        store.commit("setRegistrationData", {field: "country", val, sideData: true});
        viewCountries.value = false;
        searchCountry.value = null;
      },

      selectedOrganization,
      selectedCourse,

      viewContinue,

      policy,
      agreementOnTheNewsletter,
      videoPlaying,

      countries,
      searchCountry,
      selectedCountry,
      viewCountries

    };
  },
};
</script>

<style scoped>
input {
  color: #1e2229;
}

input::placeholder {
  color: #1e2229;
}

.robot-message {
  display: flex;
  flex-direction: column;

}

.robot-message__text {
  margin-top: 0.5rem;
  max-width: 69.25rem;
  width: 100%;
  font-weight: 500;
  font-size: 1.25rem;
  color: rgba(19, 19, 19, 0.8);
  padding: 1.25rem 1.875rem;
  background: #fff;
  border-radius: 0.625rem;
  word-wrap: break-word;
  line-height: 1;
}

.robot-message__video {
  margin: 1rem 0 0.375rem 0;
  max-width: 36rem;
  width: 100%;
  border-radius: 1.25rem;
  height: auto;
  object-fit: cover;
}

.robot-message__btn {
  margin-top: 2.813rem;
  margin-left: auto;
}

.chat-pop-up {
  max-width: 69.25rem;
  font-weight: 400;
  font-size: 0.938rem;
  line-height: 1;
  color: #000000;
  margin-top: 1rem;
}

.chat-select-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.94rem 1.5rem 0.94rem 1.875rem;
  border-radius: 0.625rem;
  background: #fff;
  font-size: 1.25rem;
  font-weight: 500;
}

.chat-select-btn svg {
  width: 1.53125rem;
}

.chat-select-element {
  background: #fff;
  padding: 0.875rem 1.5rem 0.875rem 1.875rem;
  border-bottom: 1px solid #1e2229;
  text-align: left;
  font-size: 1.25rem;
  font-weight: 700;
}

.chat-select-element:last-child {
  border: none;
}

.find {
  font-weight: 500;
  max-width: 100%;
  width: 100%;
  font-family: "Inter";
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
  padding: 0;
}

.find::placeholder {
  color: #acb1c6;
}

.find-list {
  max-height: 14rem;
  overflow: auto;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  border-radius: 0 0 0.625rem 0.625rem;
  scrollbar-color: #4a58dd #f1f4ff;
}

.find-list::-webkit-scrollbar-track {
  background-color: #f1f4ff;
}

.find-list::-webkit-scrollbar-thumb {
  background-color: #4a58dd;
}

.find-wrapper {
  padding: 1rem 1.87rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: none;
  background: #fff;
  border-bottom: 1px solid #1e2229;
  cursor: text;
}

.fing-icon {
  width: 1.125rem;
  height: 1.125rem;
}

.select-btn {
  transition: all 1s ease-in-out;
}

.select-btn svg {
  transition: all 1s ease-in-out;
}

.select-list {
  max-height: 20rem;
}

.select-list-open {
  display: flex;
  border-radius: 0 0 0.625rem 0.625rem;
}

.select-btn-open {
  background: #f1f4ff;
  border-radius: 0.625rem 0.625rem 0 0;
}

.select-btn-open svg {
  transform: rotate(180deg);
}

.check-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
  font-size: 1.25rem;
  max-width: 69.25rem;
  margin-top: 0.5rem;
  color: rgba(19, 19, 19, 0.8);
  padding: 1.25rem 1.875rem;
  background: #fff;
  border-radius: 0.625rem;
  word-wrap: break-word;
  align-items: center;
}


.check {
  display: flex;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.check:hover {
  opacity: 0.8;
}

.check__input {
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
}

.check__box {
  width: 100%;
  height: 100%;
  background: center / cover no-repeat url("@/assets/imgs/check__box.svg");
}

.check__input:checked ~ .check__box {
  background: center / cover no-repeat url("@/assets/imgs/check__box-on.svg");
}

.robot-big {
  margin-top: 0.5rem;
  display: flex;

  min-height: calc(100vh - 228px);
  min-height: calc(100dvh - 228px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.robot-big-filling {
  display: flex;
  align-items: center;
  gap: 3rem;
}

@media (max-width: 1600px) {

  .robot-big {
    min-height: calc(100vh - 200px);
    min-height: calc(100dvh - 200px);
  }
}

.robot-big__img {
  width: 15.3rem;
}

.robot-big__text-wrapeer {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.robot-big__text {
  border-radius: 0.625rem;
  background: #FFF;

  font-weight: 500;
  font-size: 1.25rem;
  color: rgba(19, 19, 19, 0.8);
  padding: 1.25rem 1.875rem;
  word-wrap: break-word;
  line-height: 1;

}

.triangle {
  width: 4.0625rem;
  position: absolute;
  left: -2.88rem;
}

.robot-pay-wrapper {
  min-height: calc(100vh - 140px);
  min-height: calc(100dvh - 140px);
  align-items: center;
  display: flex;
  padding-top: 3rem;
}

.robot-pay {
  max-width: 80rem;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 3rem;
  transition: all 1s ease-in-out;

}

@media (max-width: 1600px) {

  .robot-big {
    min-height: calc(100vh - 200px);
    min-height: calc(100dvh - 200px);
  }

}

.robot-pay__text {
  margin-top: 2.56rem;
  animation: show-left 1s ease;
}

.robot-big--mobile {
  display: none;
}

.robot-pay--triangle {
  left: unset;
  right: -3rem;
}

.robot-pay__video {
  max-width: 46.8125rem;
  width: 100%;
  height: 27.42919rem;
}


.greetings {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 3rem;
}

.greetings__text {
  font-weight: 500;
  font-size: 1.25rem;
  color: rgba(19, 19, 19, 0.8);
  padding: 1.25rem 1.875rem;
  background: #fff;
  border-radius: 0.625rem;
  word-wrap: break-word;
  line-height: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.greetings__img {
  width: 4.375rem;
}

.loader {
  padding-top: 6rem;
  position: fixed;
  left: 0;
  top: 86px;
  background: linear-gradient(180deg, #101215 10.35%, #21262E 100%);
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;;
}

.loader__line {
  width: 9.54438rem;
  height: 0.55494rem;
  border-radius: 1.10981rem;
  background: #48494B;
}

.loader__line-filling {
  background: linear-gradient(92deg, #AD4FEA 4.41%, #4A58DD 72.27%);
  height: 100%;
  animation: grow 1s ease-in;
  border-radius: 1.10981rem;
}

.loader__img {
  margin-top: 4rem;
  width: 9.5rem;

}

.illustration {
  margin-top: 1rem;

}

.illustration__filling {
  width: 36rem;
  height: 17.5rem;
  border-radius: 1.25rem;
}

@keyframes grow {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@media (max-width: 850px) {
  .robot-big--desktop {
    display: none;
  }

  .robot-big--mobile {
    display: flex;
  }


  .robot-big__img {
    width: 13.375rem;
  }

  .triangle {
    width: 1.32144rem;
    position: absolute;
    left: 0.62rem;
    bottom: -1.3rem;
  }

  .robot-pay {
    flex-direction: column;
    gap: 0;
  }

  .robot-pay__img {
    margin: 1rem auto 0 auto;
    width: 13.375rem;
    position: relative;
    z-index: 2;
  }

  .robot-pay__text {
    margin-top: 0;
    max-width: 18.75rem;

  }

  .robot-pay__text--2 {
    margin-left: auto;
    animation: show-right 1s ease;
    max-width: 15.75rem;

  }

  .robot-pay__text--2 .robot-pay--triangle {
    left: unset;
    right: 0.63rem;
  }

  .robot-video {
    margin-top: 0.5rem;
    position: relative;
    justify-content: center;
    display: flex;
    height: calc(100vh - 7rem);
    align-items: center;
  }

  .robot-pay__video {
    width: 12rem;
    height: 7rem;
    position: absolute;
    left: 1rem;

    top: unset;
    z-index: 5;
  }


  .robot-video__img {
    width: 16.43338rem;
    position: absolute;
  }

  .greetings {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 0rem;
  }

  .greetings__img {
    width: 13.375rem;
    margin: 0 auto;
  }

  .greetings__text span {
    font-size: 1.625rem;
    font-weight: 700;
  }

  .loader {
    padding-top: 3rem;
    top: 0;
  }

  .loader__line {
    width: 5.375rem;
    height: 0.3125rem;
    border-radius: 1.10981rem;
    background: #48494B;
  }

  .loader__img {
    margin-top: 1rem;
    width: 5.375rem;

  }


  .robot-big-filling {
    margin-top: 0.5rem;
    gap: 1rem;
    flex-direction: column-reverse;
    justify-content: center;
    max-width: 100%;
    width: 100%;
  }

  .robot-pay-wrapper {
    height: calc(100dvh - 92px);
    justify-content: center;
  }

  .robot-pay__img--video {
    position: relative;
    right: -2rem;
  }

  .illustration {
    margin-top: 0.5rem;

  }

  .illustration__filling {
    width: 18rem;
    height: 10.5rem;
    border-radius: 1rem;
  }

  .radio-number-wrapper--organization .radio-number {
    max-width: 100%;
    width: 100%;
  }

  .radio-number__content {
    justify-content: flex-start;
    text-align: left;
  }

}

@media (max-width: 700px) {
  .robot-message__text {
    max-width: 72%;
    font-size: 1.125rem;
    line-height: 1;
    padding: 0.75rem 1rem;
  }

  .robot-message__btn {
    margin-top: 1rem;
  }

  .chat-pop-up {
    max-width: 100%;
    font-weight: 400;
    font-size: 1rem;
  }

  .find-wrapper {
    padding: 0.81rem;
  }

  .chat-select-btn {
    padding: 0.75rem 0.813rem 0.75rem 1rem;
  }

  .chat-select-btn svg {
    width: 0.875rem;
    display: flex;
    flex-shrink: 0;
  }

  .chat-select-element {
    display: flex;
    flex-shrink: 0;
    padding: 0.81rem;
    font-size: 1rem;
  }

  .find {
    font-size: 1rem;
  }

  .find-icon {
    width: 1.125rem;
    height: 1.125rem;
  }

  .check-wrapper {
    max-width: 100%;
    font-size: 1.125rem;
    line-height: 1;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    align-items: flex-start;
  }


  .check {
    display: flex;
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  .robot-big__text {
    font-size: 1.125rem;
    line-height: 1;
    padding: 0.75rem 1rem;
  }

  .triangle {

    left: 0.62rem;
    bottom: -1.3rem;
  }

  .robot-pay {
    margin-top: 0.5rem;
  }

  .greetings__text {
    font-size: 1.125rem;
    line-height: 1;
    padding: 0.75rem 1rem;
  }

  .greetings__text .triangle {
    left: calc(50% - 0.7rem);
  }

}

.show-left {
  animation: show-left 1s ease;
}

.show-right {
  animation: show-right 1s ease;
}

@keyframes show-left {
  from {
    opacity: 0;
    transform: translateX(-10%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes show-right {
  from {
    opacity: 0;
    transform: translateX(10%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.purple-text {
  background-color: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  background-image: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
