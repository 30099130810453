<template>
  <div class="porofile">
    <profile-navigation :user-name="`${userData?.firstName} ${userData?.secondName}`" v-if="!mode"
                        @setProfileMode="(val) => (mode = val)"></profile-navigation>
    <profile-data @closeWindow="mode = null" v-if="mode === 'info'"></profile-data>
    <profile-info @closeWindow="mode = null" v-if="mode === 'profile'"></profile-info>
    <profile-education-select v-if="false"></profile-education-select>
    <profile-education-settings @closeWindow="mode = null" v-if="mode === 'education'"></profile-education-settings>
    <change-photo v-if="avatarFile" :avatar-file="avatarFile" :user-data="userData">
    </change-photo>
  </div>
</template>WW

<script>
import ProfileNavigation from "@/components/profile/ProfileNavigation.vue";
import ProfileData from "@/components/profile/ProfileData.vue";
import ProfileInfo from "@/components/profile/ProfileInfo.vue";
import ChangePhoto from "@/components/profile/ChangePhoto.vue";
import ProfileEducationSelect from "@/components/profile/ProfileEducationSelect.vue";
import ProfileEducationSettings from "@/components/profile/ProfileEducationSettings.vue";
import {computed, inject, ref} from "vue";
import {useStore} from "vuex";

export default {
  name: "ProfilePage",
  components: {
    ProfileNavigation,
    ProfileData,
    ProfileInfo,
    ProfileEducationSelect,
    ProfileEducationSettings,
    ChangePhoto
  },
  setup() {
    const store = inject("store") || useStore();
    store.dispatch("fetchRegions");
    const mode = ref(null);
    const avatarFile = computed(() => store.getters.avatarFile);
    return {
      mode,
      userData: computed(() => store.getters.userData),
      avatarFile
    };
  },
};
</script>

<style scoped>
.porofile {
  max-width: 55rem;
  padding: 1rem;
  background: linear-gradient(180deg, #101215 0%, #21262e 100%);
  border-radius: 0.625rem;
}
</style>
