<template>
  <div class="filling-9">
    <img src="@/assets/imgs/video/bg-2-9.png" alt="" class="bg-9"/>
    <div class="content-9">
      <p class="title-9">
        Верю в твой успех!<br/>
        Будущее зависит<br/>
        от того, что ты Делаешь сегодня!<br/>
      </p>
      <div class="helper-9">
        <img
            src="@/assets/imgs/video/robot-small.webp"
            alt=""
            class="robot-9"
        />
        <p class="text-9">Цифровой помощник</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PresentationElement9",
};
</script>

<style scoped>
.filling-9 {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  height: 100vh;
  height: 100dvh;
}

.bg-9 {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.content-9 {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  max-width: 100%;
  width: 100%;
}

.title-9 {
  max-width: 79.31vw;
  text-align: center;
  font-size: 55px;
  font-weight: 700;
  line-height: 143.5%;
  letter-spacing: 3.3px;
  text-transform: uppercase;
}

.helper-9 {
  margin-top: 25px;
  display: flex;
  align-items: center;
  gap: 14px;
}

.robot-9 {
  width: 58px;
}

.text-9 {
  font-size: 19.333px;
}
</style>
