<script>
export default {
  name: "CongratulationModal",
  props: ['scoresPaymentTarget'],

}
</script>

<template>
  <div class="wrapper">
    <div class="modal">
      <div class="head">
        <div class="caption">
          <p class="title">Поздравляем! </p>
          <button class="close" @click="$emit('close')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31" fill="none">
              <g clip-path="url(#clip0_24_424)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M28.7857 2.21436L2.21429 28.7858L28.7857 2.21436ZM2.21429 2.21436L28.7857 28.7858L2.21429 2.21436Z"
                  fill="#BFBDB8" />
                <path d="M28.7857 2.21436L2.21429 28.7858M2.21429 2.21436L28.7857 28.7858" stroke="#BFBDB8"
                  stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_24_424">
                  <rect width="31" height="31" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <img src="@/assets/imgs/congratulation-img.png" alt="" class="head__img">
      </div>
      <div class="filling" v-if="scoresPaymentTarget === 'marathon'">
        <p class="text">Ты потратил <span class="purple-text text__big"> 250 баллов <br></span>
          на участие в марафоне.</p>
        <p class="text">Все подробности пришлю тебе на твою почту или в личном сообщении <span class="purple-text">в
            конце января 2025 года.</span>
        </p>
        <p class="text">Обязательно отслеживай входящие письма 😉</p>
      </div>
      <div class="filling" v-if="scoresPaymentTarget === 'courses'">
        <p class="text">Ты потратил <span class="purple-text text__big"> 250 баллов <br></span>
          на онлайн-курс.</p>
        <p class="text">Я уже отправил ссылку на твою почту. Скорей смотри 😉</p>
        <a href="mailto:" target="_blank" class="link">Проверить почту</a>
        <p class="text">Если вдруг ты не сможешь её найти,
          то обязательно проверь все <span class="purple-text">папки входящих писем, особенно «Спам».</span></p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  z-index: 13000;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
  padding: 3rem;
  display: flex;
  scrollbar-width: none;
}

.modal {
  border-radius: 1rem;
  background: #fff;
  position: relative;
  pointer-events: all;
  max-width: 33.9375rem;
  width: 100%;
  margin: auto;
  z-index: 10;
  position: relative;
  color: #000;
}

.head {
  border-radius: 1rem 1rem 0 0;
  background: #F0E9FE;
  padding: 1.87rem 2rem 0.56rem 2rem;
  display: flex;
  flex-direction: column;
}

.caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}


.title {
  color: #333;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: normal;
}

.close {
  margin-left: auto;
  width: 1.9375rem;
  height: 1.9375rem;
}

.close svg {
  width: 100%;
}

.head__img {
  width: 17.98075rem;
  margin: 0.62rem auto 0 auto;
}

.filling {
  padding: 1.5rem 2.19rem 2.25rem 2.19rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.text {
  color: #000;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 120%;
}


.text__big {
  background: linear-gradient(92deg, #C977FF 4.41%, #6D7BFC 72.27%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 120%;
}

.link {
  padding: 1.44rem;
  background: #6256E0;
  text-align: center;
  color: #FFF;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: normal;
  border-radius: 1.25rem;
  margin: 0.5rem 0;
}

@media (max-width: 850px) {
  .wrapper {
    padding: 3rem 1rem;
  }

  .modal {
    max-width: 24rem;

  }

  .head {
    padding: 1rem 1.5rem;
  }

  .title {
    font-size: 2rem;
  }

  .close {
    width: 1.2rem;
    height: 1.2rem;
  }

  .head__img {
    width: 12rem;
    margin: 1rem auto 0 auto;
  }

  .filling {
    padding: 1rem 1.5rem;
    gap: 0.5rem;
  }

  .text {
    font-size: 1.2rem;
  }


  .text__big {
    font-size: 1.5rem;
  }

  .link {
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 1rem;
  }
}
</style>