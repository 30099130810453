<template>
  <div class="filling" :class="{ 'step': step === 1 }">
    <img src="@/assets/imgs/video/bg-2.webp" alt="" class="bg" />

    <div class="content" :class="{ 'animate': step === 1 }">
      <p class="title">
        Я только что побывал <br />
        в будущем! <br />
        И у меня есть что <br />
        рассказать тебе!
      </p>
      <div class="helper">
        <img src="@/assets/imgs/video/robot-small.webp" alt="" class="robot" />
        <p class="text">Цифровой помощник</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoElement2",
  props: ["step"],
};
</script>

<style scoped>
.filling {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.bg {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  transition: all 1.5s ease-in;
  transform: translateY(100%);
  opacity: 0;
  will-change: transform;
}

.animate {
  animation: content-open 2s 0.5s both;
}

@keyframes content-open {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.title {
  max-width: 50.31vw;
  text-align: center;
  font-size: 3.33vw;
  font-weight: 700;
  line-height: 143.5%;
  letter-spacing: 0.21vw;
  text-transform: uppercase;
}

.helper {
  margin-top: 1.3vw;
  display: flex;
  align-items: center;
}

.robot {
  width: 3.02vw;
}

.text {
  margin-left: 0.73vw;
  font-size: 0.99vw;
  font-weight: 500;
}
</style>
