import router from "@/router";
import {
    fetchCompanyMissions,
    saveMissionPassing,
    fetchProfessionDescriptions
} from "@/store/missions/serverInterationMethods";


export const missions = {
    actions: {
        async fetchMissionsAnswers({getters, commit}, missionId) {
            const {data} = await (missionId === 4 ? fetchCompanyMissions(getters.token) : fetchProfessionDescriptions(getters.token));
            commit('setMissionAnswers', {data, missionId})
        },

        async saveMissionPassing({getters}) {
            const {status} = await saveMissionPassing(getters.token, getters.missionPassing);
            if (status === 200) {
                await this.dispatch('fetchUserData');
                await router.push({name: 'missionPass', params: {missionId: getters.missionPassing.missionCount + 1}});
            }
        }
    },
    mutations: {

        setMissionAnswers(state, {data, missionId}) {
            data = data.map(profession => {
                return {
                    name: profession.description,
                    value: profession.value
                }
            });
            state.missions[state.missions.map(item => item.missionId).indexOf(missionId)].questions[0].answers = data?.slice(0, 2);
            state.missions[state.missions.map(item => item.missionId).indexOf(missionId)].questions[1].answers = data?.slice(2, 4);
            state.missions[state.missions.map(item => item.missionId).indexOf(missionId)].questions[2].answers = data?.slice(4, 6);
        },

        initMissionPassing(state, {id, missions}) {


            if (!accessToMission(id, missions)) {
                router.push({name: 'missions'});
                return;
            }

            state.missionPassing.missionCount = id;
            state.missionPassing.answers = {};

            if (id === 5) {
                state.missionPassing.answers['q47'] = '';
                state.missionPassing.answers['q51'] = '';
            }

        },
        setMissionPassingAnswer(state, {val, type, id, skipQuestion}) {
            if (id === 'q1' && val === 'b')
                skipQuestion();

            if (type === 'yesOrNo' || type === 'radio' || type === 'response' || type === 'img' || type === 'company' || type === 'rating' || type === 'open') {
                state.missionPassing.answers[id] = val;
            }

            if (type === 'checkbox') {

                if (state.missionPassing.answers[id]) {
                    const answers = state.missionPassing.answers[id].split(',');
                    const index = answers.indexOf(val)
                    if (index !== -1) {
                        answers.splice(index, 1);
                        state.missionPassing.answers[id] = answers.join(',')
                        return;
                    }
                    if (answers.length === 2) {
                        answers.shift();
                    }
                    answers.push(val);
                    state.missionPassing.answers[id] = answers.join(',')
                } else {
                    state.missionPassing.answers[id] = val;
                }
            }
        }
    },
    state: {
        missions: [
            {
                missionId: 1,
                questions: [
                    {
                        id: 'q1',
                        body: 'Скажи, ты раньше участвовал в мероприятиях по выбору профессии🤓?',
                        purpleBody: null,
                        type: 'yesOrNo',
                        answers: [
                            {
                                name: 'да',
                                value: 'a'
                            },
                            {
                                name: 'нет',
                                value: 'b'
                            }
                        ],
                        continueText: 'Продолжить'
                    },
                    // {
                    //     id: 'q2',
                    //     body: null,
                    //     purpleBody: 'На каких мероприятиях ты был?',
                    //     type: 'radio',
                    //     answers: [
                    //         {
                    //             name: 'Билет в будущее',
                    //             value: 'a'
                    //         },
                    //         {
                    //             name: 'Проектория',
                    //             value: 'b'
                    //         },
                    //         {
                    //             name: 'Другое мероприятие',
                    //             value: 'с'
                    //         },
                    //     ],
                    //     continueText: 'Продолжить'
                    //
                    // },
                    {
                        id: 'q3',
                        body: null,
                        purpleBody: 'А что для тебя самое главное в будущей профессии#📝#?',
                        smiles: true,
                        type: 'radio',
                        answers: [
                            {
                                name: 'её востребованность на рынке труда',
                                value: 'a'
                            },
                            {
                                name: 'рекомендации родителей и близких людей',
                                value: 'b'
                            },
                            {
                                name: 'престиж профессии',
                                value: 'с'
                            },
                        ],
                        continueText: 'Как интересно! Давай дальше!'

                    },
                    {
                        id: 'q49',
                        body: null,
                        purpleBody: 'Планируешь ли ты в будущем работать в своём городе/регионе #🌇#?',
                        smiles: true,
                        type: 'radio',
                        answers: [
                            {
                                name: 'конечно',
                                value: 'a'
                            },
                            {
                                name: 'нет, хочу переехать в другой город',
                                value: 'b'
                            },
                        ],
                        continueText: 'Ответ засчитан! Двигаемся дальше'

                    },
                    {
                        id: 'q50',
                        body: null,
                        purpleBody: 'Мало знаю про твой город.\nСкажи, в нём достаточно возможностей для профессионального роста#🤔#?',
                        smiles: true,
                        type: 'radio',
                        answers: [
                            {
                                name: 'да, достаточно возможностей',
                                value: 'a'
                            },
                            {
                                name: 'нет, в регионах трудно построить карьеру',
                                value: 'b'
                            },
                        ],
                        continueText: 'Принято! \n' +
                            'Переходим к следующему вопросу'

                    },

                    {
                        id: 'q4',
                        body: 'Как гласит народная мудрость: «Нужно работать, чтобы жить, а не жить, чтобы работать» 😉 Я хочу понять, о чём ты мечтаешь, чтобы я мог рекомендовать тебе ту профессию, которая поможет тебе реализовать твои материальные мечты🤗 Я буду предлагать тебе несколько вариантов, выбери тот, который тебе ближе.',
                        purpleBody: 'Машина или мотоцикл? На чём тебе будет комфортнее передвигаться#🤔#?',
                        type: 'img',
                        smiles: true,
                        answers: [
                            {
                                name: 'автомобиль',
                                value: require('@/assets/imgs/missions/car.png')
                            },
                            {
                                name: 'мотоцикл',
                                value: require('@/assets/imgs/missions/bike.png')
                            },
                        ],
                        continueText: 'Едем дальше!'

                    },
                    {
                        id: 'q4',
                        body: '',
                        purpleBody: 'А теперь давай подумаем о том, где ты будешь жить: квартира или дом #🏡#?',
                        type: 'img',
                        smiles: true,
                        answers: [
                            {
                                name: 'дом',
                                value: require('@/assets/imgs/missions/house.png')
                            },
                            {
                                name: 'квартира',
                                value: require('@/assets/imgs/missions/flat.png')
                            },
                        ],
                        continueText: 'Круто! Переходим к следущей мечте!'

                    },
                    {
                        id: 'q4',
                        body: 'Кто хорошо работает, тот хорошо и отдыхает😉\nГде ты выберешь отдохнуть: в горах или на море?',
                        purpleBody: '',
                        type: 'img',

                        answers: [
                            {
                                name: 'в горах',
                                value: require('@/assets/imgs/missions/gori.jpg')
                            },
                            {
                                name: 'на море',
                                value: require('@/assets/imgs/missions/more.jpg')
                            },
                        ],
                        continueText: 'Отдохнули! Давай дальше'

                    },
                    {
                        id: 'q4',
                        body: 'Расскажи мне, как ты хочешь работать🤓: из дома или в офисе?',
                        purpleBody: '',
                        type: 'img',
                        answers: [
                            {
                                name: 'в офисе',
                                value: require('@/assets/imgs/missions/office.jpg')

                            },
                            {
                                name: 'из дома',
                                value: require('@/assets/imgs/missions/udalenka.jpeg')
                            },
                        ],
                        continueText: 'Отличные мечты! Но это ещё не всё.\n' +
                            'Едем дальше'

                    },
                    {
                        id: 'q5',
                        body: 'Первая миссия подошла к концу🤗, а теперь ставь свою оценку этой миссии и идём дальше.',
                        purpleBody: '',
                        type: 'response',
                        answers: [
                            {
                                name: 'like',
                                value: require('@/assets/imgs/emoji/like.svg')
                            },
                            {
                                name: 'fire',
                                value: require('@/assets/imgs/emoji/fire.svg')
                            },
                            {
                                name: 'dislike',
                                value: require('@/assets/imgs/emoji/dislike.svg')
                            },
                        ],
                        continueText: 'Спасибо! Нажимай и продолжим'

                    },
                ],
            },
            {
                missionId: 2,
                questions: [
                    {
                        id: 'q8',
                        body: '',
                        purpleBody: 'Представь себе идеальную работу, где каждый рабочий день — это вдохновение и хорошее вознаграждение#🤓# Что бы тебе нравилось делать на такой работе#🤔#?',
                        smiles: true,
                        type: 'radio',
                        answers: [
                            {
                                name: 'общаться с разными людьми, публично выступать',
                                value: 'a'
                            },
                            {
                                name: 'заниматься творчеством: например, снимать фильмы, рисовать, фотографироваться',
                                value: 'b'
                            },
                            {
                                name: 'заниматься расчётами  и документами',
                                value: 'c'
                            },
                        ],
                        continueText: 'Принято! \n' +
                            'Переходим к следующему вопросу'
                    },
                    {
                        id: 'q9',
                        body: '',
                        purpleBody: 'Представь, что тебе нужно определиться прямо сейчас.\nКакой из предложенных вариантов ты выберешь#✅#?',
                        smiles: true,
                        type: 'radio',
                        answers: [
                            {
                                name: 'учёный или изобретатель',
                                value: 'a'
                            },
                            {
                                name: 'художник или музыкант',
                                value: 'b'
                            },
                            {
                                name: 'журналист или переводчик',
                                value: 'c'
                            },
                        ],
                        continueText: 'Чудесно! Идем дальше'
                    },
                    {
                        id: 'q10',
                        body: '',
                        purpleBody: 'Отлично#✨#! Теперь перейдём к важному вопросу.\nКакая рабочая обстановка была бы для тебя наиболее подходящей#👨‍💻#?',
                        type: 'radio',
                        smiles: true,
                        answers: [
                            {
                                name: 'в помещении, где много людей',
                                value: 'a'
                            },
                            {
                                name: 'в экстремальных условиях',
                                value: 'b'
                            },
                            {
                                name: 'в обычном кабинете или офисе',
                                value: 'c'
                            },
                        ],
                        continueText: 'Продолжаем нашу беседу'
                    },
                    {
                        id: 'q11',
                        body: '',
                        purpleBody: 'Моё призвание — помогать другим#🤝#\nИ мне интересно, а что из предложенного тебе бы понравилось делать больше всего#😉#?',
                        smiles: true,
                        type: 'radio',
                        answers: [
                            {
                                name: 'заниматься сбытом товаров',
                                value: 'a'
                            },
                            {
                                name: 'изготавливать изделия',
                                value: 'b'
                            },
                            {
                                name: 'планировать рекламную кампанию',
                                value: 'c'
                            },
                        ],
                        continueText: 'Отлично! Переходим дальше'
                    },
                    {
                        id: 'q12',
                        body: '',
                        purpleBody: 'А как ты думаешь, что больше всего влияет на наше будущее#✨#?',
                        type: 'radio',
                        smiles: true,
                        answers: [
                            {
                                name: 'взаимопонимание между людьми',
                                value: 'a'
                            },
                            {
                                name: 'научные открытия',
                                value: 'b'
                            },
                            {
                                name: 'развитие производства',
                                value: 'c'
                            },
                        ],
                        continueText: 'Я так и думал! \n' +
                            'А теперь - следующий вопрос!'
                    },
                    {
                        id: 'q13',
                        body: '',
                        purpleBody: 'Теперь давай себе представим, что ты — глава компании#💪🏼#\nЧем ты займёшься с самого начала?',
                        smiles: true,
                        type: 'radio',
                        answers: [
                            {
                                name: 'созданием дружного, сплочённого коллектива',
                                value: 'a'
                            },
                            {
                                name: 'разработкой новых технологий',
                                value: 'b'
                            },
                            {
                                name: 'сбором и анализом информации',
                                value: 'c'
                            },
                        ],
                        continueText: 'Ты не перестаёшь меня удивлять,\n' +
                            ' идём дальше'
                    },
                    {
                        id: 'q14',
                        body: '',
                        purpleBody: 'Ну что, переключимся с будущего на настоящее #😉#?\nРасскажи, что ты больше всего ценишь в книгах или фильмах#🎬#',
                        type: 'radio',
                        smiles: true,
                        answers: [
                            {
                                name: 'возможность следить за ходом мыслей автора',
                                value: 'a'
                            },
                            {
                                name: 'мастерство писателя или режиссера',
                                value: 'b'
                            },
                            {
                                name: 'сюжет, действия героев',
                                value: 'c'
                            },
                        ],
                        continueText: 'Как интересно! Скорее к следующему вопросу!'
                    },
                    {
                        id: 'q15',
                        body: '',
                        purpleBody: 'А теперь очень простой вопрос. Как ты предпочитаешь проводить своё свободное время#✨#?',
                        smiles: true,
                        type: 'radio',
                        answers: [
                            {
                                name: 'учиться новому, ставить различные опыты, эксперименты',
                                value: 'a'
                            },
                            {
                                name: 'писать стихи, сочинять музыку или рисовать',
                                value: 'b'
                            },
                            {
                                name: 'заниматься спортом',
                                value: 'c'
                            },
                        ],
                        continueText: 'Засчитано! Идём дальше'
                    },
                    {
                        id: 'q16',
                        body: '',
                        purpleBody: 'Представь, что все экзамены сданы и у тебя появилось чуть больше свободного времени#🤗# Что бы ты делал?',
                        type: 'radio',
                        smiles: true,
                        answers: [
                            {
                                name: 'читал, думал, рассуждал ',
                                value: 'a'
                            },
                            {
                                name: 'что-нибудь мастерил, шил, ухаживал за животными, растениями',
                                value: 'b'
                            },
                            {
                                name: 'ходил с друзьями на выставки, концерты, в музеи',
                                value: 'c'
                            },
                        ],
                        continueText: 'Засчитано! Идём дальше'
                    },

                    {
                        id: 'q17',
                        body: '',
                        purpleBody: 'А у меня, между прочим, тоже есть несколько увлечений#😉#\nВ свободное от вычислений время я изучаю самые востребованные профессии, отслеживаю передовые технологии#👨‍💻# \nУ меня свой стиль путешествий — виртуальный. Скажи, а что тебя интересует в путешествиях#🤔#?',
                        type: 'radio',
                        smiles: true,
                        answers: [
                            {
                                name: 'возможность знакомства с историей и культурой другой страны',
                                value: 'a'
                            },
                            {
                                name: 'экстремальный туризм (альпинизм, виндсёрфинг, горные лыжи)',
                                value: 'b'
                            },
                            {
                                name: 'общение с местными жителями',
                                value: 'c'
                            },
                        ],
                        continueText: 'Отлично! Следующий вопрос от меня'
                    },

                    {
                        id: 'q18',
                        body: '',
                        purpleBody: 'Знаешь, а помимо виртуальных путешествий я ещё люблю смотреть документальные фильмы#🎥# Порой в них такие крутые сюжеты, не хуже, чем в хорошем сериале! А что бы ты рекомендовал для просмотра#📺#? ',
                        type: 'radio',
                        smiles: true,
                        answers: [
                            {
                                name: 'научно-популярные фильмы',
                                value: 'a'
                            },
                            {
                                name: 'программы о культуре и искусстве',
                                value: 'b'
                            },
                            {
                                name: 'спортивные программы',
                                value: 'c'
                            },
                        ],
                        continueText: 'Засчитано! Поехали дальше'
                    },
                    {
                        id: 'q19',
                        body: '',
                        purpleBody: 'Надеюсь, что ты любишь читать! Если не большие книги, то маленькие тексты#😉# Что из предложенного ты бы хотел почитать больше#🤓#?',
                        type: 'radio',
                        smiles: true,
                        answers: [
                            {
                                name: 'о выдающихся учёных и их открытиях',
                                value: 'a'
                            },
                            {
                                name: 'о великих изобретениях',
                                value: 'b'
                            },
                            {
                                name: 'о жизни и творчестве писателей, художников, музыкантов',
                                value: 'c'
                            },
                        ],
                        continueText: 'Зафиксировал. Вперёд, к следующему вопросу!'
                    },
                    {
                        id: 'q20',
                        body: '',
                        purpleBody: 'Как ты уже знаешь, я создан работодателями, чтобы помогать в выборе карьерного пути#📝# Чем больше узнаю людей, тем сильнее влюбляюсь в человечество#🤗#: доброе, талантливое, создающее новые технологии! А что ты больше всего ценишь в людях?',
                        type: 'radio',
                        smiles: true,
                        answers: [
                            {
                                name: 'дружелюбие',
                                value: 'a'
                            },
                            {
                                name: 'смелость',
                                value: 'b'
                            },
                            {
                                name: 'эрудицию',
                                value: 'c'
                            },
                        ],
                        continueText: 'Хороший ответ. Едем дальше'
                    },

                    {
                        id: 'q21',
                        body: '',
                        purpleBody: 'Мы с тобой очень бодро продвигаемся и прошли уже половину пути#💪🏼#\nСкажи, о чём ты с радостью поддержишь разговор?',
                        smiles: true,
                        type: 'radio',
                        answers: [
                            {
                                name: 'о человеческих взаимоотношениях',
                                value: 'a'
                            },
                            {
                                name: 'о новой научной гипотезе',
                                value: 'b'
                            },
                            {
                                name: 'о технике и гаджетах',
                                value: 'c'
                            },
                        ],
                        continueText: 'Я тоже! Нажимай, и продолжим наше общение'
                    },

                    {
                        id: 'q22',
                        body: '',
                        purpleBody: 'Если бы я позвал тебя на мастер-класс, какой бы ты выбрал#✨#?',
                        smiles: true,
                        type: 'radio',
                        answers: [
                            {
                                name: 'технический',
                                value: 'a'
                            },
                            {
                                name: 'творческий',
                                value: 'b'
                            },
                            {
                                name: 'спортивный',
                                value: 'c'
                            },
                        ],
                        continueText: 'Отлично! Идём дальше'
                    },

                    {
                        id: 'q23',
                        body: '',
                        purpleBody: 'Представь, что ты уже получил образование, получил опыт и тебя назначили министром образования #😉# Чему бы тогда учила школа под твоим руководством?',
                        type: 'radio',
                        smiles: true,
                        answers: [
                            {
                                name: 'общению с другими людьми',
                                value: 'a'
                            },
                            {
                                name: 'навыкам практической работы',
                                value: 'b'
                            },
                            {
                                name: 'навыкам работы с информацией',
                                value: 'c'
                            },
                        ],
                        continueText: 'Отличный ответ! Следующий вопрос'
                    },
                    {
                        id: 'q24',
                        body: '',
                        purpleBody: 'Важный вопрос для тебя, как для министра! Как ты думаешь, на что в любом учебном заведении следует обращать внимание больше всего#🤔#?',
                        type: 'radio',
                        smiles: true,
                        answers: [
                            {
                                name: 'улучшение взаимопонимания между преподавателями и учениками',
                                value: 'a'
                            },
                            {
                                name: 'улучшение физической формы учащихся',
                                value: 'b'
                            },
                            {
                                name: 'совершенствование обучающих программ',
                                value: 'c'
                            },
                        ],
                        continueText: 'Засчитано! Едем дальше'
                    },
                    {
                        id: 'q25',
                        body: '',
                        purpleBody: 'Представь, что у тебя был бы выбор, на какой урок пойти в школе #😉# — какой бы ты выбрал?',
                        type: 'radio',
                        smiles: true,
                        answers: [
                            {
                                name: 'труд',
                                value: 'a'
                            },
                            {
                                name: 'ОБЖ',
                                value: 'b'
                            },
                            {
                                name: 'информатика',
                                value: 'c'
                            },
                        ],
                        continueText: 'Интересный выбор!\n' +
                            'Скорее жми на кнопку!'
                    },
                    {
                        id: 'q26',
                        body: '',
                        purpleBody: 'А теперь представь себя на технологической выставке #🤩# Всё вокруг сияет новейшими гаджетами, инновационными разработками #📱# На что ты сразу обратишь внимание на этой выставке?',
                        type: 'radio',
                        smiles: true,
                        answers: [
                            {
                                name: 'техническое устройство экспонатов',
                                value: 'a'
                            },
                            {
                                name: 'практическое применение экспонатов',
                                value: 'b'
                            },
                            {
                                name: 'внешний вид экспонатов (цвет, форма, интерактивность)',
                                value: 'c'
                            },
                        ],
                        continueText: 'Поддерживаю! Едем дальше!'
                    },
                    {
                        id: 'q27',
                        body: '',
                        purpleBody: 'Почему-то я так и думал #😉#! Скажи, а с кем или с чем тебе бы хотелось работать?',
                        smiles: true,
                        type: 'radio',
                        answers: [
                            {
                                name: 'с людьми',
                                value: 'a'
                            },
                            {
                                name: 'с машинами и механизмами',
                                value: 'b'
                            },
                            {
                                name: 'с объектами природы',
                                value: 'c'
                            },
                        ],
                        continueText: 'Всё интереснее! \n' +
                            'Нажимай, и мы продолжим'
                    },
                    {
                        id: 'q28',
                        body: '',
                        purpleBody: 'Представь себя стоящим на огромной сцене#✨#, и перед тобой — президент России, который вручает тебе награду#🤝# Как ты думаешь, за что она будет?',
                        type: 'radio',
                        smiles: true,
                        answers: [
                            {
                                name: 'за общественную деятельность',
                                value: 'a'
                            },
                            {
                                name: 'за научные открытия',
                                value: 'b'
                            },
                            {
                                name: 'за прорыв в искусстве',
                                value: 'c'
                            },
                        ],
                        continueText: 'Переходим дальше'
                    },

                    {
                        id: 'q29',
                        body: '',
                        purpleBody: 'Да будет так! Будь смелым и не бойся ставить перед собой высокие цели #😉#  Главное — любить свою профессию. Какая из предложенных профессий тебе подходит больше?',
                        smiles: true,
                        type: 'radio',
                        answers: [
                            {
                                name: 'механик',
                                value: 'a'
                            },
                            {
                                name: 'спасатель',
                                value: 'b'
                            },
                            {
                                name: 'программист',
                                value: 'c'
                            },
                        ],
                        continueText: 'Переходим дальше'
                    },

                    {
                        id: 'q30',
                        body: '',
                        purpleBody: 'А что насчёт собственного бизнеса#🤓#? Ты бы хотел стать предпринимателем?',
                        type: 'radio',
                        smiles: true,
                        answers: [
                            {
                                name: 'нет, это очень сложно и ответственно',
                                value: 'a'
                            },
                            {
                                name: 'да, я люблю рисковать и готов к этой ответственности',
                                value: 'b'
                            },
                            {
                                name: 'мне это неинтересно',
                                value: 'c'
                            },
                        ],
                        continueText: 'Засчитано! Переходим дальше'
                    },


                    {
                        id: 'q31',
                        body: '',
                        purpleBody: 'Представь, что ты открыл свой бизнес, и он успешен#✨# В твоём подчинении более 1000 сотрудников. О чём бы ты заботился в первую очередь, будучи руководителем#🤔#?',
                        type: 'radio',
                        smiles: true,
                        answers: [
                            {
                                name: 'о защите интересов и условий труда сотрудников',
                                value: 'a'
                            },
                            {
                                name: 'о достижениях и результатах компании',
                                value: 'b'
                            },
                            {
                                name: 'о материальном благополучии сотрудников',
                                value: 'c'
                            },
                        ],
                        continueText: 'Засчитано! Переходим дальше'
                    },


                    {
                        id: 'q32',
                        body: '',
                        purpleBody: 'Мне всего 2 года, и я ещё в поисках  жизни #😉#\nПоделись своим мнением: что в жизни самое главное?',
                        type: 'radio',
                        smiles: true,
                        answers: [
                            {
                                name: 'иметь возможность заниматься творчеством',
                                value: 'a'
                            },
                            {
                                name: 'помогать людям',
                                value: 'b'
                            },
                            {
                                name: 'прогнозировать последствия своих поступков',
                                value: 'c'
                            },
                        ],
                        continueText: 'Молодец! Переходим дальше'
                    },
                    {
                        id: 'q33',
                        body: '',
                        purpleBody: 'Спасибо за твои ответы на все вопросы в этой миссии#🤗# Теперь я ещё лучше понимаю твои карьерные стремления. А как насчёт самой миссии? Оцени, насколько она была для тебя интересной #😉#',
                        type: 'response',
                        smiles: true,
                        answers: [
                            {
                                name: 'like',
                                value: require('@/assets/imgs/emoji/like.svg')
                            },
                            {
                                name: 'fire',
                                value: require('@/assets/imgs/emoji/fire.svg')
                            },
                            {
                                name: 'dislike',
                                value: require('@/assets/imgs/emoji/dislike.svg')
                            },
                        ],
                        continueText: 'Спасибо! Нажимай и продолжим'
                    },

                ]
            },
            {
                missionId: 3,
                questions: [
                    {
                        id: 'q36',
                        body: '',
                        purpleBody: 'Давай представим: мы собрались вместе, чтобы организовать большой праздник#🎉#\nИ у каждого из нас будет своя задача. \n' +
                            'Какие бы задачи ты взял на себя#📝#?',
                        smiles: true,
                        type: 'checkbox',
                        answers: [
                            {
                                name: 'скорее всего, я бы стал инициатором вечеринки и взял ответственность за всю организацию – мне это нравится',
                                value: 'A'
                            },

                            {
                                name: 'взял бы на себя одну функцию и качественно с ней справился (организовал музыку, занялся едой, взял на себя оформление и т.п)',
                                value: 'B'
                            },

                            {
                                name: 'предлагал бы креативные идеи, как лучше организовать вечеринку, но реализовывать своими руками не стал – мне больше нравится придумывать, чем реализовывать',
                                value: 'C'
                            },

                            {
                                name: 'я бы много шутил и поднимал настроение компании, люблю создавать позитивную атмосферу',
                                value: 'D'
                            },

                            {
                                name: 'я бы следил за работой других и давал советы для улучшения качества вечеринки, но технически сам не принимал бы участие',
                                value: 'E'
                            },

                        ],
                        continueText: 'Принято! \n' +
                            'Переходим к следующему вопросу'
                    },
                    {
                        id: 'q37',
                        body: '',
                        purpleBody: 'Отлично! Тогда все остальные пункты я возьму на себя#🤝# Расскажи мне, что тебя больше всего мотивирует быть в числе организаторов нашего праздника?',
                        type: 'checkbox',
                        smiles: true,
                        answers: [
                            {
                                name: 'получить материальную выгоду, когда берёшь на себя много обязанностей, не бесплатно же это делать',
                                value: 'A'
                            },

                            {
                                name: 'из-за друзей – мне нравится наша тусовка и неважно, что именно мы при этом делаем, главное – вместе',
                                value: 'B'
                            },

                            {
                                name: 'я получаю удовольствие от процесса организации, это меня вдохновляет',
                                value: 'C'
                            },

                            {
                                name: 'для опыта: если есть идея – надо реализовывать, а если есть ещё отзывы и будут благодарности – вообще огонь',
                                value: 'D'
                            },

                            {
                                name: 'мне нравятся необычные, тематические праздники и атмосфера на них, например: Международный день тигра, во-первых – мне нравится этот зверь, а во-вторых – это необычный праздник, который даёт возможность обращать внимание на уникальных животных',
                                value: 'E'
                            },

                        ],
                        continueText: 'Интересный выбор! Скорее жми на кнопку!'
                    },
                    {
                        id: 'q38',
                        body: '',
                        purpleBody: 'Мы могли бы быть отличной командой#😉# А теперь давай поставим оценку этой миссии.',
                        smiles: true,
                        type: 'response',
                        answers: [
                            {
                                name: 'like',
                                value: require('@/assets/imgs/emoji/like.svg')
                            },
                            {
                                name: 'fire',
                                value: require('@/assets/imgs/emoji/fire.svg')
                            },
                            {
                                name: 'dislike',
                                value: require('@/assets/imgs/emoji/dislike.svg')
                            },
                        ],
                        continueText: 'Спасибо! Нажимай и продолжим'
                    },
                ]
            },
            {
                missionId: 4,
                questions: [
                    {
                        id: 'q40',
                        body: null,
                        purpleBody: 'Начинаем 4-ю миссию#🤗#! \n' +
                            'Я уже знаю компании, которые тебе подходят#😉#\n' +
                            'Сейчас я расскажу подробнее, чем они занимаются, а ты выбирай те, что тебе ближе#🤝#\n',
                        type: 'company',
                        smiles: true,
                        answers: null,
                        continueText: 'Нажимай, и мы продолжим'

                    },
                    {
                        id: 'q40',
                        body: null,
                        purpleBody: null,
                        type: 'company',
                        answers: null,
                        continueText: 'Переходим дальше'

                    },
                    {
                        id: 'q40',
                        body: null,
                        purpleBody: null,
                        type: 'company',
                        answers: null,
                        continueText: 'Супер! Продолжаем дальше'

                    },
                    {
                        id: null,
                        body: null,
                        purpleBody: 'А ты знаешь, что эти компании могут дать тебе целевое направление на обучение#😉#?\nЧто это значит? Рассказываю: ты сможешь учиться бесплатно, получить диплом, а потом трудоустроиться#🤝# По-моему, это отличная возможность#🤗#! ',
                        type: 'none',
                        smiles: true,
                        answers: null,
                        continueText: 'Продолжить'

                    },
                    {
                        id: 'q42',
                        body: null,
                        purpleBody: 'Ну и традиционно – я жду твою оценку#😉#',
                        type: 'response',
                        smiles: true,
                        answers: [
                            {
                                name: 'like',
                                value: require('@/assets/imgs/emoji/like.svg')
                            },
                            {
                                name: 'fire',
                                value: require('@/assets/imgs/emoji/fire.svg')
                            },
                            {
                                name: 'dislike',
                                value: require('@/assets/imgs/emoji/dislike.svg')
                            },
                        ],
                        continueText: 'Спасибо! Нажимай и продолжим'
                    },
                ]
            },
            {
                missionId: 5,
                questions: [
                    {
                        id: 'q45',
                        body: null,
                        purpleBody: 'Пока ты смотрел видео, я подбирал для тебя подходящие профессии#🤗#, сейчас я буду предлагать тебе их описания, а ты выбирай то, которое тебе больше всего понравится#😉#',
                        type: 'company',
                        smiles: true,
                        answers: null,
                        continueText: 'Переходим дальше'
                    },
                    {
                        id: 'q45',
                        body: null,
                        purpleBody: null,
                        type: 'company',
                        answers: null,
                        continueText: 'Продолжить'
                    },
                    {
                        id: 'q45',
                        body: null,
                        purpleBody: null,
                        type: 'company',
                        answers: null,
                        continueText: 'Двигаемся дальше!'
                    },
                    // {
                    //     id: 'q47',
                    //     body: null,
                    //     purpleBody: `Знаешь, на основе всех твоих ответов, я могу с уверенностью сказать: Твои способности на ${calculateUniqueness()}% выше, чем у многих взрослых, представляешь?! #😉# Мне очень понравилось с тобой работать и общаться! Подскажи, пожалуйста, а насколько тебе было комфортно со мной? Оцени меня и мою работу по 5-балльной шкале, где 1 — совсем не понравилось, а 5 — было очень интересно и полезно! Это поможет мне стать еще лучше #💪🏻#`,
                    //     smiles: true,
                    //     type: 'rating',
                    //     answers: [
                    //         {
                    //             name: '1',
                    //             value: '1'
                    //         },
                    //         {
                    //             name: '2',
                    //             value: '2'
                    //         },
                    //         {
                    //             name: '3',
                    //             value: '3'
                    //         },
                    //         {
                    //             name: '4',
                    //             value: '4'
                    //         },
                    //         {
                    //             name: '5',
                    //             value: '5'
                    //         },
                    //     ],
                    //     continueText: 'Спасибо! Нажимай, и продолжим'
                    // },
                    {
                        id: 'q48',
                        body: null,
                        purpleBody: 'С тобой время летит незаметно, вот и подошла к концу пятая миссия#🎉#! Как настроение?  ',
                        type: 'response',
                        smiles: true,
                        answers: [
                            {
                                name: 'like',
                                value: require('@/assets/imgs/emoji/like.svg')
                            },
                            {
                                name: 'fire',
                                value: require('@/assets/imgs/emoji/fire.svg')
                            },
                            {
                                name: 'dislike',
                                value: require('@/assets/imgs/emoji/dislike.svg')
                            },
                        ],
                        continueText: 'Спасибо! Двигаемся дальше'
                    },
                    // {
                    //     id: 'q51',
                    //     body: null,
                    //     purpleBody: 'Мы так много с тобой успели обсудить, что ты наверняка сможешь оставить свой отзыв о прохождений всех этапов! \n' +
                    //         'Что больше всего тебе понравилось?',
                    //     type: 'open',
                    //     answers: null,
                    //     continueText: 'Спасибо! Продолжаем дальше'
                    // },

                ]
            },
        ],
        missionPassing: {
            missionCount: null,
            answers: {}
        }
    },
    getters: {
        missions: state => state.missions,
        missionPassing: state => state.missionPassing,
        smilesList: () => ['💪🏻', '💪🏼', '😉', '📝', '🌇', '🤔', '🤗', '🏡', '🤓', '✅', '👨‍💻', '✨', '🎬', '🎥', '📺', '🤓', '🤩', '📱', '🤝', '🎉',]
    }


}

// function calculateUniqueness() {
//     return Math.floor(Math.random() * 7) + 76;
// }


export function accessToMission(id, missions) {
    return ((id === 1 && !missions.missionOne) ||
        (id === 2 && missions.missionOne && !missions.missionTwo) ||
        (id === 3 && missions.missionOne && missions.missionTwo && !missions.missionThree) ||
        (id === 4 && missions.missionOne && missions.missionTwo && missions.missionThree && !missions.missionFour) ||
        (id === 5 && missions.missionOne && missions.missionTwo && missions.missionThree && missions.missionFour && !missions.missionFive))
}





