import {makeDeleteReq, makeGetReq, makePostReq} from "@/store/helpers/request";
import {appConfig} from "@/lib/appConfig";
import {defaultHttpResHandler} from "@/store/helpers/defaultHttpResHandler";


export const fetchCalendarEvents = async (token, month, months, openEndedEvents) => await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/student/events/${month}?months=${openEndedEvents || !months ? '0,1,2,3,4,5,6,7,8,9,10,11' : months}`, {token}))
export const deleteCalendarEvent = async (token, data) => await defaultHttpResHandler(async () => await makeDeleteReq(`${appConfig.domain}/student/events`, data, {
    token,
    data: true
}))

export const sendEventClickInfo = async (token, data) => await defaultHttpResHandler(async () => await makePostReq(`${appConfig.domain}/student/click_event`, data, {
    token,
    data: true
}))
