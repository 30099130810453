<template>
  <div class="authorization">
    <form class="authorization-wrapper small-container">
      <h1 class="authorization__title">Вход</h1>
      <div class="authorization__element">
        <input type="text" placeholder="Логин (email)" id="login" class="authorization__element-input"
          v-model.trim="login" @keydown.enter="submitAuthorization" />
        <label for="login"></label>
      </div>
      <div class="authorization__element">
        <label class="password-wrapper">
          <input autocomplete="current-password" placeholder="Пароль" class="authorization__element-input"
            v-model.trim="password" id="password" @keydown.enter="submitAuthorization"
            :type="viewPassword ? 'text' : 'password'" />
          <button class="eye-wrapper" type="button" @click="viewPassword = !viewPassword"
            :class="{ 'eye-open': viewPassword }">
          </button>
        </label>
      </div>

      <button @click="submitAuthorization" type="button" class="authorization__send">
        <p class="white-btn-text purple-text">Продолжить</p>
      </button>

      <button type="button" @click="goToRegistration" class="authorization__registration">
        <p class="white-btn-text fw700">Регистрация</p>
      </button>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "StudentLoginPage",
  setup() {
    const router = useRouter();
    const store = useStore();
    const viewPassword = ref(false);
    const login = ref(null);
    const password = ref(null);

    const submitAuthorization = () =>
      store.dispatch("submitAuthorization", {
        login: login.value,
        password: password.value,
        role: "student",
      });

    scroll(0, 0);

    return {
      submitAuthorization,
      goToRegistration: () => router.push({ name: "registration" }),
      login,
      password,
      viewPassword
    };
  },
};
</script>

<style scoped>
.authorization {
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding: 2rem 1rem;
}

.small-container {
  max-width: 45.75rem;
  width: 100%;
  margin: 0 auto;
}

.authorization-wrapper {
  padding: 3rem 4rem;
  display: flex;
  flex-direction: column;
  border-radius: 2.1875rem;
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  align-items: center;
}

.authorization__title {
  font-size: 3.125rem;
  text-align: center;
}

.authorization__element {
  margin-top: 1.9rem;
  max-width: 100%;
  width: 100%;
}

.password-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 0 0.81rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}

#password {
  padding: 0;
  border: none;
}

.authorization__element-input {
  background: none;
  color: #fff;
  border-radius: 0;
  padding: 0 0 0.81rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  transition: all 1s ease-in-out;
  font-size: 1.125rem;
  max-width: 100%;
  width: 100%;
}

.authorization__element-input:focus {
  border-bottom: 1px solid #fff;
}

.authorization__element-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.authorization__send {
  margin-top: 2rem;
  max-width: 17rem;
  width: 100%;
  background: #fff;
  padding: 1rem;
  border-radius: 2rem;
  font-size: 1.5em;
  font-weight: 700;
}

.authorization__registration {
  margin-top: 1rem;
  max-width: 17rem;
  width: 100%;
  padding: 1rem;
  border-radius: 2rem;
  font-size: 1.5em;
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
}

.eye-wrapper {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  flex-shrink: 0;
  background: url('@/assets/imgs/eye.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.eye-open {
  background: url('@/assets/imgs/eye-close.svg');
  background-size: contain;
  background-position: center;
}

.eye {
  display: flex;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  position: relative;
  z-index: 2
}

button {
  text-align: center;
}

@media (max-width: 700px) {
  .authorization-wrapper {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    border-radius: 2.1875rem;
    background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
    align-items: center;
  }

  input {
    font-size: 16px;
  }
}
</style>
