<template>
  <div class="filling-8">
    <img src="@/assets/imgs/video/bg-2-2.png" alt="" class="bg-8" />
    <div class="content-8">
      <p class="title-8">Мира, тебе подойдут такие профессии</p>
      <div class="description-8">
        <div class="roles-8">
          <div class="roles-element-8">
            <img
              src="@/assets/imgs/video/med.webp"
              alt=""
              class="roles-img-8"
            />
            <p class="roles-text-8">Хирург</p>
          </div>
          <div class="roles-element-8">
            <img
              src="@/assets/imgs/video/med.webp"
              alt=""
              class="roles-img-8"
            />
            <p class="roles-text-8">Менеджер по подбору персонала</p>
          </div>
          <div class="roles-element-8">
            <img
              src="@/assets/imgs/video/med.webp"
              alt=""
              class="roles-img-8"
            />
            <p class="roles-text-8">Стилист</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PresentationElement8",
};
</script>

<style scoped>
.filling-8 {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  height: 100vh;
  height: 100dvh;
}

.description-8 {
  margin-top: 180px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.bg-8 {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.content-8 {
  max-width: 100%;
  width: 100%;
  padding: 100px;
  position: relative;
  height: 100%;
}

.title-8 {
  text-align: center;
  font-size: 55px;
  font-weight: 700;
  line-height: 143.5%;
  letter-spacing: 3.3px;
  text-transform: uppercase;
  text-align: left;
}

.roles-8 {
  position: relative;
  z-index: 2;

  display: flex;
  gap: 200px;
  justify-content: center;
}

.roles-img-8 {
  width: 186px;
}

.roles-element-8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 295px;
}

.roles-text-8 {
  margin-top: 23px;
  color: #fff;
  text-align: center;
  font-size: 33px;
  line-height: 143.5%;
  letter-spacing: 2.88px;
}
</style>
