<template>
  <header class="header">
    <div class="header-filling container desktop">
      <router-link v-if="$route.name === 'missionPass'" :to="{ name: 'missions' }" href="#" class="logo-wrapper"
                   :class="{ 'personal-area__logo': $route.name === 'personal-area' }">
        <img src="@/assets/imgs/logo.png" alt="" class="logo"/>
        ЦИФРОВОЙ ПОМОЩНИК
      </router-link>
      <router-link v-else :to="{ name: 'landing' }" href="#" class="logo-wrapper"
                   :class="{ 'personal-area__logo': $route.name === 'personal-area' }">
        <img src="@/assets/imgs/logo.png" alt="" class="logo"/>
        ЦИФРОВОЙ ПОМОЩНИК
      </router-link>
    </div>
    <div class="header-navigation mobile container" v-if="token">
      <router-link :to="{ name: 'statistics' }"
                   :class="{ 'navigation__link-active': $route.name === 'statistics' || $route.name === 'missions' }"
                   class="navigation__link">
        <img src="@/assets/imgs/menu-profile.svg" alt="" class="navigation__link-icon"/>
      </router-link>
      <router-link :to="{ name: 'profile' }" :class="{ 'navigation__link-active': $route.name === 'profile' }"
                   class="navigation__link">
        <img src="@/assets/imgs/menu-settings.svg" alt="" class="navigation__link-icon"/>
      </router-link>
      <button @click="setPaymentByScoreModal" :class="{
        'navigation__link-active ': setPaymentByScoreModal,
        'navigation__link--disabled': routeBlocked
      }" class="navigation__link ">
        <img src="@/assets/imgs/menu-cash.svg" alt="" class="navigation__link-icon"/>
      </button>
      <router-link :to="{ name: 'assistant' }" class="navigation__link " :class="{
        'navigation__link-active': $route.name === 'assistant',
        'navigation__link--disabled': routeBlocked
      }">
        <img src="@/assets/imgs/menu-chat.svg" alt="" class="navigation__link-icon"/>
      </router-link>
      <button class="navigation__link" @click="logout">
        <img src="@/assets/imgs/menu-exit.svg" alt="" class="navigation__link-icon"/>
      </button>
    </div>
  </header>
</template>

<script>
import {computed, ref} from "vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {allMissionPassed} from "@/router/helpers";

export default {
  name: "PageHeader",
  setup() {
    const store = useStore();

    const router = useRouter();

    const logout = () => store.dispatch("logout");

    const token = computed(() => store.getters.token);

    return {
      router,
      routeBlocked: computed(() => !allMissionPassed()),

      viewPassing: ref(false),
      logout,
      viewPaymentByScoreModal: computed(() => store.getters.viewPaymentByScoreModal),
      setPaymentByScoreModal: () => {
        store.commit("setPaymentByScoreModal", true)
      },
      token
    };
  },
};
</script>

<style scoped>
.header {
  display: flex;
  max-width: 100%;
  width: 100%;
  background: #121317;

  border-bottom: 1px solid #7c808b;
  position: sticky;
  top: 0;
  z-index: 6;
}

.header-filling {
  display: flex;
  align-items: center;
  padding: 1.5rem 1.75rem;
  justify-content: space-between;
  align-items: center;
}

.logo-wrapper {
  margin-right: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: normal;
  color: rgba(229, 189, 255, 0.8);
}

.logo {
  width: 4.43006rem;
  height: 3.1875rem;
}

.log-out {
  color: rgba(255, 255, 255, 0.5);
  padding: 1rem 2rem;
  border-radius: 1.25rem;
  background: rgba(223, 230, 244, 0.2);
}

@media (max-width: 850px) {

  .header-navigation {
    background: #121418;
    backdrop-filter: blur(12px);
  }

  .header {
    border-bottom: none;
  }

  .header-navigation {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
  }

  .navigation__link {
    border-radius: 0.5rem;
    width: 2rem;
    height: 2rem;
  }

  .navigation__link-active {
    background: url("@/assets/imgs/menu-active.svg");
    background-size: cover;
  }

  .navigation__link-icon {
    width: 100%;
    height: 100%;
  }

  .navigation__link--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

/* Modifiers */
.personal-area__logo {
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
}
</style>
