<template>
  <div class="block stated" id="certificate" :class="{'train-selected':educationStep===1}">
    <div class="block-filling block-filling__stated" :class="{ 'block-filling__stated-open': viewMore }">
      <div class="block-caption">
        <img src="@/assets/imgs/statistics/about.svg" alt="" class="block-caption__icon"/>
        <div class="clue">
          <h3 class="subtitle">Заяви о себе</h3>
          <div class="clue__content">
            <p class="clue__title">Блок Заяви о себе</p>
            <p class="clue__text">Поделись с друзьями своим профессиональным аватаром, скачай сертификат о прохождении и
              уникальную информацию о тебеиз личного кабинета.</p>
          </div>
        </div>
      </div>
      <div class="block-content stated-content">
        <div class="stated-element" id="video">
          <p class=" stated__text">Дашборд</p>
          <router-link style="pointer-events: none" target="_blank" :to="{ name: 'videoBrowser' }"
                       class="stated__img-wrapper video">
            <img src="@/assets/imgs/statistics/stated-img-dashboard.png" alt="" class="stated__img stated__img-1"/>
          </router-link>
          <button class="statistics__btn" @click="authorizationVk">
            <img src="@/assets/imgs/share.svg" alt="" class="statistics__btn-icon">
            Поделиться
          </button>

        </div>
        <div class="stated-element">
          <p class=" stated__text">Сертификат </p>
          <div class="stated__img-wrapper">
            <img src="@/assets/imgs/statistics/stated-img-sert.png" alt="" class="stated__img stated__img-2"/>
          </div>
          <button @click="downloadCertificate" class="statistics__btn">
            Смотреть
          </button>
        </div>
        <div class="stated-element">
          <p class=" stated__text">Презентация</p>
          <div class="stated__img-wrapper">
            <img src="@/assets/imgs/statistics/stated-img-pres.png" alt="" class="stated__img stated__img-3"/>
          </div>
          <button class="statistics__btn" @click="downloadPresentation">
            Смотреть
          </button>
        </div>
      </div>
      <div class="block-shadow" :class="{ 'block-shadow-close': viewMore }"></div>
    </div>
    <div class="block-end">
      <button class="block-end__btn" @click="viewMore = !viewMore"
              :class="{ 'block-end__btn-open': viewMore }"></button>
    </div>
  </div>
</template>

<script>
import {computed, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {appConfig} from "@/lib/appConfig";
import {Config, Auth} from "@vkid/sdk"

export default {
  props: ['educationStep'],
  name: "StatedBlock",
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const certificate = ref(null);
    const presentation = ref(null);
    const viewMore = ref(false);

    const userData = computed(() => store.getters.userData);

    const downloadVideo = () => router.push({name: 'videoBrowser'});

    const downloadCertificate = () => {
      store.dispatch('downloadCertificate');
    };

    const downloadPresentation = () => {
      store.dispatch('downloadPresentation');
    };

    const viewShareVkModal = () => {
      store.commit('setViewVkShareModal', true);
    }

    watch(() => props.educationStep, (val) => {
      viewMore.value = (val === 1);
    });

    // watch(() => viewMore.value, (val) => {
    //   if (val) {
    //     if (!appConfig.appState.free && userData.value?.status !== 'account_paid' && props.educationStep === null) {
    //       viewMore.value = false;
    //       store.commit('setPaymentByMoneyModal', true);
    //     }
    //   }
    // });


    Config?.set({
      app: appConfig.vkClientId,
      redirectUrl: appConfig.getVkRedirectUrl(),
    });

    return {
      viewShareVkModal,
      downloadVideo,
      downloadCertificate,
      downloadPresentation,
      certificate,
      presentation,
      viewMore,
      userData,
      authorizationVk() {
        Auth?.login();
      },
      appConfig
    };
  }
};
</script>

<style scoped>
.block {
  background: #21262e;
  border-radius: 2rem;
  padding-bottom: 1.5rem;
  scroll-margin-top: 150px
}

.block-caption {
  display: flex;
  align-items: center;
  gap: 1.13rem;
}

.block-filling {
  position: relative;
  background: linear-gradient(180deg, rgba(33, 38, 46, 0) 0%, #21262e 100%);
  padding: 1.88rem 1.88rem 1.69rem 1.88rem;
  transition: max-height 2s ease-in-out;
  height: auto;
  overflow: hidden;
}

.block-caption__icon {
  display: flex;
  flex-shrink: 0;
}

.subtitle {
  background-color: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  background-image: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
}

.statistics__btn {
  margin-top: 1rem;
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.62rem;
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: #fff;
  border-radius: 0.625rem;
  background: linear-gradient(92deg, #AD4FEA 4.41%, #4A58DD 72.27%);
  line-height: normal;
}

.statistics__btn-icon {
  width: 1.5rem;

}

.block-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  z-index: 2;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(33, 38, 46, 0) 0%, #21262e 100%);
  transition: opacity 1s ease-in-out;
}

.block-shadow-close {
  opacity: 0;
  pointer-events: none;
}

.block-end {
  display: flex;
  justify-content: center;
}

.block-end__btn {
  margin: 0 auto;
  width: 2.90275rem;
  height: 1.1875rem;
  background: url("@/assets/imgs/statistics/arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.block-end__btn-open {
  transform: rotate(180deg);
}

.stated-content {
  margin-top: 2.4rem;
  display: flex;
  gap: 1rem;
}

.stated-element {
  max-width: 100%;
  width: 100%;
}


.stated__text {
  color: #FFF;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-bottom: 1rem;
}

.stated__img-1 {
  width: 7.6875rem;
}

.stated__img-2 {
  width: 7.5625rem;
}

.stated__img-3 {
  width: 6.125rem;
}

.stated__img {
  width: auto;
  object-fit: contain;
  object-position: center;
}


.stated__img-wrapper {
  height: 16rem;
  background: linear-gradient(180deg, rgba(16, 18, 21, 0.67) 10.35%, #21262E 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(26.780780792236328px);
  border: 2px solid #AD4FEA;
  border-radius: 10px;
  flex-direction: column;
  text-align: center;
}

.stated-element__text {
  margin-top: 1.31rem;
  color: #FFF;
  font-size: 0.875rem;
  line-height: 140%;
}


.video {
  position: relative;
  cursor: pointer;
}

.video:hover {
  opacity: 1;
}


.block-filling__stated {
  max-height: 27rem;
}

.block-filling__stated-open {
  max-height: 37rem;
}

@media (max-width: 1600px) {
  .stated__img-wrapper {
    height: 13rem;
  }

  .stated__img {
    max-width: 4rem;
  }

  .stated-element__text {
    font-size: 0.75rem;
  }
}

@media (max-width: 850px) {
  .block {
    border-radius: 0.625rem;
    padding-bottom: 1rem;
  }

  .block-caption {
    display: flex;
    align-items: center;
    gap: 1.13rem;
  }

  .block-filling {
    padding: 1.63rem 1rem 0.87rem 1rem;
  }

  .block-caption__icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .subtitle {
    font-size: 1.375rem;
  }

  .stated-content {
    margin-top: 1.53rem;
    gap: 3rem;
    flex-direction: column;
  }

  .statistics__btn {
    margin-top: 1.5rem;
    padding: 0.59rem;
    font-size: 0.875rem;
  }

  .block-shadow {
    border-radius: 0.625rem;
  }

  .block-end {
    display: flex;
    justify-content: center;
  }

  .block-end__btn {
    width: 2.40275rem;
    height: 1rem;
  }

  .stated__text {
    font-size: 1.125rem;
    line-height: 1;
    height: unset;
    margin-bottom: 0;
  }


  .stated__img-wrapper {
    margin-top: 1.16rem;
    height: 14.5rem;
  }

  .video-link {
    bottom: 0.5rem;
    opacity: 1;
  }

  .stated__img-1 {
    width: 5.125rem;
  }

  .stated__img-2 {
    width: 5.163rem;
  }

  .stated__img-3 {
    width: 4.65rem;
  }

  .stated__share {
    margin-top: 1.19rem;
    padding: 0;
    height: 1.87rem;
    gap: 0.5rem;
    font-size: 0.875rem;
  }

  .stated__share-img {
    width: 0.95419rem;
    height: 0.95419rem;
  }

  .block-filling__stated {
    max-height: 27rem;
  }

  .block-filling__stated-open {
    max-height: 200rem;
  }
}
</style>
