import {useToast} from "vue-toastification";
import store from "@/store";
import Cookies from "js-cookie";
import router from "@/router";

const toast = useToast();

const notification = {
    params: {
        position: "top-center",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false
    },
    successNotification(app, msg) {
        toast.success(msg, this.params);
    },
    errorNotification(app, msg) {
        toast.error(msg, this.params);
    },
    warningNotification(app, msg) {
        toast.warning(msg, this.params);
    },
    infoNotification(app, msg) {
        toast.info(msg, this.params);
    }
};

export function errorHandler(event) {


    if (event?.status === 401 || event?.status === 403 || event?.status === 400) {
        switch (event?.data?.statusCode) {
            case 'InvalidPasswordError':
                notification.infoNotification(this, 'Неверный пароль');
                break;
            case 'InvalidEmailError':
                notification.infoNotification(this, 'Неверный логин');
                break;
            case 'EmailAlreadyExistsError':
                if (event?.isPayment) {
                    notification.infoNotification(this, 'Аккаунт с такой почтой существует');
                }
                break;
            case 'PaymentError':
                break;
            default:
                store.dispatch('logout').then();
        }
    }


    switch (event.name) {

        case 'vkPostCreated': {
            if (event.status === 200) notification.successNotification(this, 'Запись опубликована на вашей странице ВК');
            else notification.warningNotification(this, 'Не удалось опубликовать запись в ВК');
            break;
        }

        case 'customMessage': {
            if (event?.message)
                notification.infoNotification(this, event?.message);
            break;
        }

        case 'userAgreementPrivacy':
            notification.infoNotification(this, 'Необходимо дать согласие на обработку персональных данных, принять условия пользовательского соглашения и Политики обработки персональных данных.');
            break;

        case 'emptyReview':
            notification.infoNotification(this, 'Необходимо поставить оценку и оставить свой отзыв');
            break;

        case 'emailError':
            notification.infoNotification(this, 'Ошибка почтового клиента. Обратитесь в тех. поддержку');
            break;

        case 'notAllData':
            notification.infoNotification(this, 'Не все данные данные заполнены');
            break;

        case 'accountPaid':
            notification.successNotification(this, 'Ваш аккаунт оплачен');
            break;

        case 'InvalidField':
            switch (event.field) {
                case 'name':
                    notification.infoNotification(this, 'Невалидное имя');
                    break;
                case 'email':
                    notification.infoNotification(this, 'Невалидный email');
                    break;
                case 'phone':
                    notification.infoNotification(this, 'Невалидный номер телефона');
                    break;
                case 'cyrillic':
                    notification.infoNotification(this, 'В ответе не должна присутствовать кириллица');
                    break;
                case 'birth':
                    notification.infoNotification(this, 'Некорректная дата рождения');
                    break;
                case 'birth_young':
                    notification.infoNotification(this, 'Тебе слишком рано пользоваться Цифровым помощником');
                    break;
            }
            break;
        case 'emailExist':
            notification.infoNotification(this, 'Кажется, с этого адреса уже кто-то заходил🤔. Если это твой адрес, то напиши в Техподдержку, либо дай мне другую электронку.');

            break;

        case 'registrationStudent':
            switch (event.status) {
                case 200:
                    notification.successNotification(this, 'Вы успешно зарегистрировались');
                    router.push({name: 'studentLogin'});
                    break;
                case  401:
                    notification.infoNotification(this, 'Аккаунт с таким email уже существует');
                    break;
                case 406:
                    notification.warningNotification(this, 'Не все данные заполнены');
                    break;
                case 500:
                    notification.errorNotification(this, 'Ошибка сервера');
                    break;
            }
            break;
        case 'loginStudent':
            switch (event.status) {
                case 200:
                    notification.successNotification(this, 'Вы успешно авторизовались');
                    router.push({name: 'studentAllMissions'});
                    break;
                case 401:
                    notification.warningNotification(this, 'Неверный логин или пароль');
                    break;
                case 406:
                    notification.warningNotification(this, 'Не все данные заполнены');
                    break;
                case 500:
                    notification.errorNotification(this, 'Ошибка сервера');
                    break;
            }
            break;
        case 'registrationTeacher':
            switch (event.status) {
                case 200:
                    notification.successNotification(this, 'Вы успешно зарегистрировались');
                    router.push({name: 'teacherLogin'});
                    break;
                case 406:
                    notification.warningNotification(this, 'Не все данные заполнены');
                    break;
                case  407:
                    notification.warningNotification(this, 'Пароли не совпадают');
                    break;
                case  401:
                    notification.warningNotification(this, 'Аккаунт с таким email уже существует');
                    break;
                case 500:
                    notification.errorNotification(this, 'Ошибка сервера');
                    break;
            }
            break;
        case 'teacherPassword':
            switch (event.status) {
                case 200:
                    notification.successNotification(this, 'Вы успешно авторизовались');
                    Cookies.set('token', event.params);
                    Cookies.set('role', 'TEACHER')
                    router.push({name: 'teacherCabinet'});
                    break;
                case 500:
                    notification.errorNotification(this, 'Ошибка сервера');
                    break;
            }
            break;
        case 'loginTeacher':
            switch (event.status) {
                case 200:
                    notification.successNotification(this, 'Вы успешно авторизовались');
                    router.push({name: 'teacherCabinet'});
                    break;
                case 401:
                    notification.warningNotification(this, 'Неверный логин или пароль');
                    break;
                case 406:
                    notification.warningNotification(this, 'Не все данные заполнены');
                    break;
                case 500:
                    notification.errorNotification(this, 'Ошибка сервера');
                    break;
            }
            break;
        case 'payment':
            switch (event.status) {
                case 200:
                    notification.successNotification(this, 'Тариф успешно оплачен');
                    router.push({name: 'studentCabinet'})
                    break;
                case 201:
                    notification.successNotification(this, 'Ваш тариф оплачен');
                    break;
                case 301:
                    notification.warningNotification(this, 'Обновите тариф для доступа к функционалу')
                    break;
                case 500:
                    notification.errorNotification(this, 'Ошибка сервера');
                    break;
            }
            break
        case 'saveUserData':
            switch (event.status) {
                case 200:
                    notification.infoNotification(this, 'Успешно');
                    break;
                case 500:
                    notification.warningNotification(this, 'Не удалось');
                    break;
            }
            break
        case 'event':
            switch (event.status) {
                case 200:
                    switch (event.params) {
                        case 'event':
                            notification.warningNotification(this, 'Вы отписались от события');
                            break;
                        case 'registered':
                            notification.successNotification(this, 'Вы зарегестрированы на мероприятие');
                            break;
                        case 'added':
                            notification.successNotification(this, 'Мероприятие занесенно в календарь');
                            break;
                        case 'registered_added':
                            notification.successNotification(this, ' Вы зарегестрированы на мероприятие и оно занесенно в календарь');
                            break;

                    }
                    break;
                case 500:
                    notification.errorNotification(this, 'Ошибка сервера');
                    break;
            }
            break;
        case 'chat':
            switch (event.status) {
                case 200:
                    notification.successNotification(this, 'Заявка успешно отправлена');
                    break;
                case 500:
                    notification.errorNotification(this, 'Ошибка');
                    break;
            }
            break;
        case 'actionNotStarted':
            notification.warningNotification(this, 'Акция начнется 5 сентября');
    }
}
