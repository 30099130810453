<template>
  <div class="block future" id="future" :class="{'train-selected':educationStep===2}">
    <div class="block-filling block-filling__future" :class="{ 'block-filling__future-open': viewMore }">
      <div class="block-caption block-caption__future">
        <img src="@/assets/imgs/statistics/future.svg" alt="" class="block-caption__icon"/>
        <div class="clue">
          <h3 class="subtitle">Моё будущее</h3>
          <div class="clue__content">
            <p class="clue__title">Блок Моё будущее</p>
            <p class="clue__text">Здесь ты можешь вдохновляться мечтами о своём будущем.</p>
          </div>
        </div>

      </div>
      <div class="block-content block-content__future">
        <div class="future-wrapper">
          <div class="future-element"
               v-for="(item, index) in futurePictures" :key="index">
            <div class="future-element-filling">

              <button class="future-element__img-wrapper" @click="pickFuture(item)">
                <div class="future-element-demonstration"></div>
                <img :src="item?.value" alt="" class="future-element__img"/>
                <p class="future-element__text">{{ item?.name }}</p>
              </button>
            </div>
          </div>
          <div class="future-modal" v-if="bigFuture">
            <div class="future-modal-filling">
              <button class="future-element-close" @click="pickFuture(null)">
                <img src="@/assets/imgs/close.svg" alt="" class="close"/>
              </button>
              <img :src="bigFuture?.value" alt="" class="future-modal__img">
              <p class="future-modal__text">{{ bigFuture?.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="company" id="company" :class="{'train-selected':educationStep===5}">
        <div class="block-caption block-caption__company">
          <img src="@/assets/imgs/statistics/company.svg" alt="" class="block-caption__icon"/>
          <div class="clue">
            <h3 class="subtitle">Компании для тебя</h3>
            <div class="clue__content">
              <p class="clue__title">Блок Компании для тебя</p>
              <p class="clue__text">Знакомься – здесь будущие варианты развития твоей карьеры. Здесь я расскажу тебе,
                где ты сможешь работать в будущем, какой уровень зарплаты тебя ждёт и кто может оплатить тебе обучение
                (дать целевой договор).</p>
            </div>
          </div>
        </div>
        <div class="block-content block-content__company">
          <div class="company-wrapper">
            <company-element v-for="item in studentStatistic?.companies" :key="item.id"
                             :company="item"></company-element>
          </div>
        </div>
      </div>
      <div class="robot-message-wrapper" v-if="false">
        <div class="robot-message">
          <img src="@/assets/imgs/statistics/robot-img.png" alt="" class="robot-message__img"/>
          <p class="robot-message__title">
            Подробнее расскажу о целевом обучении
          </p>
          <p class="robot-message__text">
            Меня создавали 531 работодатель и искусственный интеллект. Помогу
            тебе понять, в чем ты лучший, поставить цель и достигнуть ее.
            <br/>
            <br/>
            Для этого мы пройдем 5 миссий, прокачаем твоего аватара и узнаем,
            что ждет тебя в будущем. Получай монеты за каждую миссию и меняй их
            в конце на что-то интересное о себе.
            <br/>
            <br/>
            Начинаем 11 апреля!
          </p>
          <div class="robot-message-author">
            <img src="@/assets/imgs/statistics/robot-author.svg" alt="" class="robot-message-author__img"/>
            <p class="robot-message-author__text purple-text">
              Цифровой помощник
            </p>
          </div>
        </div>
      </div>
      <div class="more-company-wrapper">
        <div class="more-company">
          <div class="more-company-left">
            <p class="more-company-left__title">Открой больше</p>
            <p class="more-company-left__text">
              Посмотри все компании, чтобы лучше понимать рынок труда.
            </p>
          </div>
          <a href="https://profstories.ru/organizatsii" target="_blank" class="more-company__btn">Смотреть</a>
        </div>
      </div>
      <div class="professions" id="professions" :class="{'train-selected':educationStep===6}">
        <div class="block-caption block-caption__professions">
          <img src="@/assets/imgs/statistics/people.svg" alt="" class="block-caption__icon"/>
          <div class="clue">
            <h3 class="subtitle">Профессии для тебя</h3>
            <div class="clue__content">
              <p class="clue__title">Блок Профессии для тебя</p>
              <p class="clue__text">Смотри, какие профессии подходят именно тебе. Уверен, что в них ты сможешь раскрыть
                свой потенциал, принести пользу людям и добиться впечатляющих высот в карьере.</p>
            </div>
          </div>
        </div>
        <div class="block-content block-content__professions">
          <professions-element v-for="(item,index) in studentStatistic?.professions" :key="item.id" :index="index+1"
                               :profession="item"></professions-element>
        </div>
      </div>
      <div class="more-company-wrapper">
        <div class="more-company">
          <div class="more-company-left">
            <p class="more-company-left__title">Узнать больше</p>
            <p class="more-company-left__text">
              Посмотри всеx, чтобы лучше понимать рынок труда.
            </p>
          </div>
          <a href="https://profstories.ru/professii#sort=attr.featured.frontend_value&sortdir=desc" target="_blank"
             class="more-company__btn">Смотреть</a>
        </div>
      </div>
      <div class="block-shadow" :class="{ 'block-shadow-close': viewMore }"></div>
    </div>
    <div class="block-end">
      <button class="block-end__btn" @click="viewMore = !viewMore"
              :class="{ 'block-end__btn-open': viewMore }"></button>
    </div>
  </div>
</template>

<script>
import CompanyElement from "./CompanyElement.vue";
import ProfessionsElement from "./ProfessionsElement.vue";
import {computed, ref, watch} from "vue";
// import {useStore} from "vuex";
// import {appConfig} from "@/lib/appConfig";

export default {
  name: "FutureBlock",
  props: ["studentStatistic", 'educationStep'],
  components: {
    CompanyElement,
    ProfessionsElement,
  },
  setup(props) {


    // const store = inject("store") || useStore();

    const viewMore = ref(false);
    const futureOpen = ref(false);
    const bigFuture = ref(null);
    const futurePictures = computed(() =>
        props.studentStatistic?.yourFuturePicNames
            ?.split(",")
            .filter((item) => item)
            .map((future) =>
                [
                  {
                    name: "Автомобиль",
                    value: require("@/assets/imgs/missions/car.png"),
                  },
                  {
                    name: "Мотоцикл",
                    value: require("@/assets/imgs/missions/bike.png"),
                  },
                  {
                    name: "Дом",
                    value: require("@/assets/imgs/missions/house.png"),
                  },
                  {
                    name: "Квартира",
                    value: require("@/assets/imgs/missions/flat.png"),
                  },
                  {
                    name: "В России",
                    value: require("@/assets/imgs/missions/Russia.png"),
                  },
                  {
                    name: "За рубежом",
                    value: require("@/assets/imgs/missions/abroad.png"),
                  },
                  {
                    name: "Животные",
                    value: require("@/assets/imgs/missions/dog.png"),
                  },
                  {
                    name: "Растения",
                    value: require("@/assets/imgs/missions/plants.png"),
                  },
                  {
                    name: "В горах",
                    value: require("@/assets/imgs/missions/gori.jpg"),
                  },
                  {
                    name: "На море",
                    value: require("@/assets/imgs/missions/more.jpg"),
                  },
                  {
                    name: "Из дома",
                    value: require("@/assets/imgs/missions/udalenka.jpeg"),
                  },
                  {
                    name: "В офисе",
                    value: require("@/assets/imgs/missions/office.jpg"),
                  },
                ].find((item) => item?.name === future)
            )
    );

    // const userData = computed(() => store.getters.userData);

    const pickFuture = (name) => {
      bigFuture.value = bigFuture.value === name ? null : name;
    };


    watch(() => props.educationStep, (val) => {
      if (val === 2 || val === 5 || val === 6) {
        viewMore.value = true;
      } else {
        viewMore.value = false;
      }
    });

    // watch(() => viewMore.value, (val) => {
    //   if (val) {
    //     if (!appConfig.appState.free && userData.value?.status !== 'account_paid' && props.educationStep === null) {
    //       viewMore.value = false;
    //       store.commit('setPaymentByMoneyModal', true);
    //     }
    //   }
    // })

    return {
      viewMore,
      futurePictures,
      futureOpen,
      bigFuture,
      pickFuture,
    };
  },
};
</script>

<style scoped>
.block {
  background: #21262e;
  border-radius: 2rem;
  padding-bottom: 1.5rem;
  scroll-margin-top: 150px

}

.block-caption {
  display: flex;
  align-items: center;
  gap: 1.13rem;
}

.block-filling {
  position: relative;
  background: linear-gradient(180deg, rgba(33, 38, 46, 0) 0%, #21262e 100%);
  padding: 1.88rem 1.88rem 1.69rem 1.88rem;
  transition: max-height 2s ease-in-out;
  height: auto;
  overflow: hidden;
}

.block-caption__icon {
  display: flex;
  flex-shrink: 0;
}

.subtitle {
  background-color: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  background-image: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
}

.statistics__btn {
  margin-top: 0.5rem;
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.625rem;
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: #fff;
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  line-height: normal;
}

.block-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  z-index: 2;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(33, 38, 46, 0) 0%, #21262e 100%);
  transition: opacity 1s ease-in-out;
}

.block-shadow-close {
  opacity: 0;
  pointer-events: none;
}

.block-end {
  display: flex;
  justify-content: center;
}

.block-end__btn {
  margin: 0 auto;
  width: 2.90275rem;
  height: 1.1875rem;
  background: url("@/assets/imgs/statistics/arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.block-end__btn-open {
  transform: rotate(180deg);
}

.block-content__future {
  margin-top: 2.38rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.future-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.future-element {
  max-width: 4.9375rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.69rem;
}

.future-element__img {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 4.9375rem;
  object-position: center;
  object-fit: cover;
  border-radius: 100%;
}

.future-element:nth-child(3) .future-element-filling {
  right: 0;
  left: unset;
}

.future-element:nth-child(4) .future-element-filling {
  right: 0;
  left: unset;
}

.future-element__img-wrapper {
  position: relative;
}

.future-element__img-wrapper :hover {
  opacity: 1;
}

.future-element-demonstration {
  opacity: 0;
  transition: all 1s ease-in-out;
  background: url("@/assets/imgs/future-hover.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}


.close {
  width: 1.75rem;
  height: 1.75rem;
}

.future-element__img-wrapper :hover .future-element-demonstration {
  opacity: 1;
}

.future-element-open {
  position: relative;
  width: 4.9375rem;
  height: 100%;
}


.future-element-open .future-element__img-wrapper {
  pointer-events: none;
}

.future-element-open .future-element-demonstration {
  display: none;
}

.future-element-open .future-element__img {
  width: 18.0625rem;
  height: 18.0625rem;
}

.future-element-open .future-element-filling {
  padding: 20px;
  background: #101215;
  border-radius: 2rem;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.future-element__text {
  margin-top: 0.69rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  text-align: center;
}

.company {
  margin-top: 6.44rem;
  scroll-margin-top: 150px
}


.block-content__company {
  margin-top: 1.75rem;
}

.company-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.robot-message {
  margin-top: 1.25rem;
  padding: 0.75rem;
  border-radius: 0.625rem;
  background: #fff;
}

.robot-message__img {
  max-width: 100%;
  width: 100%;
}

.robot-message__title {
  margin-top: 0.5rem;
  color: #131313;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.625rem;
  letter-spacing: -0.0275rem;
}

.robot-message__text {
  margin-top: 1rem;
  color: rgba(19, 19, 19, 0.8);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
}

.robot-message-author {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.robot-message-author__img {
  width: 2.0625rem;
  height: 2.0625rem;
  display: flex;
  flex-basis: 0;
}

.robot-message-author__text {
  font-size: 0.6875rem;
}

.more-company {
  margin-top: 1.25rem;
  padding: 1.56rem 1.63rem;
  border-radius: 0.875rem;
  background: url("@/assets/imgs/statistics/more-company.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  gap: 1.9rem;
}

.more-company-left__title {
  font-size: 1.1875rem;
  font-weight: 700;
  line-height: 115%;
}

.more-company-left__text {
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 115%;
}

.more-company__btn {
  padding: 0.69rem 1.24rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  border: 1px solid #fff;
  border-radius: 0.5rem;
}

.professions {
  margin-top: 6.25rem;
  scroll-margin-top: 150px

}

.block-content__professions {
  margin-top: 1.67rem;
  display: flex;
  flex-direction: column;
  gap: 1.88rem;
}

.professions-element {
  padding: 1.75rem 1.57rem 1.31rem 1.57rem;
  border-radius: 1rem;
  background: rgba(98, 98, 98, 0.17);
  backdrop-filter: blur(32.584266662597656px);
}

.professions-element__text {
  font-size: 1.1875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.professions-element__btn {
  margin-top: 1.62rem;
}

.block-filling__future {
  max-height: 16rem;
}

.block-filling__future-open {
  max-height: 260rem;
}

.future-modal {
  position: fixed;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  padding: 2rem;
  left: 0;
  top: 0;
  display: flex;
  overflow: auto;
}

.future-modal-filling {
  margin: auto;
  max-width: 60vw;
  position: relative;
  width: 100%;

}

.future-modal__text {
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  padding: 0.94rem 2.5rem;
  position: absolute;
  left: 1.5rem;
  bottom: 1.5rem;
  color: #101215;
  background: #fff;
  border-radius: 0.5rem;
}

.future-element-close {
  top: 0;
  right: -4.5rem;
  position: absolute;

}

.future-modal__img {
  border: 0.25rem solid #AD4FEA;
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: 1.5rem;
  background: rebeccapurple;
}

.close {
  width: 3rem;
  height: 3rem;
}

@media (max-width: 1360px) {
  .future-element:nth-child(2) .future-element-filling {
    left: -6.3rem;
  }

  .future-element:nth-child(3) .future-element-filling {
    right: -4rem;
  }
}

@media (max-width: 1300px) {
  .future-element:nth-child(3) .future-element-filling {
    right: 0;
  }

  .future-element:nth-child(4) .future-element-filling {
    right: unset;
    left: 0;
  }

  .future-element-open .future-element__img {
    width: 15rem;
    height: 15rem;
  }

  .more-company {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}

@media (max-width: 1200px) {
  .future-element:nth-child(4) .future-element-filling {
    right: 0;
    left: unset;
  }

  .more-company {
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }
}

@media (max-width: 850px) {
  .block {
    border-radius: 0.625rem;
    padding-bottom: 1rem;
  }

  .block-caption {
    display: flex;
    align-items: center;
    gap: 1.13rem;
  }

  .block-filling {
    padding: 1.63rem 0 0.87rem 0rem;
  }

  .block-caption__icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .subtitle {
    font-size: 1.375rem;
  }

  .stated-content {
    margin-top: 1.53rem;
    gap: 3rem;
  }

  .statistics__btn {
    margin-top: 1.5rem;
    padding: 0.59rem;
    font-size: 0.875rem;
  }

  .block-shadow {
    border-radius: 0.625rem;
  }

  .block-end {
    display: flex;
    justify-content: center;
  }

  .block-end__btn {
    width: 2.40275rem;
    height: 1rem;
  }

  .block-caption__icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .block-filling__stated {
    max-height: 27rem;
  }

  .block-filling__stated-open {
    max-height: 70rem;
  }

  .block-content__future {
    position: relative;
    scrollbar-width: 0;
  }

  .future-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 1.33rem;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    padding: 0 1rem;
  }

  .future-element {
    width: 100%;
    align-items: center;
    flex-shrink: 0;
    max-width: 100%;
    position: unset;
  }

  .future-element-open .future-element-filling {
    left: 1rem;
    width: calc(100% - 2rem);
  }

  .future-element:nth-child(2) .future-element-filling {
    left: 1rem;
    right: unset;
  }

  .future-element:nth-child(3) .future-element-filling {
    left: 1rem;
    right: unset;
  }

  .future-element:nth-child(4) .future-element-filling {
    left: 1rem;
    right: unset;
  }

  .future-element-open .future-element__img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .future-element-open .future-element__img-wrapper {
    position: relative;
    width: 100%;
    padding-top: 100%;
  }

  .future-element__img {
    height: 6.625rem;
    width: 6.625rem;
  }

  .block-caption {
    padding: 0 1rem;
  }

  .block-caption__company {
    margin-top: 5.33rem;
  }

  .future-element__text {
    font-size: 1rem;
  }

  .block-content__company {
    position: relative;
    overflow: auto;
    scrollbar-width: 0;
  }

  .company-wrapper {
    padding: 0 1rem;
    flex-direction: row;
    overflow: auto;
  }

  .robot-message {
    padding: 0.62rem;
    margin-top: 0;
    border-radius: 0.625rem;
  }

  .robot-message-wrapper {
    margin-top: 2rem;
    padding: 0 1rem;
  }

  .robot-message__img {
    border-radius: 0.625rem;
  }

  .robot-message__title {
    margin-top: 0.5rem;
    font-size: 1.375rem;
  }

  .robot-message__text {
    margin-top: 1rem;
    font-size: 0.875rem;
  }

  .robot-message-author__text {
    font-size: 0.6875rem;
  }

  .more-company-wrapper {
    margin-top: 2rem;
    padding: 0 1rem;
  }

  .more-company {
    margin-top: 0;
    border-radius: 0.625rem;
    padding: 0.63rem;
    gap: 0.25rem;
  }

  .more-company-left__title {
    font-size: 1rem;
  }

  .more-company-left__text {
    margin-top: 0.37rem;
    font-size: 0.875rem;
  }

  .more-company__btn {
    padding: 0.5rem;
    font-size: 0.875rem;
    font-weight: 700;
    border-radius: 0.625rem;
  }

  .block-caption__professions {
    margin-top: 2.78rem;
  }

  .block-content__professions {
    padding: 0 1rem;
    margin-top: 1.16rem;
  }

  .block-content__professions {
    gap: 1rem;
  }


  .future-modal-filling {

    max-width: 100%;

  }

  .future-modal__text {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    padding: 0.5rem 1rem;
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    color: #101215;
    background: #fff;
    border-radius: 0.5rem;
    line-height: 1;
  }

  .future-element-close {
    top: -2.5rem;
    right: 0;
    position: absolute;

  }

  .future-modal__img {
    border-radius: 1rem;
  }

  .close {
    width: 1.5rem;
    height: 1.5rem;
  }

}
</style>
