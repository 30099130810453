<template>
  <div class="study-element">
    <img :src="require(`@/assets/imgs/statistics/study-img-${index}.svg`)" alt="" class="study__img" />
    <div class="study-element-filling">
      <p class="study__text">
        {{ `${university?.name}, ${universityRegion}`}}
      </p>
      <a :href="university?.url" class="study__link" target="_blank">{{ university?.url }}</a>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "StudyElement",
  props: ['university', 'index', 'region'],
  setup(props) {
    return {
      universityRegion: computed(() => {
        return (props.university?.region?.includes(props.region?.replace('Город ', '')) ? props.region : props.university?.region)
      })
    }
  }
};
</script>

<style scoped>
.study-element {
  display: flex;
  align-items: flex-start;
  border-radius: 1rem;
  background: rgba(98, 98, 98, 0.17);
  backdrop-filter: blur(32.584266662597656px);
  padding: 1.56rem 1.44rem;
  gap: 4.34rem;
}

.study__img {
  width: 7.96981rem;
  height: 7.58069rem;
}

.study-element-filling {
  max-width: 23.625rem;
  width: 100%;
}

.study__text {
  max-width: 20.0625rem;
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 140%;
  text-align: center;
  margin: 0 auto;
}

.study__link {
  color: #fff;
  margin-top: 1.24rem;
  max-width: 100%;
  width: 100%;
  text-align: center;
  border-radius: 0.625rem;
  font-size: 1.1875rem;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  word-break: break-word;
}

@media (max-width: 850px) {
  .study-element {
    border-radius: 1rem;
    padding: 0.62rem;
  }

  .study__img {
    display: none;
  }

  .study__text {
    font-size: 0.875rem;
  }

  .study__link {
    margin-top: 1rem;
    border-radius: 0.625rem;
    font-size: 0.875rem;
  }
}
</style>
