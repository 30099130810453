<template>
  <div class="missions">
    <separate-mission
      v-for="(value, key, index) in userData?.missions"
      :key="key"
      :access="accessToMission(index + 1, missions)"
      :index="index + 1"
      :passed="value"
      :start-date="userData?.actionStartDate"
    ></separate-mission>
  </div>
</template>

<script>
import SeparateMission from "@/components/missions/SeparateMission.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { accessToMission } from "@/store/missions";

export default {
  name: "MissionsPage",
  methods: { accessToMission },
  components: {
    SeparateMission,
  },
  setup() {
    const store = useStore();

    const userData = computed(() => store.getters.userData);

    return {
      userData,
      missions: userData.value?.missions,
    };
  },
};
</script>

<style scoped>
.missions {
  margin-top: 4.13rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  flex-wrap: wrap;
  gap: 1.25rem;
}

@media (max-width: 1600px) {
  .missions {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}

@media (max-width: 1000px) {
  .missions {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 850px) {
  .missions {
    margin-top: 0;
    gap: 0.5rem;
    display: flex;
  }
}
</style>
