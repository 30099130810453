<template>
  <div class="block study" id="study" :class="{'train-selected':educationStep===3}">
    <div
        class="block-filling block-filling__study"
        :class="{ 'block-filling__study-open': studyOpen }"
    >
      <div class="block-caption">
        <img
            src="@/assets/imgs/statistics/study.svg"
            alt=""
            class="block-caption__icon"
        />
        <div class="clue">
          <h3 class="subtitle">Где учиться</h3>
          <div class="clue__content">
            <p class="clue__title">Блок Где учиться</p>
            <p class="clue__text">Я подобрал для тебя несколько интересных вариантов, где ты сможешь получить
              профессию.Знакомься с возможностями, которые ты получишь там.</p>
          </div>
        </div>
      </div>
      <div class="block-content study-content">
        <study-element v-for="(item,index) in universities" :region="userData?.region"  :university="item" :index="index+1"
                       :key="item?.id"></study-element>
      </div>
      <div
          class="block-shadow"
          :class="{ 'block-shadow-close': studyOpen }"
      ></div>
    </div>
    <div class="block-end">
      <button
          class="block-end__btn"
          @click="studyOpen = !studyOpen"
          :class="{ 'block-end__btn-open': studyOpen }"
      ></button>
    </div>
  </div>
</template>

<script>
import StudyElement from "./StudyElement.vue";
import {computed, inject, ref} from "vue";
import {useStore} from "vuex";
// import {appConfig} from "@/lib/appConfig";

export default {
  name: "StudyBlock",
  components: {
    StudyElement,
  },
  props: ['universities', 'educationStep'],

  setup() {
    const store = inject('store') || useStore();
    const userData = computed(() => store.getters.userData);
    const studyOpen = ref(false);

    // watch(() => studyOpen.value, (val) => {
    //   if (val) {
    //     if (!appConfig.appState.free && userData.value?.status !== 'account_paid' && props.educationStep === null) {
    //       studyOpen.value = false;
    //       store.commit('setPaymentByMoneyModal', true);
    //     }
    //   }
    // })

    return {
      studyOpen,
      userData
    }
  }
};
</script>

<style scoped>
.block {
  background: #21262e;
  border-radius: 2rem;
  padding-bottom: 1.5rem;
  scroll-margin-top: 150px

}

.block-caption {
  display: flex;
  align-items: center;
  gap: 1.13rem;

}

.block-filling {
  position: relative;
  background: linear-gradient(180deg, rgba(33, 38, 46, 0) 0%, #21262e 100%);
  padding: 1.88rem 1.88rem 1.69rem 1.88rem;
  transition: max-height 2s ease-in-out;
  height: auto;
  overflow: hidden;
}

.block-caption__icon {
  display: flex;
  flex-shrink: 0;
}

.subtitle {
  background-color: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  background-image: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
}

.block-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  z-index: 2;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(33, 38, 46, 0) 0%, #21262e 100%);
  transition: opacity 1s ease-in-out;
}

.block-shadow-close {
  opacity: 0;
  pointer-events: none;
}

.block-end {
  display: flex;
  justify-content: center;
}

.block-end__btn {
  margin: 0 auto;
  width: 2.90275rem;
  height: 1.1875rem;
  background: url("@/assets/imgs/statistics/arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.block-end__btn-open {
  transform: rotate(180deg);
}

.study-content {
  margin-top: 1.87rem;
  display: flex;
  flex-direction: column;
  gap: 1.87rem;
}

.block-filling__study {
  max-height: 30.0625rem;
}

.block-filling__study-open {
  max-height: 70.6rem;
}

@media (max-width: 850px) {
  .block {
    border-radius: 0.625rem;
    padding-bottom: 1rem;
  }

  .block-caption {
    display: flex;
    align-items: center;
    gap: 1.13rem;
  }

  .block-filling {
    padding: 1.63rem 1rem 0.87rem 1rem;
  }

  .block-caption__icon {
    width: 1.8125rem;
    height: 1.5rem;
  }

  .subtitle {
    font-size: 1.375rem;
  }

  .stated-content {
    margin-top: 1.53rem;
    gap: 3rem;
  }

  .statistics__btn {
    margin-top: 1.5rem;
    padding: 0.59rem;
    font-size: 0.875rem;
  }

  .block-shadow {
    border-radius: 0.625rem;
  }

  .block-end {
    display: flex;
    justify-content: center;
  }

  .block-end__btn {
    width: 2.40275rem;
    height: 1rem;
  }

  .block-filling__stated {
    max-height: 27rem;
  }

  .block-filling__stated-open {
    max-height: 70rem;
  }

  .study-content {
    margin-top: 1.38rem;
    gap: 1rem;
  }

  .block-filling__study {
    max-height: 20.0625rem;
  }

  .block-filling__study-open {
    max-height: 42.6rem;
  }
}
</style>
