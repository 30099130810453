// Vuex and Router imports
import router from './router'
import store from './store'

// Toast lib imports
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


// Vue core objects imports
import {createApp} from 'vue';
import App from './App.vue';
import dayjs from "dayjs";


// creating app instance
const app = createApp(App);


// connecting Vuex storage and Vue-Router
app.use(store);
app.use(router);


// connecting Toast lib
app.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true
});

import "dayjs/locale/ru";

const localeData = require('dayjs/plugin/localeData')
dayjs.extend(localeData);
dayjs.locale('ru')

// build component and mount
app.component("app", App);
app.mount('#app');