<template>
  <div class="mission-greetings-wrapper">
    <div class="mission-greetings-filling">
      <div class="mission-greetings mission-greetings-1-1" v-if="missionId === 1">
        <div class="greetings-robot">
          <div class="robot-messages">
            <div class="robot-message message-left">
              <img src="@/assets/imgs/missions/decoration-left.svg" alt="" class="message-decoration" />
              <p class="robot-message__text">
                Мы начинаем наше путешествие по миру профессий и твоих возможностей😉!
              </p>
            </div>
            <div class="robot-message message-right">
              <img src="@/assets/imgs/missions/decoration-right.svg" alt="" class="message-decoration" />
              <p class="robot-message__text">
                Я был создан специально для того, чтобы направлять и помогать тебе в этом нелёгком и очень важном
                деле🤝
              </p>
            </div>
          </div>
          <img src="@/assets/imgs/missions/robot-big.png" alt="" class="greetings-robot__img" />
        </div>
      </div>
      <div class="mission-greetings mission-greetings-1-2" v-if="false">
        <div class="greetings-robot">
          <div class="robot-messages">
            <div class="robot-message message-left">
              <img src="@/assets/imgs/missions/decoration-left.svg" alt="" class="message-decoration" />
              <p class="robot-message__text">
                Если ты не забыл, меня зовут — Проффи. Впереди тебя ждёт
                прохождение нескольких важных этапов, всего их 5.
              </p>
            </div>
            <div class="robot-message message-right">
              <img src="@/assets/imgs/missions/decoration-right.svg" alt="" class="message-decoration" />
              <p class="robot-message__text">
                О твоих способностях, возможностях, профессиональных задатках, а
                также мы предложим тебе варианты работодателей для возможного
                оформления договора на целевое обучение.
              </p>
            </div>
          </div>
          <img src="@/assets/imgs/missions/robot-big.png" alt="" class="greetings-robot__img" />
        </div>
      </div>
      <div class="mission-greetings mission-greetings-1-3" v-if="false">
        <div class="greetings-robot">
          <div class="robot-messages">
            <div class="robot-message">
              <img src="@/assets/imgs/missions/decoration-left.svg" alt="" class="message-decoration" />
              <p class="robot-message__text">
                Ух, как много я тебе рассказал, и сколько всего интересного тебя
                ещё ждёт <br />
                впереди! <br />
                И, чуть не забыл! У тебя появится классная презентация и даже
                видео о тебе. <br />
                А теперь, давай скорее переходить дальше <br />
                к первому этапу.
              </p>
            </div>
          </div>
          <img src="@/assets/imgs/missions/robot-big.png" alt="" class="greetings-robot__img" />
        </div>
      </div>
    </div>
    <div class="mission-greetings mission-greetings-2" v-if="missionId === 2">
      <div class="greetings-robot">
        <div class="robot-messages">
          <div class="robot-message message-right">
            <img src="@/assets/imgs/missions/decoration-right.svg" alt="" class="message-decoration desktop" />
            <img src="@/assets/imgs/missions/decoration-left.svg" alt="" class="message-decoration mobile" />
            <p class="robot-message__text">
              С удовольствием учту все твои пожелания😉 Сейчас переходим ко второй миссии, которая будет интереснее,
              чем
              смотреть мимолетные сторисы. Задача — ответить на вопрос, и у нас для тебя целых три варианта🤗! Здесь нет
              "правильного" или "неправильного", просто выбирай то, что внутри говорит тебе сердце❤️
            </p>
          </div>
        </div>
        <img src="@/assets/imgs/missions/robot-big.png" alt="" class="greetings-robot__img" />
      </div>
    </div>
    <div class="mission-greetings mission-greetings-3" v-if="missionId === 3">
      <div class="greetings-robot">
        <div class="robot-messages">
          <div class="robot-message message-left">
            <img src="@/assets/imgs/missions/decoration-left.svg" alt="" class="message-decoration" />
            <p class="robot-message__text">
              А теперь погнали в 3-ю миссию😉!
              В ней мы с тобой попробуем организовать мероприятие и будем вместе разбирать разные ситуации🤝
            </p>
          </div>
        </div>
        <img src="@/assets/imgs/missions/robot-big.png" alt="" class="greetings-robot__img" />
      </div>
    </div>
    <div class="mission-greetings mission-greetings-5" v-if="missionId === 5">
      <div class="greetings-robot">
        <div class="robot-messages">
          <div class="robot-message message-left">
            <img src="@/assets/imgs/missions/decoration-left.svg" alt="" class="message-decoration" />
            <p class="robot-message__text robot-message__text--video">
              <span>
                Кто владеет информацией, тот владеет миром <span class="emoji">🌍</span> <br> Я попросил своих друзей
                из Московской школы управления «Сколково» рассказать тебе, как стать высококлассным специалистом и о
                трендах на рынке труда. Скорей смотри видео<span class="emoji">😉</span>
              </span>
            </p>
          </div>
          <div class="video-wrapper">
            <video class="video" controls volume="0.2" autoplay poster="@/assets/imgs/missions/poster.png">
              <source :src="`${appConfig.domain}/files/data/final.webm`" type="video/webm">
              <source :src="`${appConfig.domain}/files/data/final-reserve.mp4`" type="video/mp4" />
            </video>
            <img src="@/assets/imgs/robot-big.png" alt="" class="video__robot video__robot--desk">
            <img src="@/assets/imgs/robot-face.png" alt="" class="video__robot video__robot--mob">
          </div>
        </div>
      </div>
    </div>
    <div class="robot-message__btn-wrapper">
      <button @click="startMission" class="robot-message__btn button-purple">
        <p>Продолжить</p>
      </button>
    </div>
  </div>
</template>

<script>

import { appConfig } from "@/lib/appConfig";

export default {
  name: "MissionGreatings",
  computed: {
    appConfig() {
      return appConfig
    }
  },
  props: ["missionId"],
  data() {
    return {};
  },
  setup(props, { emit }) {

    return {

      startMission: () => emit("startMission"),
    }
  },
};
</script>

<style scoped>
.mission-greetings-wrapper {

  height: 100%;
  display: flex;
  scrollbar-width: thin;
  scrollbar-width: none;
  flex-direction: column;
}

.mission-greetings-filling {
  margin-top: auto;
  display: flex;
}

.mission-greetings-filling {
  max-width: 100%;
  width: 100%;
}

.mission-greetings {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.greetings-robot {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.robot-messages {
  display: flex;
  flex-direction: column;
  gap: 2.62rem;
}

.robot-message {
  padding: 1.25rem 4rem 1.25rem 1.88rem;
  border-radius: 0.625rem;
  background: #fff;
  color: rgba(19, 19, 19, 0.8);
  position: relative;
  max-width: 43rem;
}

.message-left {
  margin-right: 49rem;
}

.message-decoration {
  position: absolute;
  width: 1.875rem;
  height: 1.9375rem;
  right: 2.1rem;
  bottom: -1.8375rem;
}

.robot-message__btn {
  margin: 5rem auto 2.81rem auto;
  font-size: 1.25rem;
}

.greetings-robot__img {
  width: 19.6875rem;
  height: 19.6875rem;
  margin: 0.94rem auto 0 auto;
}

.message-right {
  margin-left: 18rem;
}

.robot-message__btn-wrapper {
  display: flex;
  justify-content: center;
}

.robot-message__btn {
  font-size: 1.125rem;
}

.robot-message__text {
  font-size: 1.5rem;
}

.robot-message__text span {
  background-color: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  background-image: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.robot-message__text .emoji {
  background-color: unset;
  background-image: unset;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
  color: #fff;
}

.mission-greetings-1-1 {
  padding-top: 5rem;
}

.mission-greetings-2 {
  margin-top: 3rem;
}

.mission-greetings-2 .message-right {
  margin-left: 0;
  margin-right: 17rem;
}

.mission-greetings-3 .message-right {
  margin-left: 13rem;
}

.mission-greetings-3 .message-left {
  margin-right: 33rem;
}

.mission-greetings-3 .greetings-robot__img {
  margin-top: 3rem;
}

.mission-greetings-3 {
  margin-top: 4rem;
}

.mission-greetings-5 {
  padding: 5rem 30rem 0 17rem;
}

.mission-greetings-5 .greetings-robot__img {
  margin-right: 0;
}

.mission-greetings-5 .robot-message {
  max-width: 100%;
  width: 100%;
  margin: 0;
}

.mission-greetings-5 .message-left .message-decoration {
  right: unset;
  left: 2.1rem;
}

.mission-greetings-5 .message-right {
  max-width: 33.6rem;
  margin-left: auto;
  display: flex;
  align-items: flex-end;
  gap: 2rem;
}

.description__title {
  color: rgba(19, 19, 19, 0.8);
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 115%;
}

.description__text {
  margin-top: 1.42rem;
  color: rgba(19, 19, 19, 0.5);
  font-size: 1.5rem;
  line-height: normal;
}

.description__arrow {
  margin-top: 0.83rem;
  max-width: 19.125rem;
  width: 100%;
}

.robot-message__link-icon {
  width: 4.07169rem;
  height: 4.07169rem;
}

.greetings-robot__img-love {
  width: 14.0625rem;
  height: 9.625rem;
}

/* video */
.video-wrapper {
  position: relative;
  max-width: 47.125rem;
  width: 100%;
  margin: 0 auto;
  border-radius: 1.5rem;
}

.video {
  max-width: 100%;
  width: 100%;
  border-radius: 1.5rem;
  height: auto;
}

.mission-greetings-5 {
  padding: 5rem 17rem 0 17rem;
}

.video__robot--mob {
  display: none;
}

.video__robot {
  position: absolute;
  transform: rotate(-5.949deg);
  left: -9rem;
  bottom: -1.78rem;
  width: 12.03294rem;
  pointer-events: none;
}

@media (max-width: 1600px) {
  .robot-message__btn {
    margin-top: 3rem;
  }
}

@media (max-width: 1400px) {
  .navigation-link {
    font-size: 1.225rem;
  }
}

@media (max-width: 1200px) {
  .message-right {
    margin-left: 6rem;
  }

  .mission-greetings-2 .message-right {
    margin-left: 0rem;
  }

  .mission-greetings-3 .message-right {
    margin-left: 0rem;
  }

  .mission-greetings-5 {
    padding: 5rem 0 0 0;
  }
}

@media (max-width: 1000px) {
  .message-left {
    margin-right: 25rem;
  }
}

@media (max-width: 850px) {
  .mission-greetings {
    padding-top: 0;
  }

  .robot-message__btn-wrapper {
    max-width: 100%;
    width: 100%;
    padding-bottom: 1rem;
    background: #15191e;
    z-index: 2;
  }

  .robot-message__btn {
    margin: 1rem 0 0 0;
    max-width: 100%;
    width: 100%;
    text-align: center;
  }

  .robot-message__btn-wrapper {
    padding-bottom: 4rem;
    background: none;
  }

  .robot-messages {
    gap: 0.5rem;
  }

  .robot-message {
    margin: 0;
    padding: 0.75rem 1rem;
  }

  .robot-message__text {
    font-size: 1.125rem;
    line-height: 115%;
  }

  .robot-message__text--video {
    font-size: 1rem;
  }

  .robot-message {
    max-width: 60%;
  }

  .message-right {
    margin-left: auto;
  }

  .message-decoration {
    bottom: -1.125rem;
    width: 1.32144rem;
    height: 1.625rem;
    right: 0.62rem;
  }

  .message-left .message-decoration {
    right: unset;
    left: 0.62rem;
  }

  .greetings-robot__img {
    margin: 1rem auto 0 auto;
    width: 15.375rem;
    height: auto;
  }

  .mission-greetings-1-3 .message-decoration {
    right: unset;
    left: 0.62rem;
  }

  .mission-greetings-2 .message-decoration {
    right: unset;
    left: 0.62rem;
  }

  .mission-greetings-2 .robot-message {
    max-width: 100%;
    margin-right: 0;
  }

  .mission-greetings-3 .message-left {
    margin-right: 0;
    max-width: 100%;
  }

  .mission-greetings-5 .robot-messages {
    gap: 2.5rem;
  }

  .mission-greetings-5 .message-right {
    max-width: 80%;
  }

  .description__title {
    font-size: 1.125rem;
  }

  .description__text {
    margin-top: 1.09rem;
    font-size: 1.125rem;
  }

  .description__arrow {
    max-width: 7.9375rem;
  }

  .robot-message__link-icon {
    width: 2.25rem;
    height: 2.25rem;
  }

  .greetings-robot__img-love {
    margin-top: 1.75rem;
    width: 5.75rem;
    height: 3.9375rem;
  }

  /* video */
  .video__robot--mob {
    display: flex;
    left: 0;
    bottom: unset;
    top: -1rem;
    width: 4.033rem;
  }

  .video__robot--desk {
    display: none;
  }

  .video {
    border-radius: 1rem;
  }

  iframe {
    height: 30vh;
    height: 30dvh;
  }
}

.disabled-btn {
  background: #121418;
  color: #fff;
  pointer-events: none;
  border: none;
  z-index: 2;
  position: relative;
  overflow: visible;
}

.disabled-btn::before {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border-radius: inherit;
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  z-index: -1;
}

.disabled-btn::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  background: #121418;
  z-index: -1;
}
</style>
