<template>
  <div class="teacher">
    <div class="teacher-element promocode-wrappper">
      <p class="teacher-element__title">Ваш промокод</p>
      <p class="promocode-text">
        Сообщите данный промокод участникам Акции от Вашей образовательной
        организации
      </p>
      <p class="promocode-text">
        Промокод должен быть активирован в определенный срок. Если он куплен в
        виде цифрового сертификата, этот срок составляет 6 месяцев со дня
        покупки. Если в виде промокода — 12 месяцев. На промокодах, полученных в
        рамках акции, срок активации указан дополнительно.
      </p>
      <h3 class="promocode">1231231323</h3>
    </div>
    <div class="teacher-element">
      <p class="teacher-element__title">Регионы</p>
      <div class="table-wrapper">
        <RegionTable></RegionTable>
      </div>
      <div class="navigation">
        <button class="arrow left"></button>
        <p class="navigation-number">12</p>
        <button class="arrow right"></button>
      </div>
    </div>
    <div class="teacher-element">
      <p class="teacher-element__title">Ученики</p>
      <div class="table-wrapper">
        <StudentTable></StudentTable>

        <!-- <download-excel v-if="getTeacherStudentStatistic.length !== 0" style="cursor: pointer"
          :data="getTeacherStudentStatistic" :fields="studentExelField">
          Выгрузить в Excel
        </download-excel> -->
      </div>
      <div class="navigation">
        <button class="arrow left"></button>
        <p class="navigation-number">1</p>
        <button class="arrow right"></button>
      </div>
    </div>
  </div>
</template>

<script>
import RegionTable from "@/components/teacher/RegionTable";
import StudentTable from "@/components/teacher/StudentTable.vue";

export default {
  components: { RegionTable, StudentTable },
  name: "TeacherPersonaleAreaPage",
};
</script>

<style scoped>
.teacher {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-top: 4.13rem;
}

.promocode-wrappper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.teacher-element__title {
  font-size: 2rem;
  background-color: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  background-image: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.promocode {
  text-transform: uppercase;
  background-color: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  background-image: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* navigation */
.navigation {
  margin-top: 0.5rem;
  display: flex;
  justify-content: right;
  gap: 0.5rem;
}

.arrow {
  background-color: rgba(30, 30, 30, 1);
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: center;
  height: 3rem;
  width: 3rem;
  border: 1px solid hsla(0, 0%, 100%, 0.08);
}

.navigation-number {
  background: rgba(30, 30, 30, 1);
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid hsla(0, 0%, 100%, 0.08);
}

/* navigation */
.navigation {
  margin-top: 0.5rem;
  display: flex;
  justify-content: right;
  gap: 0.5rem;
}

.arrow {
  background-color: rgba(30, 30, 30, 1);
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: center;
  height: 3rem;
  width: 3rem;
  border: 1px solid hsla(0, 0%, 100%, 0.08);
}

.left {
  background-image: url("@/assets/imgs/arrow-left.svg");
}

.right {
  background-image: url("@/assets/imgs/arrow-right.svg");
}

.navigation-number {
  background: rgba(30, 30, 30, 1);
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid hsla(0, 0%, 100%, 0.08);
}

.table-wrapper {
  margin-top: 1.5rem;
}

@media (max-width: 1366px) {
  .teacher {
    gap: 3rem;
  }
}

@media (max-width: 800px) {
  .teacher {
    padding-top: 0;
    gap: 1.5rem;
  }

  .table-wrapper {
    overflow: auto;
  }

  .teacher-element__title {
    font-size: 1.5rem;
  }

  .promocode-wrappper {
    gap: 1rem;
  }

  .promocode-text {
    font-size: 0.8rem;
  }

  .arrow {
    width: 2rem;
    height: 2rem;
  }

  .navigation-number {
    width: 2rem;
    height: 2rem;
    font-size: 0.8rem;
  }

  .teacher :deep(td) {
    padding: 0.7rem;
    font-size: 0.8rem;
  }
}
</style>
