<template>
  <div class="mission-finish-wrapper">
    <div class="mission-finish mission-finish-1" v-if="missionId === 1">
      <img src="@/assets/imgs/missions/robot-big.png" alt="" class="finish-robot__img" />
      <div class="robot-messages">
        <div class="robot-message message-first">
          <img src="@/assets/imgs/missions/decoration-left.svg" alt="" class="message-decoration" />
          <p class="robot-message__text">
            <span> Первая миссия пройдена! </span>
          </p>
        </div>
        <div class="robot-message message-standart">
          <img src="@/assets/imgs/missions/decoration-center.svg" alt="" class="message-decoration desktop" />
          <img src="@/assets/imgs/missions/decoration-left.svg" alt="" class="message-decoration mobile" />
          <p class="robot-message__text desktop">
            И у меня для тебя сюрприз — за её прохождение ты получаешь 50 баллов🎉! <br> И это ещё не всё! За каждую
            пройденную миссию ты будешь получать баллы, которые потом сможешь потратить в личном кабинете😉
          </p>
          <p class="robot-message__text mobile">
            И у меня для тебя сюрприз — за её прохождение ты получаешь 50 баллов🎉! И это ещё не всё! За каждую
            пройденную миссию ты будешь получать баллы, которые потом сможешь потратить в личном кабинете😉
          </p>
        </div>
        <div class="coin-wrapper">
          <p class="coin__number">50</p>
        </div>
      </div>
    </div>
    <div class="mission-finish mission-finish-2" v-if="missionId === 2">
      <img src="@/assets/imgs/missions/robot-big.png" alt="" class="finish-robot__img" />
      <div class="robot-messages">
        <div class="robot-message message-first">
          <img src="@/assets/imgs/missions/decoration-left.svg" alt="" class="message-decoration" />
          <p class="robot-message__text">
            <span> Вторая миссия пройдена! </span>
          </p>
        </div>
        <div class="robot-message message-standart">
          <img src="@/assets/imgs/missions/decoration-center.svg" alt="" class="message-decoration desktop" />
          <img src="@/assets/imgs/missions/decoration-left.svg" alt="" class="message-decoration mobile" />
          <p class="robot-message__text desktop">
            А теперь — тадаммм…!
            Поздравляю🥳! <br> За прохождение этой миссии ты получаешь ещё 50 баллов. И у тебя уже 100 баллов 🤩
            Напоминаю, что все свои баллы ты сможешь потратить в личном кабинете 😉

          </p>
          <p class="robot-message__text mobile">
            А теперь — тадаммм…!
            Поздравляю🥳! За прохождение этой миссии ты получаешь ещё 50 баллов. И у тебя уже 100 баллов 🤩
            Напоминаю, что все свои баллы ты сможешь потратить в личном кабинете 😉

          </p>
        </div>
        <div class="coin-wrapper">
          <p class="coin__number">50</p>
        </div>
      </div>
    </div>
    <div class="mission-finish mission-finish-3" v-if="missionId === 3">
      <img src="@/assets/imgs/missions/robot-big.png" alt="" class="finish-robot__img" />
      <div class="robot-messages">
        <div class="robot-message message-first">
          <img src="@/assets/imgs/missions/decoration-left.svg" alt="" class="message-decoration" />
          <p class="robot-message__text">
            <span> Третья миссия пройдена! </span>
          </p>
        </div>
        <div class="robot-message message-standart">
          <img src="@/assets/imgs/missions/decoration-center.svg" alt="" class="message-decoration desktop" />
          <img src="@/assets/imgs/missions/decoration-left.svg" alt="" class="message-decoration mobile" />
          <p class="robot-message__text desktop">
            А теперь — тадаммм…! Поздравляю🎉! <br> За прохождение этой миссии ты
            получаешь ещё 50 баллов. И у тебя уже 150 баллов.
            Напоминаю😉, что все свои награды ты сможешь потратить на что-нибудь полезное.
          </p>
          <p class="robot-message__text mobile">
            А теперь — тадаммм…!<br> Поздравляю🎉! За прохождение этой миссии ты
            получаешь ещё 50 баллов. И у тебя уже 150 баллов.
            Напоминаю😉, что все свои награды ты сможешь потратить на что-нибудь полезное.
          </p>
        </div>
        <!--        <div class="robot-message message-standart message-third">-->
        <!--          <img-->
        <!--              src="@/assets/imgs/missions/decoration-center.svg"-->
        <!--              alt=""-->
        <!--              class="message-decoration desktop"-->
        <!--          />-->
        <!--          <img-->
        <!--              src="@/assets/imgs/missions/decoration-left.svg"-->
        <!--              alt=""-->
        <!--              class="message-decoration mobile"-->
        <!--          />-->
        <!--          <p class="robot-message__text desktop">-->
        <!--            Спасибо! Напоминаю, что все свои награды ты сможешь потом <br/>-->
        <!--            потратить на что-нибудь полезное. Я обязательно тебе об этом <br/>-->
        <!--            расскажу поподробнее чуть позже. А сейчас давай скорее <br/>-->
        <!--            продолжать.-->
        <!--          </p>-->
        <!--          <p class="robot-message__text mobile">-->
        <!--            Спасибо! Напоминаю, что все свои награды ты сможешь потом потратить-->
        <!--            на что-нибудь полезное. Я обязательно тебе об этом расскажу-->
        <!--            поподробнее чуть позже. А сейчас давай скорее продолжать.-->
        <!--          </p>-->
        <!--        </div>-->
        <div class="coin-wrapper">
          <p class="coin__number">50</p>
        </div>
      </div>
    </div>
    <div class="mission-finish mission-finish-4" v-if="missionId === 4">
      <img src="@/assets/imgs/missions/robot-big.png" alt="" class="finish-robot__img" />
      <div class="robot-messages">
        <div class="robot-message message-first">
          <img src="@/assets/imgs/missions/decoration-left.svg" alt="" class="message-decoration" />
          <p class="robot-message__text">
            <span> Четвертая миссия пройдена! </span>
          </p>
        </div>
        <div class="robot-message message-standart">
          <img src="@/assets/imgs/missions/decoration-center.svg" alt="" class="message-decoration desktop" />
          <img src="@/assets/imgs/missions/decoration-left.svg" alt="" class="message-decoration mobile" />
          <p class="robot-message__text desktop">
            Тадаммм…! Поздравляю!
            За прохождение этой миссии ты получаешь ещё 50 баллов. И у тебя их уже 200🎉!
            И ещё раз напомню, что все свои баллы ты сможешь потратить на кое-что интересное в твоём личном кабинете.
            Мы почти у финиша🤗!

          </p>
          <p class="robot-message__text mobile">
            Тадаммм…! Поздравляю!
            За прохождение этой миссии ты получаешь ещё 50 баллов. И у тебя их уже 200🎉! <br>
            И ещё раз напомню, что все свои баллы ты сможешь потратить на кое-что интересное в твоём личном кабинете.
            Мы почти у финиша🤗!

          </p>
        </div>
        <div class="coin-wrapper">
          <p class="coin__number">50</p>
        </div>
      </div>
    </div>
    <div class="mission-finish mission-finish-5" v-if="missionId === 5">
      <img src="@/assets/imgs/missions/robot-big.png" alt="" class="finish-robot__img" />
      <div class="robot-messages">
        <div class="robot-message message-standart">
          <img src="@/assets/imgs/missions/decoration-center.svg" alt="" class="message-decoration desktop" />
          <img src="@/assets/imgs/missions/decoration-left.svg" alt="" class="message-decoration mobile" />
          <p class="robot-message__text desktop">
            А что там у нас по баллам после пяти миссий👀? <br> Их на твоём балансе уже 250🎉!!! <br>
            И у меня есть что предложить тебе, чтобы их потратить 😉
            Время заглянуть в твоё будущее, вперёд в личный кабинет за результатами 🤝
          </p>
          <p class="robot-message__text mobile">
            А что там у нас по баллам после пяти миссий👀? Их на твоём балансе уже 250🎉!!!
            И у меня есть что предложить тебе, чтобы их потратить 😉
            Время заглянуть в твоё будущее, вперёд в личный кабинет за результатами 🤝
          </p>
        </div>
        <div class="coin-wrapper">
          <p class="coin__number">50</p>
        </div>
      </div>
    </div>
    <div class="robot-message__btn-wrapper">
      <button class="robot-message__btn button-purple" @click="saveMissionPass">
        <p v-if="missionId === 5">Нажимай — и скорее за результатами!</p>
        <p v-else>
          Нажимай — забирай награду <br />
          на свой личный баланс и продолжаем!
        </p>
      </button>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  name: "MissionFinish",
  props: ["missionId"],
  setup() {
    const store = inject("store");

    return {
      saveMissionPass: () => store.dispatch("saveMissionPassing"),
    };
  },
};
</script>

<style scoped>
.mission-finish-wrapper {
  height: 100%;
  display: flex;
  scrollbar-width: thin;
  scrollbar-width: none;
  flex-direction: column;
  max-width: 98.25rem;
  width: 100%;
  padding: 0 1rem;
  margin: 0 auto;
}

.mission-finish {
  max-width: 100%;
  width: 100%;
  padding: 7rem 0 0 0;
  display: flex;
  gap: 2.25rem;
  align-items: center;
}

.robot-messages {
  max-width: 40.875rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
}

.robot-message {
  padding: 1.25rem 1.88rem 1.25rem 1.88rem;
  border-radius: 0.625rem;
  background: #fff;
  color: rgba(19, 19, 19, 0.8);
  position: relative;
  font-size: 0.9375rem;
}

.message-decoration {
  position: absolute;
  width: 1.875rem;
  height: 1.9375rem;
  right: 2.1rem;
  bottom: calc(50% - 0.96875rem);
  transform: rotate(270deg);
  left: -1.65rem;
}

.robot-message__btn {
  margin: 7rem auto 2.81rem auto;
  max-width: 32rem;
  font-size: 1.5rem;
  text-align: center;
  line-height: normal;
  padding: 1rem 3rem;
  border-radius: 1rem;
}

.finish-robot__img {
  width: 19.6875rem;
  height: 19.6875rem;
}

.message-standart {
  max-width: 37.1rem;
  width: 100%;
}

.message-first {
  max-width: 22rem;
  width: unset;
}

.message-decoration {
  transform: rotate(90deg);
}

.message-standart .message-decoration {
  transform: rotate(0deg);
}

.robot-message__btn-wrapper {
  display: flex;
  justify-content: center;
}

.robot-message__text {
  font-size: 1.5rem;
  font-style: normal;
  line-height: 115%;
}

.robot-message__text span {
  background-color: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  background-image: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.coin-wrapper {
  width: 5.875rem;
  height: 5.875rem;
  margin: 0 auto;
  animation: coin 3s infinite;
}

.coin__number {
  width: 4.5rem;
  height: 4.5rem;
  border: 0.21875rem solid #585a5e;
  font-size: 1.8rem;
  animation: coin-number 3s infinite;
}

@keyframes coin {
  0% {
    transform: rotateY(0deg);
  }


  50% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

@keyframes coin-number {
  0% {
    transform: rotateY(0deg);
  }


  50% {
    transform: rotateY(-180deg);
  }

  100% {
    transform: rotateY(-360deg);
  }
}

.mission-finish-2 .coin-wrapper {
  margin: 0 auto;
}

.mission-finish-3 {
  padding-top: 5rem;
}

.mission-finish-3 .coin-wrapper {
  margin: 0 auto;
}

.mission-finish-4 {
  padding-top: 5rem;
}

.mission-finish-4 .robot-messages {
  max-width: 34.1rem;
}


.mission-finish-4 .message-standart {
  max-width: 100%;
}

.mission-finish-5 {
  padding-top: 5rem;
}

.mission-finish-5 .robot-messages {
  max-width: 38.1rem;
}

.mission-finish-5 .message-standart {
  max-width: 100%;
}

.mission-finish-5 .coin-wrapper {
  margin: 0 auto;
}

.mission-finish-1 .message-standart {
  max-width: 39.1rem;
}

@media (max-width: 1700px) {
  .mission-finish {
    padding-top: 2rem;
  }
}

@media (max-width: 1600px) {
  .mission-finish {
    padding-top: 6rem;
  }

  .robot-message__btn {
    margin-top: 3.6rem;
  }

  .mission-finish-3 {
    padding-top: 5rem;
  }

  .mission-finish-4 {
    padding-top: 4rem;
  }

  .mission-finish-5 {
    padding-top: 4rem;
  }
}

@media (max-width: 850px) {
  .mission-finish-wrapper {
    padding: 0;
  }

  .mission-finish {
    padding: 0;
    position: relative;
  }

  .robot-message__btn-wrapper {
    max-width: 100%;
    width: 100%;
    padding-bottom: 1rem;
    background: #15191e;
    z-index: 2;
  }

  .robot-message__btn {
    margin: 1.87rem 0 0 0;
    max-width: 100%;
    width: 100%;
    text-align: center;
    padding: 1rem;
    font-size: 1.25rem;
  }

  .robot-message__btn-wrapper {
    margin-top: auto;
    padding-bottom: 4rem;
    background: none;
  }

  .robot-messages {
    gap: 1rem;
    padding-top: 3.75rem;
  }

  .robot-message {
    max-width: 80%;
    margin: 0 0 0 auto;
    padding: 0.75rem 1rem;
    width: unset;
  }

  .robot-message__text {
    font-size: 1.125rem;
    line-height: 115%;
  }

  .message-decoration {
    width: 1.32144rem;
    height: 1.625rem;
    transform: rotate(180deg);
    scale: -1;
    left: -1.1rem;
    top: 0.62rem;
    bottom: unset;
    right: unset;
  }

  .message-standart .message-decoration {
    transform: rotate(180deg);
    scale: -1;
  }

  .message-first {
    max-width: 45%;
  }

  .message-standart {
    margin-left: auto;
  }

  .finish-robot__img {
    position: absolute;
    margin: 0rem auto 0 auto;
    width: 6.6875rem;
    height: auto;
    left: 0;
    top: 0;
  }

  .coin-wrapper {
    margin: 0.37rem auto 0 auto;
    width: 5rem;
    height: 5rem;
  }

  .coin__number {
    width: 4.5rem;
    height: 4.5rem;
    border: 0.125rem solid #585a5e;
  }

  .mission-finish-2 .coin-wrapper {
    margin: -6.8rem 0 0 0;
  }

  .mission-finish-2 .robot-message {
    max-width: 72%;
  }

  .message-third {
    margin-top: 0.82rem;
    margin-right: auto;
    margin-left: 0;
  }

  .mission-finish-3 .message-standart {
    max-width: 100%;
  }

  .mission-finish-3 .message-standart .message-decoration {
    transform: rotate(0deg);
    left: 3rem;
    top: -1.3rem;
  }

  .mission-finish-1 .message-standart .message-decoration {
    transform: rotate(0deg);
    left: 3rem;
    top: -1.3rem;
  }


  .message-third .message-decoration {
    top: -1rem;
    left: 0.7rem;
    transform: rotate(270deg);
  }

  .mission-finish-4 .robot-messages {
    padding-top: 5rem;
  }

  .mission-finish-4 .message-standart .message-decoration {
    top: -1rem;
    left: 0.7rem;
    transform: rotate(270deg);
  }

  .mission-finish-5 .robot-messages {
    padding-top: 8rem;
  }

  .mission-finish-5 .message-first {
    max-width: 48%;
  }

  .mission-finish-5 .message-standart .message-decoration {
    top: -1rem;
    left: 0.7rem;
    transform: rotate(270deg);
  }

}
</style>
