<template>
  <div class="presentation">
    <PresentationElement1>
    </PresentationElement1>
    <PresentationElement2>
    </PresentationElement2>
    <PresentationElement3>
    </PresentationElement3>
    <PresentationElement4>
    </PresentationElement4>
    <PresentationElement5>
    </PresentationElement5>
    <PresentationElement6>
    </PresentationElement6>
    <PresentationElement7>
    </PresentationElement7>
    <PresentationElement8>
    </PresentationElement8>
    <PresentationElement9>
    </PresentationElement9>
    <presentation-footer>
    </presentation-footer>
  </div>
</template>

<script>
import PresentationFooter from "@/components/presentation/PresentationFooter.vue"
import PresentationElement1 from "@/components/presentation/PresentationElement1.vue"
import PresentationElement2 from "@/components/presentation/PresentationElement2.vue"
import PresentationElement3 from "@/components/presentation/PresentationElement3.vue"
import PresentationElement4 from "@/components/presentation/PresentationElement4.vue"
import PresentationElement5 from "@/components/presentation/PresentationElement5.vue"
import PresentationElement6 from "@/components/presentation/PresentationElement6.vue"
import PresentationElement7 from "@/components/presentation/PresentationElement7.vue"
import PresentationElement8 from "@/components/presentation/PresentationElement8.vue"
import PresentationElement9 from "@/components/presentation/PresentationElement9.vue"

export default {
  name: "PresentationPage",
  components: {
    PresentationFooter,
    PresentationElement1,
    PresentationElement2,
    PresentationElement3,
    PresentationElement4,
    PresentationElement5,
    PresentationElement6,
    PresentationElement7,
    PresentationElement8,
    PresentationElement9
  }
};
</script>

<style scoped>
.presentation {
  position: relative;

}
</style>
