<template>
  <div class="company-element">
    <img :src="company.preview" alt="Компания" class="company-element__img" />
    <div class="company-element-description">
      <p class="company-element__text">{{ company.name }}</p>
      <button class="company-element__btn statistics__btn" @click="pickCompany">
        Оплатить мое обучение
      </button>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  name: "CompanyElement",
  props: ["company"],
  setup() {
    // const store = inject("store");
    const router = inject("router");
    return {
      pickCompany: () => {
        // store.commit("setPickedCompany", props.company.email);
        router.push({ name: "assistant" });
      },
    };
  },
};
</script>

<style scoped>
.statistics__btn {
  margin-top: 0.5rem;
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.625rem;
  padding: 1rem 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: #fff;
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  line-height: normal;
  text-transform: uppercase;
  border: 2px solid #FFF;
}

.company-element {
  max-width: 100%;
  width: 100%;
  border-radius: 0.9375rem;
  background: rgba(255, 255, 255, 0.17);
  backdrop-filter: blur(61.758975982666016px);
}

.company-element-description {
  padding: 1.56rem 2.5rem 2.5rem 2.5rem;
}

.company-element__img {
  max-width: 100%;
  width: 100%;
  height: 10.75rem;
  border-radius: 0.9375rem;
  background: #fff;
  object-fit: cover;
  object-position: center;
}

.company-element__text {
  font-size: 1.1875rem;
  font-weight: 700;
  line-height: 120%;
  word-break: break-word;
}

.company-element__btn {
  margin-top: 1.25rem;
}

@media (max-width: 1400px) {
  .company-element-description{
    padding: 1.5rem;
  }
}
@media (max-width: 850px) {
  .company-element {
    width: 14.2rem;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 0;
    font-size: 0.875rem;
    border-radius: 0.625rem;
  }

  .company-element-description {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0.88rem 0.44rem 0.69rem 0.44rem;
  }

  .company-element__img {
    height: 8rem;
    border-radius: 0.625rem;
  }

  .company-element__text {
    font-size: 0.875rem;
    text-align: center;
    margin-bottom: 0.9rem;
  }

  .company-element__btn {
    margin-top: auto;
    font-size: 0.875rem;
    padding: 0.5rem 0.5rem;
    line-height: normal;
    text-align: center;
  }
}
</style>
