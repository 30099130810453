import {defaultHttpResHandler} from "@/store/helpers/defaultHttpResHandler";
import {makeGetReq, makePostReq} from "@/store/helpers/request";
import {appConfig} from "@/lib/appConfig";


export const saveMissionPassing = async (token, data) => await defaultHttpResHandler(async () => await makePostReq(`${appConfig.domain}/student/missions${appConfig.appState.free ? '?free=true' : ''}`, data, {
    token,
    data: true
}))

export const fetchCompanyMissions = async (token) => await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/student/companies`, {
    token,
}))

export const fetchProfessionDescriptions = async (token) => await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/student/vacancies`, {
    token,
}))

