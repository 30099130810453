<template>
  <div class="filling" :class="{ 'step': step === 8 }">
    <img src="@/assets/imgs/video/bg-9.webp" alt="" class="bg" />
    <div class="content">
      <p class="title">НАВЫКИ</p>
      <div class="about">
        <p class="text text-left " :class="{ 'animate-2': step === 8 }">{{ competitions?.[0] }}</p>
        <div class="batary" :class="{ 'animation': step === 8 }">
          <div class="batary-element batary-element-1"></div>
          <div class="batary-element batary-element-2"></div>
          <div class="batary-element batary-element-3"></div>
          <div class="batary-element batary-element-4"></div>
          <div class="batary-element batary-element-5"></div>
        </div>
        <p class="text " :class="{ 'animate-2': step === 8 }">{{ competitions?.[1] }}</p>
      </div>
      <p class="text text__last " :class="{ 'animate-2': step === 8 }">
        {{ competitions?.[2] }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoElement9",
  props: ["competitions", "step"],
};
</script>

<style scoped>
.filling {
  display: flex;

  height: 100vh;
  overflow: hidden;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.bg {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.decoration {
  position: absolute;
}

.about {
  margin-top: 9.3vw;
  display: flex;
  align-items: center;
}

.batary {
  width: 18.65vw;
  height: 13.75vw;
  background: url("@/assets/imgs/video/batari.svg");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 1.72vw 1.72vw 1.72vw 3.49vw;
  display: flex;
}

.batary-element {
  margin-right: 1.3vw;
  width: 1.61vw;
  height: 100%;
  opacity: 0;
  background: linear-gradient(151deg, #c977ff 0%, #6d7bfc 100%);
}


.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  max-width: 100%;
  width: 100%;
}

.title {
  margin-top: 5.21vw;
  max-width: 50.31vw;
  text-align: center;
  font-size: 3.33vw;
  font-weight: 700;
  line-height: 143.5%;
  text-transform: uppercase;
}

.text {
  width: 27.14vw;
  font-size: 2.5vw;
  line-height: 143.5%;
  margin-left: 3.65vw;
}

.text-left {
  text-align: right;
  margin-right: 3.65vw;
  margin-left: 0;
}

.text__last {
  margin-left: 0;
  margin-top: 3.65vw;
  text-align: center;
  max-width: 100%;
  width: 100%;
}

.animation .batary-element-1 {
  opacity: 1;
  transition: all 0.5s;
}

.animation .batary-element-2 {
  opacity: 1;
  transition: all 0.8s;
}

.animation .batary-element-3 {
  opacity: 1;
  transition: all 1.1s;
}

.animation .batary-element-4 {
  opacity: 1;
  transition: all 1.4s;
}

.animation .batary-element-5 {
  opacity: 1;
  transition: all 1.7s;
  margin-right: 0;
}

.animate-2 {
  animation: content-open 2s 0.5s both;
}

@keyframes content-open {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
