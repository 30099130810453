<template>
  <div class="filling-5">
    <img src="@/assets/imgs/video/bg-10.webp" alt="" class="bg-5" />
    <div class="content-5">
      <p class="title-5">
        Мира, поздравляем! Думаю, из тебя получится успешный предприниматель!
        присмотрись к этой сфере, если хочешь брать на себя ответственность
      </p>
      <div class="helper-5">
        <img
          src="@/assets/imgs/video/robot-small.webp"
          alt=""
          class="robot-5"
        />
        <p class="text-5">Цифровой помощник</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PresentationElement5",
};
</script>

<style scoped>
.filling-5 {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  height: 100vh;
  height: 100dvh;
}

.bg-5 {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.content-5 {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  max-width: 100%;
  width: 100%;
}

.title-5 {
  max-width: 79.31vw;
  text-align: center;
  font-size: 55px;
  font-weight: 700;
  line-height: 143.5%;
  letter-spacing: 3.3px;
  text-transform: uppercase;
}

.helper-5 {
  margin-top: 25px;
  display: flex;
  align-items: center;
  gap: 14px;
}

.robot-5 {
  width: 58px;
}

.text-5 {
  font-size: 19.333px;
}
</style>
