<template>
  <div class="calendar-block block" :class="{ 'train-selected': educationStep === 7 }" id="calendar">
    <div class="block-filling  block-filling__unique" :class="{ 'block-filling__unique-open': viewMore }">
      <div class="calendar-filling">
        <div class="calendar-caption">
          <div class="clue">
            <p class="title purple-text">Мой календарь</p>
            <div class="clue__content">
              <p class="clue__title">Блок Мой календарь</p>
              <p class="clue__text">Здесь персональная подборка мероприятий исходя из твоих интересов. Начни строить
                свой
                профессиональный путь уже сейчас.</p>
            </div>
          </div>

          <div class="filter-wrapper" v-if="false">
            <button class="filter" @click="viewFilters = !viewFilters">
              <span class="filter__text purple-text">Фильтр</span>
              <img src="@/assets/imgs/statistics/filter.svg" alt="Фильтр" class="filter__icon" />
              <img src="@/assets/imgs/statistics/filter-list.svg" alt="Фильтр" class="filter__icon-list" />
            </button>
          </div>
        </div>
        <div class="filters" v-if="viewFilters">
          <!--          <div class="month">-->
          <!--            <button class="month-btn" @click="viewMonths = !viewMonths">-->
          <!--              {{ months[currentMonth] }}-->
          <!--              <img :class="{ 'month-btn__icon-open': viewMonths }" src="@/assets/imgs/statistics/list-small.svg" alt=""-->
          <!--                   class="month-btn__icon"/>-->
          <!--            </button>-->
          <!--            <div class="month-list" v-if="viewMonths">-->
          <!--              <button class="month-lust__elemnet" v-for="(item, index) in months.map((item) => item.toUpperCase())"-->
          <!--                      @click="currentMonth = index" :key="item">-->
          <!--                {{ item }}-->
          <!--              </button>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="filters-element">
            <p class="filters-element__title">Месяц</p>
            <div class="filter-list filter-list--months">
              <button v-for="(item, index) in months" :key="item" class="filter-list__element" @click="pickMonth(index)"
                :class="{ 'filter-list__element-active': pickedMonths?.includes(index) }">
                {{ item?.toUpperCase() }}
              </button>
            </div>
            <div class="filters-btns">
              <button class="filters-btns__element" @click="pickedMonths = [...months?.map((item, index) => index)]">
                Выбрать все
              </button>
              <button class="filters-btns__element filters-btns__element--delete" @click="pickedMonths.length = 0">
                Очистить все
              </button>
            </div>
          </div>
          <div class="filters-element">
            <p class="filters-element__title">Категория</p>
            <div class="filter-list">
              <button v-for="item in allTags" :key="item" class="filter-list__element"
                @click="tags?.includes(item) ? removeTag(item) : tags.push(item)"
                :class="{ 'filter-list__element-active': tags?.includes(item) }">
                {{ item }}
              </button>
              <button class="filter-list__element" @click="openEndedEvents = !openEndedEvents"
                :class="{ 'filter-list__element-active': openEndedEvents }">
                Бессрочные мероприятия
              </button>
            </div>
            <div class="filters-btns">
              <button class="filters-btns__element" @click="pickAll">Выбрать все</button>
              <button class="filters-btns__element filters-btns__element--delete" @click="dropCategories">Очистить все
              </button>
            </div>
          </div>
          <button class="filters__btn" @click="fetchCalendarEvents">Применить</button>
        </div>
      </div>
      <div class="events" v-if="calendarEvents">
        <calendar-event v-for="item in calendarEvents?.filter((calendarEvent) =>
          !tags.length ? true : tags.includes(calendarEvent.format)
        )" :key="item.id" :calendar-event="item"></calendar-event>
        <!--      <calendar-event></calendar-event>-->
      </div>
      <div class="block-shadow" :class="{ 'block-shadow-close': viewMore }"></div>
    </div>

    <div class="block-end">
      <button class="block-end__btn" @click="viewMore = !viewMore"
        :class="{ 'block-end__btn-open': viewMore }"></button>
    </div>
  </div>
</template>

<script>
import CalendarEvent from "./CalendarEvent.vue";
import { computed, inject, ref, watch } from "vue";
import dayjs from "dayjs";
import { useStore } from "vuex";
// import { appConfig } from "@/lib/appConfig";

export default {
  name: "CalendarBlock",
  props: ['educationStep'],
  components: {
    CalendarEvent,
  },
  setup(props) {
    const store = inject("store") || useStore();


    const currentMonth = ref(dayjs().get("month"));

    const pickedMonths = ref([]);

    const viewMore = ref(false);

    // const userData = computed(() => store.getters.userData);

    const openEndedEvents = ref(false);


    const viewMonths = ref(false);
    const viewFilters = ref(false);

    const tags = ref([]);
    const allTags = [
      "Стажировка",
      "Курс",
      "Экскурсия",
      "Фестиваль",
      "Конкурс",
      "Ярмарка профессий",
      "Мастер-класс",
      "Дни открытых дверей",
      "Встреча с профессионалом",
      "Обучение",
      "Тесты",
      "Онлайн-игра",
      "Переподготовка",
      "Олимпиада",
      "Подготовительные курсы",
    ];


    const calendarEvents = computed(() => {
      if (props?.educationStep === 7) {
        return store.getters.calendarEvents?.slice(0, 3);
      }
      return store.getters.calendarEvents
    });

    const fetchCalendarEvents = () => {
      store.dispatch("fetchCalendarEvents", {
        month: currentMonth.value,
        months: pickedMonths.value?.join(','),
        openEndedEvents: openEndedEvents.value
      });
      viewFilters.value = false;
    }

    const pickAll = () => {
      tags.value = [...allTags];
      openEndedEvents.value = true;

    }


    watch(() => viewFilters.value, (val) => {
      if (val) {
        viewMonths.value = false;
      }
    })

    watch(() => viewMonths.value, (val) => {
      if (val) {
        viewFilters.value = false;
      }
    })


    // watch(
    //     () => tags.value.length,
    //     (val) => {
    //       if (val > 3) tags.value.shift();
    //     }
    // );
    watch(() => props.educationStep,
      (val) => {
        viewMore.value = (val === 7);
      }
    );


    // watch(() => viewMore.value, (val) => {
    //   if (val) {
    //     if (!appConfig.appState.free && userData.value?.status !== 'account_paid' && props.educationStep === null) {
    //       viewMore.value = false;
    //       store.commit('setPaymentByMoneyModal', true);
    //     }
    //   }
    // })


    // watch(
    //     () => currentMonth.value,
    //     (val) => {
    //       fetchCalendarEvents(val);
    //       viewMonths.value = false;
    //       tags.value = [];
    //     }
    // );


    fetchCalendarEvents();

    return {
      currentMonth,
      months: dayjs.months(),
      calendarEvents,
      viewMonths,
      tags,
      viewFilters,
      viewMore,
      allTags,
      pickAll,
      removeTag: (item) => tags.value.splice(tags.value.indexOf(item), 1),
      fetchCalendarEvents,

      pickMonth: (item) => {
        const index = pickedMonths.value?.indexOf(item);
        if (index > -1) {
          pickedMonths.value?.splice(index, 1);
        } else {
          pickedMonths.value?.push(item);
        }
      },

      pickedMonths,

      openEndedEvents,

      dropCategories() {
        tags.value.length = 0;
        openEndedEvents.value = false;
      }

    };
  },
};
</script>

<style scoped>
.calendar-block {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  scroll-margin-top: 150px;

}

.calendar-filling {
  border-radius: 0.9375rem;
  max-width: 100%;
  width: 100%;
  /* padding: 1rem;
background: linear-gradient(182.17deg, #101215 10.35%, #21262e 147.02%); */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.calendar-caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.title {
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.015rem;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
}

.filter-wrapper {
  position: relative;
}

.filter {
  display: flex;
  padding: 0.81rem 1.25rem;
  gap: 0.81rem;
  border-radius: 0.625rem;
  border: 1px solid #20242b;
  background: #191d22;
  align-items: center;
}

.filter__text {
  font-size: 0.875rem;
  font-weight: 500;
}

.filter__icon {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  flex-shrink: 0;
}

.filter__icon-list {
  display: flex;
  width: 1.125rem;
  height: 1.125rem;
  flex-shrink: 0;
}

.filter-list {
  margin-top: 1.5rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.filter-list--months {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.filter-list-wrapper {
  position: absolute;
  right: 0;
}


.filter-list__element {
  background: #131519;
  padding: 1rem;
  border: 1px solid #ffffff;
  border-radius: 10px;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  transition: all 1s ease;
  text-align: center;
}

.filter-list__element:hover {
  background: linear-gradient(169.37deg, #ad4fea 0%, #4a58dd 91.49%);
  opacity: 1;
  border-color: rgba(0, 0, 0, 0);
}

.filter-list__element-active {
  background: linear-gradient(169.37deg, #ad4fea 0%, #4a58dd 91.49%);
  border-color: rgba(0, 0, 0, 0);
}


.month {
  position: relative;
  max-width: 9.135rem;
  width: 100%;
}

.month-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 400;
  gap: 0.5rem;
  text-transform: uppercase;
  padding: 1rem;
  border-radius: 0.625rem;
  border: 1px solid #fff;
  max-width: 100%;
  width: 100%;
}

.month-btn__icon {
  width: 1.4375rem;
  height: 1.4375rem;
}

.month-btn__icon-open {
  transform: rotate(180deg);
}

.month-list {
  position: absolute;
  max-height: 20rem;
  scrollbar-width: thin;
  z-index: 5;
  background: #fff;
  border-radius: 0.625rem;
  border: 1px solid #fff;
  overflow: auto;
  background: linear-gradient(182deg, #101215 0%, #21262e 100%);
}

.month-list :first-child {
  border-radius: 0.625rem 0.625rem 0 0;
}

.month-list :last-child {
  border-radius: 0 0 0.625rem 0.625rem;
  border-bottom: none;
}

.month-lust__elemnet {
  max-width: 100%;
  width: 100%;
  border-bottom: 1px solid #fff;
  padding: 1rem;
}

.status {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.62rem;
}

.status-elment {
  display: flex;
  align-items: center;
  gap: 0.94rem;
  position: relative;
  padding: 1rem;
  border-radius: 0.625rem;
  color: #fff;
}

.status-elment::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
}

.status-elment::after {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border-radius: inherit;
  background: #1e222a;
}

.status-elment__text {
  position: relative;
  z-index: 2;
  font-size: 1rem;
  font-weight: 400;
}

.status-elment__close {
  position: relative;
  z-index: 2;
  display: flex;
  flex-shrink: 0;
  width: 1.4375rem;
  height: 1.4375rem;
}

.events {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: 100%;
  overflow: hidden;
  scrollbar-width: thin;
}

.block-filling__unique-open .events {
  overflow: auto;
}

.block {
  background: #21262e;
  border-radius: 2rem;
  padding-bottom: 1.5rem;
  scroll-margin-top: 150px
}

.block-filling {
  position: relative;
  background: linear-gradient(180deg, rgba(33, 38, 46, 0) 0%, #21262e 100%);
  padding: 1.88rem 1.88rem 1.69rem 1.88rem;
  transition: max-height 2s ease-in-out;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1270px) {
  .block-filling {
    padding: 1rem;
  }
}

.block-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  z-index: 2;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(33, 38, 46, 0) 0%, #21262e 100%);
  transition: opacity 1s ease-in-out;
}

.block-shadow-close {
  opacity: 0;
  pointer-events: none;
}

.block-end {
  display: flex;
  justify-content: center;
}

.block-end__btn {
  margin: 0 auto;
  width: 2.90275rem;
  height: 1.1875rem;
  background: url("@/assets/imgs/statistics/arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.block-end__btn-open {
  transform: rotate(180deg);
}


.block-filling__unique {
  max-height: 24.5625rem;
}

.block-filling__unique-open {
  max-height: 100rem;
  min-height: 30rem;
}

.filters-element__title {
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
  color: #ffffff;
}

.filters__btn {
  margin: 1.5rem auto 0 auto;
  border: 2px solid #AD4FEA;
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  max-width: 300px;
  width: 100%;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.filters-btns {
  margin-top: 24px;
  display: flex;
  gap: 8px;
}

.filters-btns__element {
  padding: 8px 14.5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #101215;
  border-radius: 5px;
}

.filters-btns__element--delete {

  background: #484c52;
}

@media (max-width: 850px) {
  .calendar-filling {
    padding: 1.4rem 0.63rem;
    background: linear-gradient(182deg, #101215 0%, #21262e 100%);
  }

  .calendar-caption {
    flex-direction: column;
    align-items: flex-start;
  }

  .title {
    font-size: 1.125rem;
    padding: 0.63rem 0;
  }

  .filter-wrapper {
    max-width: 9.75rem;
    width: 100%;
  }

  .filter {
    margin-top: 0.69rem;
    max-width: 100%;
    width: 100%;
  }

  .filter__icon-list {
    margin-left: auto;
  }

  .filter-list {
    max-width: 100%;
    width: 100%;
  }

  .month-btn {
    margin-top: 0.81rem;
    padding: 0.63rem;
    border-radius: 0.625rem;
    font-size: 1rem;
  }

  .month-list {
    max-height: 14rem;
  }

  .month-lust__elemnet {
    padding: 0.63rem;
    font-size: 1rem;
  }

  .status {
    margin-top: 1.25rem;
  }

  .status-elment {
    padding: 0.75rem 0.62rem;
    font-size: 1rem;
  }

  .block {
    border-radius: 0.625rem;
    padding-bottom: 1rem;
  }

  .block-filling {
    padding: 1.63rem 1rem 0.87rem 1rem;
  }


  .block-shadow {
    border-radius: 0.625rem;
  }

  .block-end {
    display: flex;
    justify-content: center;
  }

  .block-end__btn {
    width: 2.40275rem;
    height: 1rem;
  }

  .filters__btn {
    margin: 0rem auto 0 auto;
    border: 1px solid #AD4FEA;
    font-size: 16px;
    line-height: 16px;
    padding: 14px;
    border-radius: 10px;
  }

}
</style>
