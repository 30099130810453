<template>
  <div class="filling" :class="{ 'step': step === 4 }">
    <img src="@/assets/imgs/video/bg-5.webp" alt="" class="bg" />
    <div class="content">
      <div class="image image-1 " :class="{ 'image-animation-1': step === 4 }">
        <img src="@/assets/imgs/video/photos/abroad.webp" alt="" class="image-filling"
          v-if="statistic?.yourFuturePicNames?.includes('За рубежом')" />
        <img src="@/assets/imgs/video/photos/rus.webp" alt="" class="image-filling"
          v-if="statistic?.yourFuturePicNames?.includes('В России')" />
      </div>
      <div class="image image-2 " :class="{ 'image-animation-2': step === 4 }">
        <img src="@/assets/imgs/video/photos/home.webp" alt="" class="image-filling"
          v-if="statistic?.yourFuturePicNames?.includes('Дом')" />
        <img src="@/assets/imgs/video/photos/flat.webp" alt="" class="image-filling"
          v-if="statistic?.yourFuturePicNames?.includes('Квартира')" />
      </div>
      <div class="image image-3 " :class="{ 'image-animation-2': step === 4 }">
        <img src="@/assets/imgs/video/photos/car.webp" alt="" class="image-filling"
          v-if="statistic?.yourFuturePicNames?.includes('Автомобиль')" />
        <img src="@/assets/imgs/video/photos/bike.webp" alt="" class="image-filling"
          v-if="statistic?.yourFuturePicNames?.includes('Мотоцикл')" />
      </div>
      <div class="image image-4 " :class="{ 'image-animation-2': step === 4 }">
        <img src="@/assets/imgs/video/photos/pet.webp" alt="" class="image-filling"
          v-if="statistic?.yourFuturePicNames?.includes('Животные')" />
        <img src="@/assets/imgs/video/photos/flower.webp" alt="" class="image-filling"
          v-if="statistic?.yourFuturePicNames?.includes('Растения')" />
      </div>
      <div class="description">
        <p class="text " :class="{ 'text-animation-1': step === 4 }">Обязательно станет твоей</p>
        <p class="text text__big " :class="{ 'text-animation-2': step === 4 }">РЕАЛЬНОСТЬЮ</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoElement5",
  props: ["statistic", 'step'],
};
</script>

<style scoped>
.filling {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.bg {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.image {
  position: absolute;
}

.image-filling {
  width: 100%;
  height: 100%;
  transform: rotate(30deg);
}

.content {
  display: flex;
  align-items: center;
  padding-left: 3.7vw;

  max-width: 100%;
  width: 100%;
}

.description {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  margin-right: 5.99vw;
  z-index: 2;
}

.image-1 {
  width: 13.18vw;
  height: 15.16vw;
  top: 5.36vw;
  right: 22.97vw;
}

.image-2 {
  left: 3.02vw;
  bottom: 0.1vw;
  width: 18.75vw;
  height: 21.56vw;
}

.image-3 {
  left: 19.48vw;
  bottom: 21.41vw;
  width: 11.46vw;
  height: 13.18vw;
}

.image-4 {
  left: 48.18vw;
  bottom: 5.26vw;
  width: 13.54vw;
  height: 15.63vw;
}

.text {
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  text-transform: uppercase;
}

.text__big {
  margin-top: 0.83vw;
  font-size: 3.33vw;
}

.image-animation-1 {
  animation: image-open-1 2s both;
  will-change: transform;
}

.image-animation-2 {
  animation: image-open-2 2s both;
  will-change: transform;
}

.text-animation-1 {
  animation: text-open-1 1.6s both;
  will-change: transform;
}

.text-animation-2 {
  animation: text-open-2 2s both;
  will-change: transform;
}

@keyframes image-open-1 {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes image-open-2 {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes text-open-1 {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes text-open-2 {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
