<template>
  <div class="block unique" id="unique" :class="{'train-selected':educationStep===4}">
    <div
        class="block-filling block-filling__unique"
        :class="{ 'block-filling__unique-open': viewMore }"
    >
      <div class="block-caption">
        <img
            src="@/assets/imgs/statistics/unique.svg"
            alt=""
            class="block-caption__icon"
        />
        <div class="clue">
          <h3 class="subtitle">Я уникален</h3>
          <div class="clue__content">
            <p class="clue__title">Блок Я уникален</p>
            <p class="clue__text">Познакомься с тем, что тебя мотивирует, твоими суперспособностямии профессиональными
              навыками, которые тебе нужно развивать для будущей карьеры.</p>
          </div>
        </div>
      </div>
      <div class="block-content unique-content">
        <div class="unique-element unique-element__columns">
          <p class="unique__title purple-text">Типы мотивации</p>
          <div class="unique-columns">
            <div
                class="unique-column"
                v-for="item in motivations"
                :key="item.key"
                @click="setCurrentMotivation(item)"
            >
              <p class="unique-column__text">{{ item.value }}%</p>
              <div
                  class="unique-column__filling"
                  :style="{ height: `${item.value}%` }"
              ></div>
              <div class="column-bottom desktop">
                <div class="column-description">
                  <p class="column-description-title">
                    {{ item.title }}
                  </p>
                  <p class="column-description-text">
                    {{ item.body }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="column-description mobile">
            <p class="column-description-title">
              {{ currentMotivation?.title }}
            </p>
            <p class="column-description-text">
              {{ currentMotivation?.body }}
            </p>
          </div>
        </div>
        <div class="unique-element unique-element-competencies">
          <div class="competencies-left">
            <div
                class="superpositions-interaction__ring pie-big animate"
                :style="{ '--p': studentStatistic?.businessmanPercent }"
            >
              <p class="interaction__ring-text">
                {{ studentStatistic.businessmanPercent }}%
              </p>
            </div>
            <p class="competencies-left__text">
              {{
                studentStatistic.businessmanPercent >= 0 &&
                studentStatistic.businessmanPercent < 57
                    ? "Если есть большое желание и готовность приложить усилия, то в будущем ты можешь стать предпринимателем!"
                    : studentStatistic.businessmanPercent >= 57 &&
                    studentStatistic.businessmanPercent < 87
                        ? "Поздравляю! У тебя есть шансы стать предпринимателем, если ты захочешь развиваться в этом направлении."
                        : "Поздравляю! С наибольшей вероятностью у тебя получится стать отличным предпринимателем!"
              }}
            </p>
          </div>
          <div class="competencies-right">
            <p class="competencies-right__title purple-text">
              Компетенции для развития
            </p>
            <div
                class="competencies-right-element"
                v-for="(item, index) in competitions"
                :key="index"
            >
              <div class="competencies-right-element-filling">
                <img
                    src="@/assets/imgs/statistics/star.svg"
                    alt=""
                    class="competencies-right__img"
                />
                <p class="competencies-right__text">{{ item.title }}</p>
                <button
                    class="competencies-right__button"
                    @click="
                    viewCompetition = viewCompetition === index ? null : index
                  "
                    :class="{
                    'competencies-right__button-active':
                      viewCompetition === index,
                  }"
                ></button>
              </div>
              <p
                  class="competencies-right-element__list"
                  :class="{
                  'competencies-right-element__list-open':
                    viewCompetition === index,
                }"
              >
                {{ item.body }}
              </p>
            </div>
          </div>
        </div>
        <div class="unique-element unique-element-skilles">
          <p class="skilles__title purple-text">Суперспособности</p>
          <skilles-element
              v-for="(item, index) in superpowers"
              :key="index"
              :power="item"
          ></skilles-element>
        </div>
        <div class="unique-element unique-element-role">
          <p class="skilles__title purple-text">Роли в команде</p>
          <role-element
              v-for="(item, index) in roles"
              :key="index"
              :role="item"
          ></role-element>
        </div>
      </div>
      <div
          class="block-shadow"
          :class="{ ' block-shadow-close': viewMore }"
      ></div>
    </div>
    <div class="block-end">
      <button
          class="block-end__btn"
          @click="viewMore = !viewMore"
          :class="{ 'block-end__btn-open': viewMore }"
      ></button>
    </div>
  </div>
</template>

<script>
import SkillesElement from "./SkillesElement.vue";
import RoleElement from "./RoleElement.vue";
import {  ref} from "vue";
// import {useStore} from "vuex";
// import {appConfig} from "@/lib/appConfig";

export default {
  name: "UniqueBlock",
  props: ["studentStatistic", "educationStep"],
  components: {
    SkillesElement,
    RoleElement,
  },
  setup(props) {

    // const store = inject('store') || useStore();


    const competitions = props?.studentStatistic?.superpower
        ? Object.entries(props.studentStatistic.superpower)
            .sort((a, b) => b[1] - a[1])
            ?.slice(0, 3)
            .map((item) => {
              switch (item[0]) {
                case "people":
                  return {
                    title: "Гибкость",
                    body: "Клиентоориентированность, управление эмоциями, работа в команде",
                  };

                case "science":
                  return {
                    title: "Наука",
                    body: "Критическое мышление, планирование и целеполагание,  системный подход",
                  };

                case "activity":
                  return {
                    title: "Активность",
                    body: "Выработка и принятие решений, тайм-менеджмент, настойчивость",
                  };

                case "creative":
                  return {
                    title: "Креативность",
                    body: "Креативное мышление, саморазвитие, умение слушать",
                  };

                case "extreme":
                  return {
                    title: "Экстрим",
                    body: "Адаптация к изменениям, стрессоустойчивость, инициативность",
                  };

                case "information":
                  return {
                    title: "Информация",
                    body: "Поиск и анализ информации, логическое мышление, убеждение и аргументация",
                  };
              }
            })
        : null;

    const superpowers = props?.studentStatistic?.superpower
        ? Object.entries(props.studentStatistic.superpower).map((item) => {
          const power = {
            category: null,
            title: null,
            body: null,
            value: Math.floor(((item[1] > 12 ? 12 : item[1]) / 12) * 100),
          };
          switch (item[0]) {
            case "people":
              power.category = "Работа с людьми";
              if (item[1] >= 9) {
                power.title =
                    "Вау! Ты сможешь успешно реализоваться в работе с людьми";
                power.body =
                    "Уверен, что тебе нравится общаться и познавать мир именно через окружение. Скорее всего у тебя большой круг друзей и знакомых. Твоя суперспособность направлена на взаимодействие, управление, обучение, воспитание, консультирование, обслуживание. В общем, всё то, чем ты можешь быть полезен для общества. Советую в будущей работе ориентироваться именно на это.";
              } else if (item[1] >= 5 && item[1] <= 8) {
                power.title =
                    "Возможно, ты чувствуешь в себе потенциал для реализации в работе с людьми.";
                power.body =
                    "Твоя суперспособность может быть направлена на взаимодействие, управление, обучение, воспитание, консультирование, обслуживание. В общем, всё то, чем ты можешь быть полезен для общества. Может быть, твоя будущая профессия напрямую будет связана с этим, а может — только частично. В любом случае тебе стоит прокачивать навык эффективных коммуникаций. Это будет полезно в любой сфере!";
              } else if (item[1] <= 4) {
                power.title =
                    "Пока что у тебя недостаточно развита суперспособность в работе с людьми.";
                power.body =
                    "Но если есть желание, то всегда можно прокачать навык эффективных коммуникаций и нетворкинга. Помни — всё в твоих руках! ";
              }
              break;
            case "activity":
              power.category = "Прикладная деятельность";
              if (item[1] >= 9) {
                power.title =
                    "Класс! У тебя суперспособность в прикладной работе!";
                power.body =
                    "Я почти уверен, что тебе нравится работать руками, а еще важно видеть практический результат своей работы. Если это действительно так, то в будущем можно заняться любым производством или изготовлением. Главное — желание и фантазия, а талант у тебя уже есть.";
              } else if (item[1] >= 5 && item[1] <= 8) {
                power.title =
                    "Возможно, ты бы мог реализоваться в прикладной деятельности.";
                power.body =
                    "Я вижу, что у тебя есть в этом потенциал. Если ты любишь работать руками и видеть практический результат своей работы, то тебе обязательно стоит задуматься над будущей профессией, связанной с производством и изготовлением предметов или же их ремонтом.";
              } else if (item[1] <= 4) {
                power.title =
                    "Пока что у тебя недостаточно развита суперспособность в прикладной деятельности.";
                power.body =
                    "Но знаешь, возможно, в тебе есть талант, который ты ещё не раскрыл... Поразмышляй, насколько тебе нравится работать руками и видеть практический результат своей работы?";
              }
              break;
            case "creative":
              power.category = "Творческая деятельность";
              if (item[1] >= 9) {
                power.title =
                    "Вот это да! У тебя выраженная склонность к эстетическим видам деятельности!";
                power.body =
                    "Ты тонко чувствуешь красоту во всех её проявлениях: природе, музыке, живописи, литературе. Твоя суперспособность к творчеству видна всем окружающим. Возможно, ты любишь иногда быть в одиночестве и проживать глубинные эмоции, которые потом выражаются в искусстве. Я почти уверен, что ты выберешь креативную профессию.";
              } else if (item[1] >= 5 && item[1] <= 8) {
                power.title =
                    "У тебя есть возможность реализоваться в творческой деятельности.";
                power.body =
                    "Вероятно, ты любишь петь, рисовать, танцевать или играть на каком-то инструменте. Творческих профессий так много, можно перечислять бесконечно. Скажу одно: нравится креатив и искусство в любых проявлениях? Если прокачиваться и дальше, то ты в этом можешь сильно преуспеть!";
              } else if (item[1] <= 4) {
                power.title =
                    "Пока что у тебя недостаточно развита суперспособность в творческой деятельности.";
                power.body =
                    "Но иногда мы даже сами не догадываемся, какой творческий потенциал может в нас скрываться! Если тебе нравится искусство — не переставай искать себя в нём. Даже если это не станет профессией, то всегда может остаться приятным хобби.";
              }
              break;
            case "extreme":
              power.category = "Экстремальная деятельность";
              if (item[1] >= 9) {
                power.title =
                    "Ничего себе! Да, ты любитель экстремальных видов деятельности!";
                power.body =
                    "Твоя суперспособность может активироваться в экстремальных условиях. В отличие от других людей тебе очень легко собраться в стрессовых ситуациях и даже получать удовольствие от адреналина. Ты любишь работать в ситуации неопределенности. Возможно, в будущем ты хотел бы связать свою жизнь с профессиональным спортом, экстремальными экспедициями, охранной или спасательной деятельностью, службой в армии или правоохранительных органах.";
              } else if (item[1] >= 5 && item[1] <= 8) {
                power.title =
                    "Ничего себе! Могу предположить, что ты любишь экстремальные виды деятельности.";
                power.body =
                    "Возможно, твоя суперспособность может активироваться именно в экстремальных условиях и стрессовых ситуациях. Если действительно чувствуешь, что тебе нравится работать в ситуации неопределенности, то можно реализовать себя в профессиональном спорте, экстремальных экспедициях, охранной или спасательной деятельности, службе в армии или правоохранительных органах.";
              } else if (item[1] <= 4) {
                power.title =
                    "Пока что у тебя недостаточно развита суперспособность в экстремальной деятельности.";
                power.body =
                    "Это работа, связанная со стрессовыми ситуациями. И такая сфера нравится далеко не всем. Но, может, ты получаешь удовольствие от адреналина и не боишься никаких сложностей? Тогда прокачивай с двойной силой все навыки, необходимые для экстремальной работы. Я в тебя верю!";
              }
              break;
            case "information":
              power.category = "Работа с информацией";
              if (item[1] >= 9) {
                power.title =
                    "Интересно! Ты яркий представитель человека, который может успешно реализоваться в работе с информацией!";
                power.body =
                    "Твоя суперспособность связана с любовью к анализу абстрактной и часто сложной информации. Наверняка сверстники удивляются, как быстро ты решаешь задачи и находишь ответы в непростых ситуациях. Рекомендую в будущем рассматривать профессии, связанные с расчётами, сбором, обработкой, анализом и преобразованием информации любого рода (текст, числа, формулы, знаки).";
              } else if (item[1] >= 5 && item[1] <= 8) {
                power.title =
                    "Интересно! Возможно, тебе стоит прокачиваться в работе с информацией.";
                power.body =
                    "Кажется, что твоя суперспособность может быть связана с любовью к анализу абстрактной и сложной информации. Если тебе по душе решать задачи и находить ответы в непростых ситуациях, рекомендую в будущем рассматривать профессии, связанные с расчётами, сбором, обработкой, анализом и преобразованием информации любого рода (текст, числа, формулы, знаки).";
              } else if (item[1] <= 4) {
                power.title =
                    "Пока что у тебя недостаточно развита суперспособность в работе с информацией.";
                power.body =
                    "Это всё, что связано с расчётами, сбором, обработкой, анализом и преобразованием информации любого рода (текст, числа, формулы, знаки). Но кто сказал, что нельзя добиться всего, что хочешь, если есть огромное желание? Чувствуешь, что эта профессиональная сфера тебе близка? Тогда непременно действуй!";
              }
              break;
            case "science":
              power.category = "Исследовательская деятельность";
              if (item[1] >= 9) {
                power.title =
                    "Ого, да, у тебя все шансы реализоваться в исследовательской деятельности!";
                power.body =
                    "Тебя наверняка может привлекать работа, связанная с научными исследованиями, решением головоломок, изучением мира и природных явлений. Твоя суперспособность  — наблюдение и формулирование выводов. Не удивлюсь, если ты постоянно пытаешься докопаться до истины во многих вопросах. И это так круто! Ведь ты развиваешь критическое мышление и стремишься понять, как устроено всё вокруг.";
              } else if (item[1] >= 5 && item[1] <= 8) {
                power.title =
                    "Вероятно, ты бы мог реализоваться в исследовательской деятельности.";
                power.body =
                    "На основе ответов предположу, что твоя суперспособность связана с научными исследованиями, решением головоломок, изучением мира и природных явлений. Если тебе нравится докапываться до истины во многих вопросах и мыслить критически, то стоит присмотреться к этой сфере.";
              } else if (item[1] <= 4) {
                power.title =
                    "Пока что у тебя недостаточно развита суперспособность к исследовательской деятельности.";
                power.body =
                    "Но, если в глубине души ты чувствуешь, что родился настоящим исследователем, то обязательно дерзай! Вдруг ты станешь великим учёным, который откроет новый закон или сделает невероятное открытие.";
              }
          }
          return power;
        })?.sort((a, b) => b?.value - a?.value)?.slice(0, 2)
        : null;

    const roles = props?.studentStatistic?.teamRoles
        ? Object.entries(props.studentStatistic.teamRoles)
            .filter((item) => item[1] >= 30)
            .map((item) => {
              switch (item[0]) {
                case "leader":
                  return {
                    title: "Да, ты настоящий лидер!",
                    body:
                        "Всегда стремишься вперёд к своим высоким амбициям и целям. И часто именно ты — новатор в команде. Замечал? Привносишь извне что-то, что уже существовало раньше, но обязательно в улучшенном виде.\n" +
                        "\n" +
                        "Вот ещё несколько интересных фактов. Ты — непоседа, экспериментатор. Любишь пробовать новое, коммуникабелен, социально адаптирован, знаешь, как направить разговор в нужное русло. Вероятно, тебе нравится организовывать события и следить, чтобы все задачи были выполнены в срок. Не боишься брать на себя ответственность. Можешь легко управлять командой.\n" +
                        "\n" +
                        "Присмотрись к руководящим должностям или предпринимательству. Возможно, ты будущий Илон Маск и создашь свой бизнес!",
                    value: item[1],
                  };
                case "specialist":
                  return {
                    title: "Да, ты настоящий специалист!",
                    body:
                        "Обладаешь исключительными интеллектуальными способностями! Иногда можешь казаться окружающим сухим, скучным и совершенно «несветским». Но им просто неизвестно, что ты получаешь огромное удовольствие от процесса выполнения задач и способен удерживать в поле своего внимания множество мельчайших фактов. А это ого-го как круто!\n" +
                        "\n" +
                        "Тебе, может, сложновато даваться навык коммуникации. Но это не особо и нужно: ты классно умеешь работать один и делать больше, чем целые команды. В будущем у тебя есть все возможности стать лучшим экспертом в своей индустрии. Ведь именно такие как ты создают продукты, которые меняют мир! Осталось только определиться с индустрией для самореализации и «дело в шляпе».",
                    value: item[1],
                  };
                case "ideas":
                  return {
                    title: "Да, ты настоящий генератор идей!",
                    body:
                        "«Креативность во всем» — кредо по жизни! Ты любишь создавать новое и творить, способен выдвигать нестандартные решения, а значит, довольно решителен в отстаивании своих предложений. Проявляешь значительную социальную активность, но больше любишь думать, чем делать. Особенно, если дело касается рутинных задач, которые тебе неинтересны.\n" +
                        "\n" +
                        "Несмотря на внешнее веселье и уверенность в себе, ты достаточно раним и плохо переносишь критику своих идей. Однако в будущем твои идеи наверняка будут пользоваться популярностью в креативных агентствах!",
                    value: item[1],
                  };
                case "soul":
                  return {
                    title: "Да, ты настоящая душа компании!",
                    body:
                        "Передо мной явно любитель поднимать всем настроение и создавать позитивный вайб в команде. Ты часто помогаешь предотвратить конфликты и устранить противоречия между людьми. Не любишь выставлять свои достижения напоказ, но и ставить интересы коллектива выше своих не намерен.\n" +
                        "\n" +
                        "Тебе достаточно «быть», и всем обеспечено хорошее настроение. Но иногда это не позволяет принимать серьёзные решения, потому что ты подвержен влиянию более напористых коллег. Никогда не позволяй им сделать себя жертвой своих манипуляций и доверяй своей интуиции!",
                    value: item[1],
                  };
                case "expert":
                  return {
                    title: "Да, ты настоящий эксперт!",
                    body:
                        "Я как знаток скажу — это крайне редкая роль в команде. Ты обязательно будешь лучшим в своем деле!\n" +
                        "\n" +
                        "Интересным для себя считаешь общение с такими же экспертами. Ты требователен как к своим профессиональным знаниям, так и к другим, даже к собственному руководству. Склонен к критике. Иногда даже к неадекватной, потому что готов жёстко отстаивать идеи, в которых ты профи.\n" +
                        "\n" +
                        "Я бы назвал тебя «профессионалом очень высокого класса», выполняющим свои обязанности с большим энтузиазмом. В будущем вижу тебя в любой сфере, но, главное, чтобы возник истинный и глубокий интерес. Чаще всего этот интерес начинает прослеживаться в подростковом возрасте от 13 до 16 лет.",
                    value: item[1],
                  };
              }
            })
        : null;

    const motivations = props?.studentStatistic?.motivation
        ? Object.entries(props.studentStatistic.motivation)
            .sort((a, b) => b[1] - a[1])
            .map((item, index) => {
              let title, body;
              switch (item[0]) {
                case "material":
                  title = "Материальный тип мотивации";
                  body =
                      "Прежде всего для тебя важна материальная выгода в любой деятельности. Ты работаешь, представляя конечное вознаграждение за приложенные усилия. При выборе компании или должности в будущем, скорее всего, будешь акцентироваться на финансовом благополучии. И это супер! Деньги — ресурс, который может сделать жизнь комфортнее.";
                  break;
                case "team":
                  title = "Командный тип мотивации";
                  body =
                      "Тебе несильно важно, где ты будешь работать и сколько за это будут платить, но о-о-очень важно, кто тебя окружает и с кем надо делать движ. Ты любишь общаться и за крутым коллективом готов пойти куда угодно. Приоритет — причастность к жизни организации. И это здорово! Таких сотрудников очень ценят.";
                  break;
                case "process":
                  title = "Процессный тип мотивации";
                  body =
                      "Настоящий человек вдохновения! Ты получаешь удовольствие от работы. Для тебя важны именно задачи и непосредственные функции. Занимаясь любимым делом, время может пролетать незаметно, а процесс настолько тебя увлекает, что забываются все проблемы. Приоритет — сама деятельность, а не полученный результат.";
                  break;
                case "achieve":
                  title = "Достиженческий тип мотивации";
                  body =
                      "Результат — твоё всё! Тут отлично подойдёт выражение «вижу цель – не вижу препятствий». Неважно, какими средствами и как будет достигнуто задуманное, главное — дойти до конца. И будет ещё лучше, если за это получить дипломы / кубки / награды / признания. Всё то, что может подтвердить достижение. Нет, ну, а что? Отличная работа всегда должна вознаграждаться! Зачастую ты соперничаешь сам с собой. Что тоже хорошо: нужно всегда сравнивать себя только с собой прежним, не оглядываясь на других.";
                  break;
                case "idea":
                  title = "Идейный тип мотивации";
                  body =
                      "Ты черпаешь вдохновение в своих идеях! А ещё в твоём сердце точно горит яркий огонёк, способный своим светом заряжать окружающих. Тебе неважно, как будет реализована твоя идея, и что ты за это получишь. Зато очень важно повлиять на мир, преследуя высшую цель или миссию.";
                  break;
              }
              return {
                value: index
                    ? index === 1
                        ? 35
                        : index === 2
                            ? 5
                            : index === 3
                                ? 3
                                : 1
                    : 56,
                key: item[0],
                title,
                body,
              };
            })
        : null;

    const currentMotivation = ref(null);
    const setCurrentMotivation = (item) => {
      currentMotivation.value = item;
    };

    const viewMore = ref(false);

    // const userData = computed(() => store.getters.userData);

    // watch(() => viewMore.value, (val) => {
    //   if (val) {
    //     if (!appConfig.appState.free && userData.value?.status !== 'account_paid' && props.educationStep === null) {
    //       viewMore.value = false;
    //       store.commit('setPaymentByMoneyModal', true);
    //     }
    //   }
    // })

    return {
      competitions,
      superpowers,
      roles,
      motivations,
      viewMore,
      viewCompetition: ref(null),

      currentMotivation,
      setCurrentMotivation,
    };
  },
};
</script>

<style scoped>
.block {
  background: #21262e;
  border-radius: 2rem;
  padding-bottom: 1.5rem;
  scroll-margin-top: 150px
}

.block-caption {
  display: flex;
  align-items: center;
  gap: 1.13rem;
}

.block-filling {
  position: relative;
  background: linear-gradient(180deg, rgba(33, 38, 46, 0) 0%, #21262e 100%);
  padding: 1.88rem 1.88rem 1.69rem 1.88rem;
  transition: max-height 2s ease-in-out;
  height: auto;
  overflow: hidden;
}

.block-caption__icon {
  display: flex;
  flex-shrink: 0;
}

.subtitle {
  background-color: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  background-image: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
}

.block-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  z-index: 2;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(33, 38, 46, 0) 0%, #21262e 100%);
  transition: opacity 1s ease-in-out;
}

.block-shadow-close {
  opacity: 0;
  pointer-events: none;
}

.block-end {
  display: flex;
  justify-content: center;
}

.block-end__btn {
  margin: 0 auto;
  width: 2.90275rem;
  height: 1.1875rem;
  background: url("@/assets/imgs/statistics/arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.block-end__btn-open {
  transform: rotate(180deg);
}

.unique-content {
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.unique-element {
  padding: 1.8rem 0.94rem;
  border-radius: 0.9375rem;
  background: linear-gradient(182deg, rgba(16, 18, 21, 0.67) 0%, #21262e 100%);
}

.unique-element__columns {
  padding: 1.7rem;
}

.unique__title {
  padding: 0.87rem 0;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.16594rem;
  letter-spacing: -0.014rem;
}

.unique-columns {
  margin-top: 2.19rem;
  display: flex;
  max-width: 100%;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
  height: 21rem;
  position: relative;
}

.column-bottom {
  position: relative;
}

.column-description {
  margin-top: 4rem;
  padding: 1.87rem;
  position: absolute;
  left: calc(50% - 11.5rem);
  width: 23rem;
  background: rgba(175, 175, 175, 0.17);
  backdrop-filter: blur(31.21722412109375px);
  border-radius: 0.625rem;

  opacity: 0;
  transition: all 1s ease-in-out;
  z-index: -1;
}

.column-description::after {
  content: "";
  position: absolute;
  top: -80px;
  width: 3rem;
  height: 5rem;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  left: calc(50% - 1.5rem);
  background: rgba(175, 175, 175, 0.17);
  backdrop-filter: blur(31.21722412109375px);
}

.column-description-title {
  font-size: 1.1875rem;
  font-weight: 700;
  line-height: normal;
}

.column-description-text {
  margin-top: 1.5rem;
  font-size: 1rem;
  line-height: normal;
}

.unique-column {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.21rem;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.unique-column:hover .column-description {
  opacity: 1;
  z-index: 5;
}

.unique-columns :first-child .column-description {
  left: calc(50% - 3.5rem);
}

.unique-columns :first-child .column-description::after {
  left: 1.9rem;
}

.unique-columns :nth-child(5) .column-description {
  left: unset;
  right: calc(50% - 3.5rem);
}

.unique-columns :nth-child(5) .column-description::after {
  left: unset;
  right: 1.9rem;
}

.unique-column__text {
  margin-top: auto;
  font-size: 1.83769rem;
  font-style: normal;
  font-weight: 400;
  line-height: 97%;
}

.unique-column__filling {
  width: 2.19438rem;
  border-radius: 2.73681rem;
  background: #6456e1;
}

.unique-element-competencies {
  padding: 0;
  background: none;
  display: flex;
  align-items: flex-start;
  gap: 1.25rem;
}

.competencies-left {
  padding: 3.13rem 2.41rem 5rem 2.41rem;
  display: flex;
  flex-direction: column;
  gap: 1.97rem;
  align-items: center;
  backdrop-filter: blur(26.780780792236328px);
  text-align: center;
  border-radius: 0.5625rem;
  position: relative;
  max-width: 100%;
  width: 100%;
}

.competencies-left__text {
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: center;
  font-family: Helvetica;
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 106.8%;
}

.competencies-left:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
}

.competencies-left:after {
  content: "";
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  border-radius: inherit;
  background: #1e222a;
}

.competencies-right {
  border-radius: 0.873rem;
  max-width: 100%;
  width: 100%;
  padding: 1.87rem 0.94rem;
  background: linear-gradient(180deg, rgba(16, 18, 21, 0.67) 0%, #21262e 100%);
  display: flex;
  flex-direction: column;
  gap: 0.94rem;
}

.competencies-right__title {
  padding: 0.57rem 0;
  font-size: 1.375rem;
  font-weight: 700;
}

.competencies-right-element {
  background: linear-gradient(180deg, rgba(16, 18, 21, 0.67) 0%, #21262e 100%);
  border-radius: 0.875rem;
  padding: 1.87rem;
}

.competencies-right-element-filling {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.competencies-right__img {
  width: 3.3125rem;
  height: 3.3125rem;
  display: flex;
  flex-shrink: 0;
}

.competencies-right__text {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
}

.competencies-right__button {
  display: flex;
  flex-shrink: 0;
  width: 1.375rem;
  height: 0.5625rem;
  background: url("@/assets/imgs/statistics/arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: auto;
}

.competencies-right__button-active {
  transform: rotate(180deg);
}

.competencies-right-element__list {
  max-height: 0;
  overflow: hidden;
  transition: all 1s ease-in-out;
}

.competencies-right-element__list-open {
  max-height: 20rem;
  padding-top: 1.44rem;
}

.interaction__ring-text {
  font-size: 2.55838rem;
  font-weight: 400;
  line-height: normal;
  transform: scale(1, -1);
}

.pie-big {
  --b: 0.4375rem;
  --c: #fff;
  --w: 9.24331rem;
  width: var(--w);
  height: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  place-content: center;
  transform: scale(1, -1);
  flex-shrink: 0;
  z-index: 1;
}

@property --p {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

.pie-big:before,
.pie-big:after {
  content: "";
  position: absolute;
  border-radius: 100%;
}

.pie-big:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b) no-repeat,
  conic-gradient(var(--c) calc(var(--p) * 1%), #27282a 0);
  -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(99% - var(--b)),
      #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
      farthest-side,
      #0000 calc(99% - var(--b)),
      #000 calc(100% - var(--b))
  );
}

.pie-big:after {
  inset: calc(50% - 11px);
  transform: rotate(calc(var(--p) * 3.6deg)) translateY(calc(50% - var(--w) / 1.87));
}

.animate {
  animation: --p 1s 0.5s both;
  font-family: "Helvetica";
}

.unique-element-skilles {
  display: flex;
  flex-direction: column;
  gap: 0.94rem;
}

.skilles__title {
  padding: 0 0 1.12rem 0;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
}

.unique-element-role {
  display: flex;
  flex-direction: column;
  gap: 0.94rem;
}

.block-filling__unique {
  max-height: 47.5625rem;
}

.block-filling__unique-open {
  max-height: 240rem;
}

@media (max-width: 1600px) {
  .column-description::after {
    top: -59px;
  }
}

@media (max-width: 850px) {
  .block {
    border-radius: 0.625rem;
    padding-bottom: 1rem;
  }

  .block-caption {
    display: flex;
    align-items: center;
    gap: 1.13rem;
  }

  .block-filling {
    padding: 1.63rem 1rem 0.87rem 1rem;
  }

  .block-caption__icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .subtitle {
    font-size: 1.375rem;
  }

  .stated-content {
    margin-top: 1.53rem;
    gap: 3rem;
  }

  .statistics__btn {
    margin-top: 1.5rem;
    padding: 0.59rem;
    font-size: 0.875rem;
  }

  .block-shadow {
    border-radius: 0.625rem;
  }

  .block-end {
    display: flex;
    justify-content: center;
  }

  .block-end__btn {
    width: 2.40275rem;
    height: 1rem;
  }

  .competencies-right__title {
    font-size: 1.125rem;
    padding: 0.57rem 0;
  }

  .block-filling__stated {
    max-height: 27rem;
  }

  .block-filling__stated-open {
    max-height: 70rem;
  }

  .block-caption__icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .unique-content {
    margin-top: 0.96rem;
    gap: 3.25rem;
  }

  .unique-element__columns {
    padding: 0;
    background: none;
  }

  .unique__title {
    padding: 0.52rem 0;
    font-size: 1.125rem;
  }

  .unique-columns {
    margin-top: 1rem;
  }

  .unique-column {
    gap: 0.38rem;
  }

  .unique-column__text {
    font-size: 0.625rem;
  }

  .unique-columns {
    height: 7.25rem;
  }

  .unique-column__filling {
    width: 0.75213rem;
    border-radius: 3.125rem;
  }

  .unique-element-competencies {
    flex-direction: column;
    gap: 2.83rem;
  }

  .competencies-left {
    padding: 2.4rem 0.62rem 4rem 0.62rem;
    gap: 1.88rem;
  }

  .pie-big {
    --b: 0.25rem;
    --c: #fff;
    --w: 5rem;
  }

  @property --p {
    syntax: "<number>";
    inherits: true;
    initial-value: 0;
  }

  .pie-big:after {
    inset: calc(50% - 2.5rem);
  }

  .interaction__ring-text {
    font-size: 1.4525rem;
  }

  .competencies-left__text {
    font-size: 0.875rem;
  }

  .competencies-right {
    background: none;
    padding: 0;
  }

  .competencies-right-element {
    padding: 0.62rem;
    border-radius: 0.875rem;
    background: linear-gradient(182deg, #101215 0%, #21262e 120%);
  }

  .competencies-right__img {
    width: 1.5rem;
    height: 1.5rem;
  }

  .competencies-right__text {
    font-size: 1.125rem;
  }

  .competencies-right__button {
    width: 1rem;
    height: 0.4rem;
  }

  .competencies-right-element__list {
    font-size: 1rem;
    line-height: normal;
  }

  .competencies-right-element__list-open {
    padding-top: 1.13rem;
  }

  .unique-element-skilles {
    padding: 0;
    background: none;
    gap: 1rem;
  }

  .skilles__title {
    font-size: 1.125rem;
    padding-bottom: 0.16rem;
  }

  .column-description {
    margin-top: 1rem;
    padding: 0;
    position: relative;
    left: unset;
    width: 100%;
    background: none;
    backdrop-filter: none;
    opacity: 1;
    transition: all 1s ease-in-out;
    z-index: 3;
  }

  .column-description::after {
    display: none;
  }

  .column-description-title {
    font-size: 0.875rem;
  }

  .column-description-text {
    margin-top: 0.69rem;
    font-size: 0.875rem;
  }

  .unique-column {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    gap: 1.21rem;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .unique-columns :first-child .column-description {
    left: unset;
  }

  .unique-columns :nth-child(5) .column-description {
    left: unset;
    right: unset;
  }

  .unique-element-role {
    padding: 0;
    background: none;
  }

  .unique-element-role {
    gap: 1rem;
  }
}
</style>
