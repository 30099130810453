<template>
  <div class="filling" :class="{ 'step': step === 15 }">
    <img src="@/assets/imgs/video/bg-16.webp" alt="" class="bg" />
    <p class="title">профессии</p>
    <div class="roles" :class="{ 'animate': step === 15 }">
      <div class="roles-element" v-for="item in professions" :key="item.id">
        <img src="@/assets/imgs/video/med.webp" alt="" class="roles-img" />
        <p class="roles-text">{{ item.name }}</p>
      </div>
      <!--      <div class="roles-element">-->
      <!--        <img-->
      <!--            src="@/assets/imgs/video/men.svg"-->
      <!--            alt=""-->
      <!--            class="roles-img roles-img__big"-->
      <!--        />-->
      <!--        <p class="roles-text">Менеджер по подбору персонала</p>-->
      <!--      </div>-->
      <!--      <div class="roles-element">-->
      <!--        <img-->
      <!--            src="@/assets/imgs/video/style.svg"-->
      <!--            alt=""-->
      <!--            class="roles-img roles-img__big"-->
      <!--        />-->
      <!--        <p class="roles-text">Стилист</p>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoElement16",
  props: ["professions", "step"],
};
</script>

<style scoped>
.filling {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.title {
  margin-top: 5.21vw;
  text-align: center;
  color: #fff;
  text-align: center;
  font-size: 3.75vw;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
}

.animate {
  animation: content-open 2s 0.5s both;
  will-change: transform;
}

@keyframes content-open {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.bg {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.roles {
  position: relative;
  z-index: 2;
  margin-top: 6vw;
  display: flex;
  justify-content: center;
}

.roles :last-child {
  margin-right: 0;
}

.roles-img {
  width: 9.69vw;
  height: 9.69vw;
}

.roles-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 12.92vw;
  margin-right: 11.41vw;
}

.roles-text {
  margin-top: 1.2vw;
  color: #fff;
  text-align: center;
  font-size: 2.5vw;
  font-weight: 500;
  line-height: 143.5%;
}
</style>
