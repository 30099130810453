<template>
  <div class="porofile-navigation">
    <div class="profile-info">
      <button class="porofile-image" v-if="false">
        <img src="@/assets/imgs/photo_camera.svg" alt="Выбор фотографии" class="porofile-image__mask" />
        <img src="@/assets/imgs/profile-test.png" alt="Фотография" class="porofile-image__img" />
      </button>
      <p class="porofile__name">{{ userName }}</p>
      <p class="porofile__login purple-text" v-if="false">@Vrt12345</p>
    </div>
    <div class="navigation-filling">
      <h1 class="navigation__title">Информация</h1>
      <div class="navigation-list">
        <button class="navigation-button" @click="setProfileMode('info')">
          Личные данные
          <img src="@/assets/imgs/settings-arrow.svg" alt="" class="navigation-img" />
        </button>
        <button class="navigation-button" @click="setProfileMode('profile')">
          Профиль
          <img src="@/assets/imgs/settings-arrow.svg" alt="" class="navigation-img" />
        </button>
        <button class="navigation-button" v-if="false">
          Программа
          <img src="@/assets/imgs/settings-arrow.svg" alt="" class="navigation-img" />
        </button>
        <button class="navigation-button" @click="setProfileMode('education')">
          Образование
          <img src="@/assets/imgs/settings-arrow.svg" alt="" class="navigation-img" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileNavigation",
  props: ["userName"],
  setup(props, { emit }) {
    return {
      setProfileMode: (mode) => emit("setProfileMode", mode),
    };
  },
};
</script>

<style scoped>
.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.porofile-image {
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.188rem solid #fff;
  border-radius: 0.625rem;
}

.porofile-image__mask {
  position: absolute;
}

.porofile-image__img {
  max-width: 100%;
  border-radius: 0.425rem;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.porofile__name {
  margin-top: 0.25rem;
  font-size: 1.5rem;
  line-height: 1.75rem;
  word-break: break-word;
}

.porofile__login {
  margin-top: 0.19rem;
  font-size: 0.75rem;
  line-height: normal;
}

.navigation-filling {
  margin-top: 1.84rem;
}

.navigation__title {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.125rem;
  letter-spacing: -0.00938rem;
  padding-left: 1rem;
}

.navigation-list {
  margin-top: 0.97rem;
}

.navigation-button {
  padding: 0.75rem 1rem;
  max-width: 100%;
  width: 100%;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: -0.02rem;
  transition: background 1s ease-in-out;
  border-radius: 6.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation-img {
  width: 1.5rem;
  height: 1.5rem;
}

.navigation-button:hover {
  background: rgba(255, 255, 255, 0.1);
  opacity: 1;
}

.navigation-button__selected {
  background: rgba(255, 255, 255, 0.1);
}
</style>
