<template>
  <tr class="table-row">
    <td class="table-row__element">Иванов Иван Иванович</td>
    <td class="table-row__element"> test.test@test.ru</td>
    <td class="table-row__element"> Тариф оплачен</td>
  </tr>
</template>

<script>
export default {
  name: "StudentTableElement",

}
</script>

<style scoped>
.table-row {
  background: #131313;
  cursor: pointer;
  transition: opacity 1s ease;
}

.table-row:hover {
  opacity: 0.5;
}

td {
  padding: 1rem;
}
</style>