<template>
  <div class="statistics">
    <div class="statistics-filling" v-if="studentStatistic">
      <div class="statistics-left">
        <stated-block :education-step="educationStep"></stated-block>
        <study-block v-if="userData?.education!=='Образовательная организация высшего образования (вуз)'"
                     :universities="studentStatistic?.universities"
                     :education-step="educationStep"></study-block>
        <unique-block :education-step="educationStep" :student-statistic="studentStatistic"></unique-block>
      </div>
      <div class="statistics-right">
        <future-block :education-step="educationStep" :student-statistic="studentStatistic"></future-block>
        <calendar-block :education-step="educationStep"></calendar-block>
      </div>
    </div>
  </div>
</template>

<script>
import StatedBlock from "@/components/statistics/StatedBlock.vue";
import StudyBlock from "@/components/statistics/StudyBlock.vue";
import UniqueBlock from "@/components/statistics/UniqueBlock.vue";
import FutureBlock from "@/components/statistics/FutureBlock.vue";
import CalendarBlock from "@/components/statistics/CalendarBlock.vue";
import {computed, inject, watch} from "vue";

export default {
  name: "StatisticsPage",
  components: {
    StatedBlock,
    StudyBlock,
    UniqueBlock,
    FutureBlock,
    CalendarBlock,
  },
  setup() {
    const store = inject("store");
    store.dispatch("fetchStudentStatistic");

    const educationStep = computed(() => store.getters.educationStep);

    const userData = computed(() => store.getters.userData)

    watch(() => educationStep.value, (val, oldValue) => {

      let id = '';

      switch (val) {
        case 0:
          scroll(0, 0)
          break;
        case 1:
          id = 'certificate';
          break;
        case 2:
          id = 'future';
          break;
        case 3:
          id = 'study';
          if (userData.value?.education === 'Образовательная организация высшего образования (вуз)') {
            store.commit('setEducationStep', oldValue === 2 ? val + 1 : val - 1);
            return;
          }
          break;

        case 4:
          id = 'unique';
          break;
        case 5:
          id = 'company';
          break;
        case 6:
          id = 'professions';
          break;
        case 7:
          id = 'calendar';
          break;
        case 8:
          scroll(0, 0);
          break;
      }

      setTimeout(() => {
        document?.getElementById(id)?.scrollIntoView({behavior: "smooth", inline: 'start'})
      }, 200);
    })

    const studentStatistic = computed(() => store.getters.studentStatistic);


    const {search} = window.location;
    if (search?.length) {
      store.commit('setViewVkShareModal', true);
    }

    return {
      studentStatistic,
      educationStep,
      userData
    };
  },
};
</script>

<style scoped>
.statistics {
  padding-top: 4.13rem;
}


.statistics-filling {
  display: flex;
  align-items: flex-start;
  gap: 1.44rem;
}

.statistics-left {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.statistics-right {
  max-width: 32.1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

@media (max-width: 1600px) {
  .statistics {
    padding-top: 0;
  }

  .statistics-filling {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .statistics-right {
    max-width: 100%;
  }

  .stated__img {
    height: 8.938rem;
  }
}

@media (max-width: 1200px) {
  .statistics-filling {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 850px) {
  .statistics-filling {
    margin-top: 0;
  }

  .statistics {
    padding-top: 1rem;
  }

  .statistics-left {
    gap: 2.5rem;
  }

  .statistics-right {
    gap: 2.5rem;
  }
}
</style>
