<template>
  <div class="user-message">
    <p class="user-message__text">{{ message?.body }}</p>
  </div>
</template>

<script>
export default {
  name: "UserMessage",
  props: ["message"],
};
</script>

<style scoped>
.user-message {
  margin-top: 1rem;
  margin-left: auto;
  max-width: 56.063rem;
  width: 100%;
  padding: 1.25rem 1.875rem;
  font-size: 1.25rem;
  line-height: 1;
  color: #fff;
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  border-radius: 0.625rem;
  word-wrap: break-word;
}

@media (max-width: 700px) {
  .user-message {
    max-width: 72%;
    font-size: 1.125rem;
    line-height: 1;
    padding: 0.75rem 1rem;
  }
}
</style>
