<template>
  <div class="payment-wrapper">
    <div class="payment">
      <button class="close" @click="closeViewVkModal">
        <img src="@/assets/imgs/close.svg" alt="" class="close__img"/>
      </button>
      <p class="text"> Ваши достижения готовы к публикации! Опубликовать?</p>
      <div class="profile-field">
        <!--        <p class="profile-field__text">Для размещения записи на странице ВК, необходимо скопировать и вставить URL-->
        <!--          адрес, который вы получите при переходе по <a target="_blank"-->
        <!--                                                        :href="`https://oauth.vk.com/authorize?client_id=${appConfig.vkClientId}&redirect_uri=https://oauth.vk.com/blank.html&scope=wall,photos,offline&response_type=token&v=5.199`">ССЫЛКЕ</a>-->
        <!--        </p>-->
        <!--        <input-->
        <!--            type="text"-->
        <!--            placeholder="Введите url"-->
        <!--            class="profile-field__input"-->
        <!--            v-model="vkAccessTokenUrl"-->
        <!--        />-->
      </div>
      <div class="actions">
        <button @click="closeViewVkModal"
                style="padding: 0.875rem 1.25rem; background: linear-gradient(151deg, #AD4FEA 0%, #4A58DD 100%); border-radius: 0.625rem; font-weight: 700; font-size: 1rem;color: #fff; max-width: 100%;width: 100%;text-align: center">
          Отменить
        </button>
        <button @click="createVkPost"
                style="padding: 0.875rem 1.25rem; background: linear-gradient(151deg, #AD4FEA 0%, #4A58DD 100%); border-radius: 0.625rem; font-weight: 700; font-size: 1rem;color: #fff; max-width: 100%;width: 100%;text-align: center">
          Опубликовать
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import {useStore} from "vuex";
import {appConfig} from "@/lib/appConfig";
import {useRouter} from "vue-router";

export default {
  name: "VkShareModal",
  setup() {
    const store = useStore();
    const router = useRouter();

    function paramsToObject(entries) {
      const result = {};
      for (const [key, value] of entries) {
        result[key] = value;
      }
      return result;
    }

    return {

      createVkPost() {

        const {search} = window.location;

        if (search.length) {
          const urlParams = new URLSearchParams(search.slice(1));
          const params = paramsToObject(urlParams.entries());
          params.payload = JSON.parse(params.payload);
          store.dispatch('createPostVk', params);
        }


      },
      closeViewVkModal() {
        store.commit('setViewVkShareModal', false);
        router.push(appConfig.getVkRedirectUrl());
      },
      appConfig
    }
  }

};
</script>

<style scoped>
.payment-wrapper {
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  z-index: 13;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
  padding: 3rem;
  display: flex;
  scrollbar-width: none;
}

.payment {
  padding: 1.875rem;
  border-radius: 2rem;
  background: #101215;
  position: relative;
  pointer-events: all;
  max-width: 35.9375rem;
  width: 100%;
  pointer-events: all;
  margin: auto;
}

.profile-field {
  margin-top: 1rem;
}

.profile-field__text {
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.55);
  line-height: 1.2;
}

.profile-field__input {
  margin-top: 0.75rem;
}

.profile-field__text a {
  font-weight: 700;
  color: rgba(255, 255, 255, 0.85);
}

.text {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: normal;
}


.actions {
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  max-width: 100%;
  width: 100%;
}

.actions :deep(a) {
  max-width: 100%;
  width: 100%;
}

.close {
  position: absolute;
  right: -3rem;
  top: 0;
  padding: 0.62rem;
  cursor: pointer;
}

.close__img {
  width: 1.75rem;
  height: 1.75rem;
}

.actions-button {
  max-width: 100%;
  width: 100%;
  text-align: center;
}

@media (max-width: 850px) {
  .payment-wrapper {
    padding: 2rem 1rem;
    background: rgba(0, 0, 0, 0.66);
  }

  .payment {
    padding: 1.5rem;
    border-radius: 0.625rem;
    max-width: 25rem;
  }

  .close {
    right: 0;
    top: -3rem;
  }

  .text {
    font-size: 1.25rem;
    margin-left: auto;
  }

  .actions {
    margin-top: 1rem;
    gap: 1rem;
  }

}
</style>
