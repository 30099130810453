<template>
  <div class="filling" :class="{ 'step': step === 5 }">
    <img src="@/assets/imgs/video/bg-6.webp" alt="" class="bg" />

    <img src="@/assets/imgs/video/decoration-6-2.webp" alt="" class="decoration-2 decoration"
      :class="{ 'animate-1': step === 5 }" />
    <img src="@/assets/imgs/video/decoration-6-3.webp" alt="" class="decoration-3 decoration"
      :class="{ 'animate-1': step === 5 }" />

    <p class="title">
      развивай свои <br />
      суперспособности
    </p>
    <div class="list-wrapper">
      <img src="@/assets/imgs/video/decoration-6-4.webp" alt="" class="decoration-4 decoration" />
      <div class="list">
        <p class="list-element" :class="{ 'animate-2': viewAnimate && step === 5 }" v-for="item in superpowers"
          :key="item.power">
          {{ item.power }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
  name: "VideoElement6",
  props: ["superpowers", "step"],
  setup() {
    const viewAnimate = ref(false);

    onMounted(() => setTimeout(() => (viewAnimate.value = true), 1000));
    return {
      viewAnimate,
    };
  },
};
</script>

<style scoped>
.filling {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.bg {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.decoration {
  position: absolute;
}

.decoration-2 {
  top: 17.03vw;
  width: 20.89vw;
  height: 20.89vw;
  left: 2.34vw;
  z-index: 2;
}

.decoration-3 {
  top: 17.03vw;
  width: 17.03vw;
  height: 17.03vw;
  left: 9.01vw;
}

.decoration-4 {
  top: 22.55vw;
  width: 78.54vw;
  height: 5.89vw;
  right: -11.72vw;
}

.title {
  margin-top: 5.26vw;
  position: relative;
  color: #fff;
  text-align: center;
  font-size: 3.75vw;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  z-index: 2;
}

.list {
  margin-top: 17.45vw;
  display: flex;
  z-index: 2;
  position: relative;
  max-width: 100%;
  width: 100%;
}

.list-element {
  color: #fff;
  text-align: center;
  font-size: 2.5vw;
  font-weight: 500;
  line-height: 143.5%;
  position: absolute;
  width: 28vw;
  opacity: 0;
}

.list-element:nth-child(1) {
  right: 48vw;
  transition: all 0.5s linear;
}

.list-element:nth-child(2) {
  right: 23vw;
  transition: all 1s linear;
}

.list-element:nth-child(3) {
  right: 0vw;
  transition: all 1.5s linear;
}

.animate-2 {
  opacity: 1;
}

.animate-1 {
  animation: content-open 1s 0.5s both;
  will-change: transform;
}

@keyframes content-open {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-2 {
  animation: content-open 1s 0.5s both;
  will-change: opacity;
}
</style>
