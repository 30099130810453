<template>
  <div class="filling-4">
    <img src="@/assets/imgs/video/bg-2-2.png" alt="" class="bg-4" />
    <div class="content-4">
      <p class="title-4">
        Для этого развивай в себе <br />
        следующие навыки
      </p>
      <div class="description-4">
        <div class="about-4">
          <p class="text-4 text-left-4">
            Выработка и принятие решений, тайм-менеджмент
          </p>
          <div class="batary-4">
            <div class="batary-element-4 batary-element-1-4"></div>
            <div class="batary-element-4 batary-element-2-4"></div>
            <div class="batary-element-4 batary-element-3-4"></div>
            <div class="batary-element-4 batary-element-4-4"></div>
            <div class="batary-element-4 batary-element-5-4"></div>
          </div>
          <p class="text-4">
            Клиенториентированность, управление эмоциями, работа в команде
          </p>
        </div>
        <p class="text-4 text__last-4">
          Креативное мышление, саморазвитие, умение слушать
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PresentationElement4",
};
</script>

<style scoped>
.filling-4 {
  display: flex;
  align-items: center;
  width: 1920px;
  position: relative;
  height: 1080px;
}

.description-4 {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.bg-4 {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.content-4 {
  max-width: 100%;
  width: 100%;
  padding: 100px;
  position: relative;
  height: 100%;
}

.title-4 {
  text-align: center;
  font-size: 55px;
  font-weight: 700;
  line-height: 143.5%;
  letter-spacing: 3.3px;
  text-transform: uppercase;
  text-align: left;
}

.about-4 {
  display: flex;
  align-items: center;
  gap: 60px;
  justify-content: center;
}

.batary-4 {
  width: 358px;
  height: 264px;
  background: url("@/assets/imgs/video/batari.png");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 33px 33px 33px 67px;
  gap: 25px;
  display: flex;
}

.batary-element-4 {
  width: 32px;
  height: 100%;
  opacity: 1;
  background: linear-gradient(151deg, #c977ff 0%, #6d7bfc 100%);
}

.text-4 {
  max-width: 567px;
  font-size: 33px;
  font-weight: 700;
  line-height: 143.5%;
}

.text-left-4 {
  text-align: right;
}

.text__last-4 {
  margin: 60px auto 0 auto;
  text-align: center;
}
</style>
