<template>
  <div class="role-element">
    <div class="role-line">
      <div class="role-line-filling" :style="{ width: `${role.value}%` }">
        <div class="role-line-number">
          <p class="role-line-number__text">{{ role.value }}%</p>
        </div>
      </div>
    </div>
    <div class="role-caption">
      <p class="role__description">
        {{ role.title }}
      </p>
      <button
        class="role__btn"
        @click="viewMore = !viewMore"
        :class="{ 'role__btn-active': viewMore }"
      ></button>
    </div>
    <div class="role-filling" :class="{ 'role-filling__open': viewMore }">
      <pre class="skills-text">{{ role.body }}</pre>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "RoleElement",
  props: ["role"],
  setup() {
    return {
      viewMore: ref(false),
    };
  },
};
</script>

<style scoped>
.role__description {
  font-family: "Helvetica";
  font-size: 1.125rem;
}

.role__btn {
  margin-left: auto;
  width: 1.375rem;
  height: 0.5625rem;
  background: url("@/assets/imgs/statistics/arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-shrink: 0;
}

.role__btn-active {
  transform: rotate(180deg);
}

.skills-text {
  padding-top: 1.87rem;
  font-family: "Helvetica";
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

.role-element {
  padding: 3.46rem 1.88rem 1.88rem 1.88rem;
  border-radius: 0.875rem;
  background: linear-gradient(182deg, #101215 0%, #21262e 100%);
}

.role-line {
  display: flex;
  border-radius: 4.5rem;
  background: #000;
  max-width: 100%;
  width: 100%;
  height: 0.75rem;
}

.role-line-filling {
  position: relative;
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  height: 100%;
  border-radius: 4.5rem;
}

.role-line-number {
  position: absolute;
  width: 2.625rem;
  height: 2.1875rem;
  background: url("@/assets/imgs/statistics/role-number.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  right: -1rem;
  top: -2.5rem;
}

.role-line-number__text {
  font-family: "Helvetica";
  font-size: 0.90369rem;
  font-style: normal;
  font-weight: 400;
  line-height: 97%;
  position: absolute;
  top: 0.25rem;
}

.role-caption {
  margin-top: 1.62rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.role__description {
  font-size: 1.1875rem;
  font-style: normal;
  font-weight: 500;
}

.role-filling {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-height: 0;
  overflow: hidden;
  transition: all 1s ease-in-out;
}

.role-filling pre {
  font-family: "PtRootUi", sans-serif;
}

.role-filling__open {
  max-height: 20rem;
}

@media (max-width: 850px) {
  .role-element {
    padding: 2.31rem 0.63rem 0.63rem 0.63rem;
    background: linear-gradient(182deg, #101215 0%, #21262e 120%);
    border-radius: 0.625rem;
  }

  .role-line {
    height: 0.5rem;
    border-radius: 3.125rem;
  }

  .role-line-filling {
    border-radius: 3.125rem;
  }

  .role-line-number {
    width: 1.73756rem;
    height: 1.5rem;
    background-size: contain;
    background-repeat: no-repeat;
    right: -0.4rem;
    top: -1.7rem;
  }

  .role-line-number__text {
    font-size: 0.625rem;
    line-height: 0.7;
  }

  .role-caption {
    margin-top: 0.5rem;
  }

  .role__description {
    font-size: 1rem;
    line-height: 140%;
  }

  .role__btn {
    width: 0.875rem;
    height: 0.375rem;
  }

  .skills-text {
    padding-top: 1.23rem;
    font-size: 0.875rem;
  }

  .role-filling__open {
    max-height: 30rem;
  }
}
</style>
