<template>
  <footer class="footer">
    <div class="footer-companies">
      <img
        src="@/assets/imgs/companies/profstor.png"
        alt=""
        class="footer-companies__element profstor"
      />
      <img
        src="@/assets/imgs/companies/za.png"
        alt=""
        class="footer-companies__element za"
      />
      <img
        src="@/assets/imgs/companies/min.png"
        alt=""
        class="footer-companies__element min"
      />
      <img
        src="@/assets/imgs/companies/work.png"
        alt=""
        class="footer-companies__element work"
      />
    </div>
    <div class="footer-description">
      <p class="footer-element">
        АНО ДО «ДИЗАЙН КАРЬЕРЫ» ИНН: 7707365048 ОГРН: 1167700055544 <br />
        ГЕНЕРАЛЬНЫЙ ДИРЕКТОР — МАРАДЗЕ ЛЕЙЛА МАИЛОВНА
      </p>
      <p class="footer-element">
        +7 (927) 147-78-34 – АНАСТАСИЯ ШЕВЧЕНКО <br />
        +7 (919) 833-94-96 – ДАНИИЛ СУКОВ <br />
        tsip.rf@mail.ru
      </p>
      <a href="" class="footer-element">ДОГОВОР ОФЕРТА</a>
      <a href="" class="footer-element">ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "PageFooter",
};
</script>

<style scoped>
.footer {
  color: rgba(255, 255, 255, 0.5);

  position: absolute;
  width: 100%;
  bottom: 20px;
  width: 100%;
  max-width: 100%;
  padding: 0 94px;
}

.footer-element {
  font-size: 9.14px;
  font-weight: 500;
  line-height: normal;
}

.footer-description {
  margin-top: 18px;
  padding-top: 18px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: space-between;
}

.footer-companies {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.profstor {
  width: 59.88px;
}

.za {
  width: 188.334px;
}

.min {
  width: 195.931px;
}

.work {
  width: 105.71px;
}
</style>
